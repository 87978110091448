import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/img/logo_violeta.png'
import { Alert, Typography, CardActions, Box, Button, Grid, Link, Card, CardContent } from '@mui/material';
import InputValidation from '../../components/utils/InputValidation';
import { signup } from '../../redux/actions/userActions';
import { EmailSelectionVerification } from './EmailSelectionVerification';
import { clientEmails } from '../../redux/actions/authActions';
import { useNavigate } from "react-router-dom";
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import { firebase } from '../../db/firebase'
export const ResetPassword = () => {
    let history = useNavigate();
    const [masg, setMsg] = useState('')
    const [cedula, setCedula] = useState({ campo: '', valido: true })
    const [message, setMessage] = useState('')
    const [email, setEmail] = useState({ campo: '', valido: null })
    const [active, setActive] = useState(false)
    const [userEmails, setUserEmails] = useState([])
    const userSignup = useSelector(state => state.userSignup)
    const { signup } = userSignup
    const dispatch = useDispatch()
    useEffect(() => {
    }, [])

    const validationPassword = () => {
        console.log(email.valido)
        //if (email.valido === true) {
            console.log('entra', email.campo)
            firebase.auth().sendPasswordResetEmail(email.campo)
                .then(() => {
                    // Password reset email sent!
                    // ..
                    setActive(true)
                    setEmail('')
                    console.log('Password reset email sent!')
                })
                .catch((error) => {
                    setActive(false)

                    var errorMessage = error.message;
                    setMsg(errorMessage)
                    setEmail('')
                    // ..
                });
        //}
    }
    let theme = createTheme({
        palette: {

            background: {
                default: '#1d0226',
                paper: '#2D033B',
            },
            primary: {
                main: '#7635DC'
            },
            secondary: {
                main: '#6d1b7b',
            },

            //},
            // typography: {
            //     button: {
            //     textTransform: "none"
            //}
        },
        components: {
            // Name of the component

            MuiButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        borderRadius: '1rem',
                        textTransform: 'none',
                        fontSize: '1rem',
                        boxShadow: '0 8px 20px -12px rgba(0,0,0,0.5)',
                    },
                },
            },

            MuiCard: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        boxShadow: '0 8px 20px -12px rgba(0,0,0,0.3)',
                        borderRadius: '1rem',
                    },
                },
            },
        },
    })
    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid
                    container
                    component="main"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        height: '100vh'
                    }}
                >
                    <Grid
                        item xs={12} sm={8} md={4} elevation={6}>
                        <Card style={{ backgroundColor: '#ffff' }}>
                            <CardContent>
                                {
                                    active === false ?
                                        < Box py={4}>

                                            <Box pb={4}>
                                                <Typography align='center' color='primary' variant='h4' component='h1' gutterBottom>
                                                    <strong>Cambiar contraseña</strong>
                                                </Typography>
                                                {message && <Alert severity="warning">{message}</Alert>}
                                            </Box>
                                            <Box>
                                                <InputValidation
                                                    estado={email}
                                                    cambiarEstado={setEmail}
                                                    label="Correo electronico"
                                                    name="email"
                                                    helperText="Sólo correos asociados a la institución '@ups.edu.ec'"
                                                //patterns={/^[a-zA-Z0-9_.+-]+@+(est\.)?(ups.edu.ec)/}
                                                />
                                            </Box>
                                            <Box display="flex" flexDirection="row-reverse">
                                                <Button
                                                    variant="contained"
                                                    style={{ textTransform: 'capitalize' }}
                                                    color="primary"
                                                    onClick={() => validationPassword(cedula.campo)}
                                                >Siguiente</Button>
                                            </Box>
                                            <Link component={RouterLink} to={'/accounts/login/'}>Volver atras</Link>
                                        </Box>
                                        : <React.Fragment>
                                        <Typography variant="h4"><strong>Correo electrónico enviado</strong></Typography>
                                        <Box py={2}>
                                        <Typography variant="body2">Hemos enviado un correo electrónico con un enlace para que recuperes el acceso a tu cuenta.</Typography>
                                        </Box>
                                        
                                        <CardActions>
                                            <Button variant='contained' component={RouterLink} to='/'>Aceptar</Button>
                                        </CardActions>
                                    </React.Fragment>}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </ThemeProvider >
        </>
    )
}

