import React, { useState, useEffect } from 'react'
import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import {db} from '../../db/firebase'
export const PuntosPagoPage = () => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [markes, setMarkers] = useState('');
  const [position, setPosition] = useState({
       lat: 0,
       lng: 0,
    })
  useEffect(() => {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    function success(pos) {
      var crd = pos.coords;

      setPosition({
        lat: crd.latitude,
        lng: crd.longitude,
      })
    };
    function error(err) {
      console.warn('ERROR(' + err.code + '): ' + err.message);
    };
    navigator.geolocation.getCurrentPosition(success, error, options);
    console.log(position, 'position')
    loadCoordenadas()
  }, [])
  const loadCoordenadas = () => {
    db.collection('parametros/cliente/puntos_pago')
    .onSnapshot((querySnapshot) => {
        const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
     
        const data = [
          ...arrayMessage.map((doc) => {
              return {
                  id: doc.id,
                  name: doc.name,
                  img: doc.img,
                  position: { lat: doc.position._lat, lng: doc.position._long }
              };
          }),
      ];
      setMarkers(data)
      console.log(arrayMessage, 'arrayMessage')
        
    })
  }
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  const { isLoaded } = useLoadScript({
    /* googleMapsApiKey: "AIzaSyCCYpBE0mJCwN_Zs3hbX4iXQozoKlScLPk" */
    googleMapsApiKey: "AIzaSyDEjx4Biuau-25bv8HabCJWhBeY8MKzaZc"
  })
  const markers = [
    {
      id: 1,
      name: "Chicago, Illinois",
      position: { lat: 41.881832, lng: -87.623177 }
    },
    {
      id: 2,
      name: "Denver, Colorado",
      position: { lat: 39.739235, lng: -104.99025 }
    },
    {
      id: 3,
      name: "Los Angeles, California",
      position: { lat: 34.052235, lng: -118.243683 }
    },
    {
      id: 4,
      name: "New York, New York",
      position: { lat: 40.712776, lng: -74.005974 }
    }
  ];
  const containerStyle = {
    width: '100%',
    height: '800px'
  };
  return (
    <>
      {
        isLoaded &&
        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={20}
          center={position}
        >
          {markes && markes.map(({ id, name, position, img }) => (
            <Marker
              key={id}
              icon={img}
              position={position}
              onClick={() => handleActiveMarker(id)}
            >
              {activeMarker === id ? (
                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                  <div>{name}</div>
                </InfoWindow>
              ) : null}
            </Marker>
          ))}
        </GoogleMap>
      }
    </>
  )
}
