import React, { useEffect, useState } from 'react'
import { Route, Routes, BrowserRouter, HashRouter } from 'react-router-dom';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { auth, functions } from '../db/firebase'
import { Navbar } from '../components/appbar/Navbar';
import RestoreScroll from '../components/utils/RestoreScroll';
import CssBaseline from '@mui/material/CssBaseline';
import { DashboardPage } from '../pages/DashboardPage';
import { LoginPage } from '../pages/authentication/LoginPage';
import { SignupPage } from '../pages/authentication/SignupPage';
import { useDispatch, useSelector } from 'react-redux';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { authState, getClient, getInvoice, listNotifications, userDetails } from '../redux/actions/userActions';
import { SoporteTecnicoPage } from '../pages/servicios/SoporteTecnicoPage';
import { CambioPlanPage } from '../pages/servicios/CambioPlanPage';
import { CambioDomicilioPage } from '../pages/servicios/CambioDomicilioPage';
import { SuspensionServicioPage } from '../pages/servicios/SuspensionServicioPage';
import { PagoAbitmediaPage } from '../pages/PagoAbitmediaPage';
import { MigracionServicioPage } from '../pages/servicios/MigracionServicioPage';
import { ContratarPage } from '../pages/servicios/ContratarPage';
import { Camaras } from '../pages/servicios/Camaras/Camaras'
import { ServiciosPage } from '../pages/servicios/ServiciosPage';
import { DatosFacturacion } from '../pages/account/DatosFacturacion';
import { TermsPage } from '../pages/terms/TermsPage';
import { NotificationsPage } from '../pages/account/notifications/NotificationsPage';
import { ClubBeneficiosPage } from '../pages/account/ClubBeneficiosPage';
import { PuntosPagoPage } from '../pages/account/PuntosPagoPage';
import { EditPage } from '../pages/account/EditPage';
import { Servicios } from '../components/servicios/Servicios';
import { ConfiguredRouter } from '../pages/configured/ConfiguredRouter';
import { NotFoundPage } from '../pages/account/NotFoundPage';
import { listPromociones } from '../redux/actions/parametersActions';
import { ResetPassword } from '../pages/authentication/ResetPassword';

import { AbitmediaPage } from '../pages/account/AbitmediaPage';
import { CambioClaveWifiPage } from '../pages/config/CambioClaveWifiPage';
import OutContratarPage from '../pages/OutContratarPage';
import { ActualizarDatosPage } from '../pages/public/ActualizarDatosPage';
import { EncuestasInstalacionPage } from '../pages/public/EncuestasInstalacionPage';

import { ActualizarPlanPage } from '../pages/public/ActualizarPlanPage';
import { EncuestasActualizarDatosPage } from '../pages/public/EncuestasActualizarDatosPage';
import { MetaversoPage } from '../pages/MetaversoPage';
import { MicrositioPage } from '../pages/public/MicrositioPage';
import { CanalesDigitalesPage } from '../pages/public/CanalesDigitalesPage';
import { TicketsPage } from '../pages/public/TicketsPage';
import { ActualizarDatosPageTerms } from '../pages/public/ActualizarDatosPageTerms';
import { PolicyPersonalPage } from '../pages/terms/PolicyPersonalPage';
import { CodigoPagoPage } from '../pages/public/CodigoPagoPage';
import { CoinsPage } from '../pages/coins/CoinsPage';
import SubscriptionPage from '../pages/subscription/SubscriptionPage';
import { StorePage } from '../pages/tienda/StorePage';
import { ProductPage } from '../pages/tienda/ProductPage';
import { CartPage } from '../pages/tienda/cart/CartPage';
import { CheckoutPage } from '../pages/tienda/checkout/CheckoutPage';
import { CoinsHistory } from '../pages/coins/CoinsHistory';
import { CoinsDesafio } from '../pages/coins/CoinsDesafio';
import { OrderPage } from '../pages/tienda/cart/OrderPage';
import { NavbarStore } from '../pages/tienda/NavbarStore';
import { AddCard } from '@mui/icons-material';
import AddCardPage from '../pages/subscription/AddCardPage';
import Loader from '../components/utils/Loader';
import { ZappingPage } from '../pages/account/ZappingPage';
import ZappingServicePage from '../pages/servicios/zapping/ZappingServicePage';
import ComprarZapping from '../pages/servicios/zapping/ComprarZapping/ComprarZapping';
import { BuyButtonZapping2 } from '../pages/servicios/zapping/ComprarZapping/BuyButtonZapping2';
import { Mcdonaldspage } from '../pages/beneficios/Mcdonaldspage';
export const AppRouter = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const dispatch = useDispatch()
    const [checking, setChecking] = useState(true);
    const [isAuth, setIsAuth] = useState(false)
    const get_Client = useSelector(state => state.getClient)
    const { client, loading } = get_Client
    //
    let theme = createTheme({
        palette: {
            //type: "dark",
            //background: {
            //default: '#1d0226',
            //paper: '#2D033B',
            //},
            //primary: {
            //main: '#7635DC'
            //},
            //secondary: {
            //main: '#6d1b7b',
            //},
            //text: {
            //primary: '#fff',
            //secondary: '#fff',
            //disabled: '#ab9fbb'
            //}
            //},
            // typography: {
            //  button: {
            //  textTransform: "none"
            //}
        },
        components: {
            // Name of the component
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: "#2D033B",
                        color: "white",
                        boxSizing: "border-box"
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        borderRadius: '1rem',
                        textTransform: 'none',
                        fontSize: '1rem',
                        boxShadow: '0 8px 20px -12px rgba(0,0,0,0.5)',
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        //boxShadow: '0 8px 10px -12px rgba(0,0,0,0.3)',
                        //borderRadius: '.6rem',
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        boxShadow: '0 8px 20px -12px rgba(0,0,0,0.3)',
                        borderRadius: '1rem',
                    },
                },
            },
        },
    });
    theme = responsiveFontSizes(theme);
    useEffect(() => {
        auth.onAuthStateChanged(userAuth => {
            //Detectamos si el usuario esta logeado
            //&& userAuth.emailVerified
            if (userAuth) {
                console.log(userAuth, ' userAuth')
                dispatch(getClient(userAuth.displayName))
                dispatch(listNotifications(userAuth.displayName))
                dispatch(listPromociones())
                setIsAuth(true)
                setChecking(false)
            } else {
                console.log('Usuario no autenticado')
                setChecking(false);
                setIsAuth(false)
            }
            //setChecking(false);
        });

    }, [dispatch, setIsAuth, setChecking])
    if (checking) {
        return (<Loader />)
    }
    return (
        <HashRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Navbar isAuth={isAuth}>
                    <Routes>
                        <Route path='/' element={
                            <PrivateRoute isAuth={isAuth}>
                                <DashboardPage />
                            </PrivateRoute>
                        } />
                        <Route path='/accounts/login/' element={<PublicRoute isAuth={isAuth}><LoginPage /></PublicRoute>} />
                        <Route path='/encuestas_instalacion/:id' element={<EncuestasInstalacionPage />} />
                        <Route path='/actualizar_datos/' element={<ActualizarDatosPage />} />
                        <Route path='/actualizar_datos/terms' element={<ActualizarDatosPageTerms />} />
                        <Route path='/micrositio/' element={<MicrositioPage />} />
                        <Route path='/dashboard/' element={<EncuestasActualizarDatosPage />} />
                        <Route path='/canalesdigitales/' element={<CanalesDigitalesPage />} />
                        <Route path='/tickets/' element={<TicketsPage />} />
                        <Route path='/codigo_pago/' element={<CodigoPagoPage />} />
                        <Route path='/accounts/404/' element={
                            <PrivateRoute isAuth={isAuth}>
                                <NotFoundPage />
                            </PrivateRoute>
                        } />
                        <Route path='/accounts/signup/' element={
                            <PublicRoute isAuth={isAuth}>
                                <SignupPage />
                            </PublicRoute>
                        } />
                        <Route path='/accounts/creating-account/' element={
                            <PrivateRoute isAuth={isAuth}>
                                Creando Cuenta
                            </PrivateRoute>
                        } />
                        <Route path='/accounts/services/' element={
                            <PrivateRoute isAuth={isAuth}>
                                <Servicios />
                            </PrivateRoute>
                        } />
                        <Route path='coins'>
                            <Route path='coins' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <NavbarStore>
                                        <CoinsPage />
                                    </NavbarStore>
                                </PrivateRoute>
                            } />
                            <Route path='desafios' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CoinsDesafio />
                                </PrivateRoute>
                            } />
                            <Route path='historico' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CoinsHistory />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path='beneficios'>
                            <Route path='mcdonalds' element={
                                <PrivateRoute isAuth={isAuth}>
                                        <Mcdonaldspage />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path='store'>
                            <Route path='order' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <NavbarStore>
                                        <OrderPage />
                                    </NavbarStore>
                                </PrivateRoute>
                            } />
                            <Route path='productos' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <NavbarStore>
                                        <StorePage />
                                    </NavbarStore>
                                </PrivateRoute>
                            } />
                            <Route path='premio/:id' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <NavbarStore>
                                        <ProductPage />
                                    </NavbarStore>
                                </PrivateRoute>
                            } />
                            <Route path='cart' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <NavbarStore>
                                        <CartPage />
                                    </NavbarStore>
                                </PrivateRoute>
                            } />
                            <Route path='checkout' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <NavbarStore>
                                        <CheckoutPage />
                                    </NavbarStore>
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path='/metaverso' element={
                            <MetaversoPage />
                        } />
                        <Route path='/accounts/reset-password/' element={
                            <PublicRoute isAuth={isAuth}>
                                <ResetPassword />
                            </PublicRoute>
                        } />
                        <Route path='policy' element={

                            <TermsPage />

                        } />
                        <Route path='policy_personal' element={
                            <PublicRoute isAuth={isAuth}>
                                <PolicyPersonalPage />
                            </PublicRoute>
                        } />
                        <Route path='accounts'>

                            <Route path='notifications' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <NotificationsPage />
                                </PrivateRoute>
                            } />
                            <Route path='puntos-pago' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PuntosPagoPage />
                                </PrivateRoute>
                            } />
                            <Route path='edit' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <EditPage />
                                </PrivateRoute>
                            } />
                            <Route path='profile' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <EditPage />
                                </PrivateRoute>
                            } />
                            <Route path='config-router' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ConfiguredRouter />
                                </PrivateRoute>
                            } />
                            <Route path='cambio-clave' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CambioClaveWifiPage />
                                </PrivateRoute>
                            } />
                            <Route path='zapping' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ZappingPage />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path='account/subscription' element={<SubscriptionPage />} />
                        <Route path='account/subscription/addcard' element={<AddCardPage />} />
                        <Route path='contratar' element={
                            <OutContratarPage />
                        } />
                        <Route path='actualizar_plan' element={
                            <ActualizarPlanPage />
                        } />
                        <Route path='servicios' element={
                            <ServiciosPage />
                        }>
                            <Route path='soporte-tecnico' element={<SoporteTecnicoPage />} />
                            <Route path='cambio-plan' element={<CambioPlanPage />} />
                            <Route path='cambio-domicilio' element={<CambioDomicilioPage />} />
                            <Route path='anulacion-de-servicio' element={<SuspensionServicioPage />} />
                            <Route path='migracion-servicio' element={<MigracionServicioPage />} />
                            <Route path='contratar-servicio' element={<ContratarPage />} />
                            <Route path='contratar-camaras' element={<Camaras />} />
                            <Route path='zapping' element={<BuyButtonZapping2 />} />
                            {/* <Route path='zapping' element={<ComprarZapping />} /> */}
                        </Route>
                        <Route path='account' element={
                            <PrivateRoute isAuth={isAuth}>
                                <ServiciosPage />
                            </PrivateRoute>}>
                            <Route path='edit' element={<DatosFacturacion />} />
                            <Route path='mis-facturas' element={<DatosFacturacion />} />
                        </Route>
                        <Route path='pago' element={
                            <PublicRoute isAuth={isAuth}>
                                <AbitmediaPage />
                            </PublicRoute>}>
                        </Route>
                    </Routes>
                    <RestoreScroll />
                </Navbar>
            </ThemeProvider>
        </HashRouter>
    )
}