import {
    Container, Card, Typography, CardContent,
    RadioGroup, FormControlLabel, Radio, FormControl, Box, Grid, Button, Paper, DialogActions, Alert
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { db, firebase } from '../../db/firebase'
import img from '../../assets/img/encuestas_tecnicos.png'
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined';
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom";
export const EncuestasInstalacionPage = () => {

    //
    let { id } = useParams();
    //
    const [step, setStep] = useState(0);
    //
    const [res1, setRes1] = useState('')
    const [res2, setRes2] = useState('')
    const [res3, setRes3] = useState('')
    const [res4, setRes4] = useState('')
    const [res5, setRes5] = useState('')
    //
    const [client, setClient] = useState('')
    //
    useEffect(() => {
        handleClient(id)
    }, [])
    //
    const handleClient = (cedula) => {
        if (cedula && cedula.length >= 10) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "dni": cedula
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(`https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/get_clients`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const dataParse = JSON.parse(result);
                    if (dataParse && dataParse.estado === 'error') {
                        console.log('No se encontro')
                        alert('No se encontro cliente')
                    } else {
                        const data = dataParse.datos[0]
                    
                        setClient(data)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }
    const handleSubmit = () => {
        const data = {
            nombre: client.nombre,
            correo: client.correo,
            movil: client.movil,
            cedula: client.cedula,
            telefono: client.telefono,
            NUM_CONTRATO: client.NUM_CONTRATO,
            res1: res1,
            res2: res2,
            res3: res3,
            res4: res4,
            res5: res5,
            created: firebase.firestore.FieldValue.serverTimestamp() 
        }
        db.collection("encuestas").doc(client.cedula).set(data).then(() => {
            setStep(2)
        })
    }
    //
    return (
        <Paper
            sx={{
          
                backgroundColor: "transparent",
                color: "#fff",
                width: "100%",
                mb: 4,
                minHeight: "100%",
                height: "auto",
                position: 'absolute',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundColor: 'aliceblue'
            }}
        >
            <Container maxWidth='md'>
                <img width='100%' src={img} />
                <Box pt={2}></Box>
                <Card>
                    <CardContent>
                        {
                            step == 0 ?
                                <>
                                      <Typography variant='h6'>Hola {client.nombre}</Typography>

                                    <Box pt={2}>
                                        <Typography>De acuerdo a la respuesta anterior ¿Cuál es tu grado de satisfacción con respecto a la atención que recibiste del instalador?</Typography>
                                        <Box py={4}>
                                            <Grid container justifyContent="center">
                                                <FormControl >
                                                    <RadioGroup row value={res1} onChange={(e) => setRes1(e.target.value)}>
                                                        <FormControlLabel labelPlacement="top" value={1} control={<Radio />} label={<SentimentVeryDissatisfiedOutlinedIcon color='disabled' fontSize='large' />} />
                                                        <FormControlLabel labelPlacement="top" value={2} control={<Radio />} label={<SentimentDissatisfiedOutlinedIcon color='disabled' fontSize='large' />} />
                                                        <FormControlLabel labelPlacement="top" value={3} control={<Radio />} label={<SentimentSatisfiedOutlinedIcon color='disabled' fontSize='large' />} />
                                                        <FormControlLabel labelPlacement="top" value={4} control={<Radio />} label={<InsertEmoticonOutlinedIcon color='disabled' fontSize='large' />} />
                                                        <FormControlLabel labelPlacement="top" value={5} control={<Radio />} label={<SentimentVerySatisfiedOutlinedIcon color='disabled' fontSize='large' />} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography>El instalador que te atendió, ¿se identificó previo a ingresar a tu domicilio?</Typography>
                                        <Box py={4}>
                                            <FormControl>
                                                <RadioGroup value={res2} onChange={(e) => setRes2(e.target.value)}>
                                                    <FormControlLabel value="SI" control={<Radio />} label="Si" />
                                                    <FormControlLabel value="NO" control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography>De acuerdo al trato recibido, ¿qué tan amable fue el instalador?</Typography>
                                        <Box pt={4}>
                                            <FormControl>
                                                <RadioGroup value={res3} onChange={(e) => setRes3(e.target.value)}>
                                                    <FormControlLabel value="Nada amable" control={<Radio />} label="Nada amable" />
                                                    <FormControlLabel value="Muy amable" control={<Radio />} label="Muy amable" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <DialogActions>
                                        <Button
                                            variant='contained'
                                            color='inherit'
                                            disabled={!res1 || !res2 || !res3}
                                            onClick={() => setStep(1)}
                                        >Siguiente</Button>
                                    </DialogActions>
                                </>
                                : ''
                        }
                        {
                            step == 1 ?
                                <>
                                    <Box>
                                        <Typography>Aproximadamente, ¿cuánto esperaste en el proceso de instalación?</Typography>
                                        <Box pt={4}>
                                            <FormControl>
                                                <RadioGroup value={res4} onChange={(e) => setRes4(e.target.value)}>
                                                    <FormControlLabel value="15 a 25 minutos" control={<Radio />} label="15 a 25 minutos" />
                                                    <FormControlLabel value="26 a 40 minutos" control={<Radio />} label="26 a 40 minutos" />
                                                    <FormControlLabel value="41 a 60 minutos" control={<Radio />} label="41 a 60 minutos" />
                                                    <FormControlLabel value="60 minutos o más" control={<Radio />} label="60 minutos o más" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box pt={5}>
                                        <Typography>¿Fuiste asesorado sobre el buen uso y cuidado de los equipos entregados en la instalación?</Typography>
                                        <Box pt={4}>
                                            <FormControl>
                                                <RadioGroup value={res5} onChange={(e) => setRes5(e.target.value)}>
                                                    <FormControlLabel value="SI" control={<Radio />} label="Si" />
                                                    <FormControlLabel value="NO" control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <DialogActions>
                                        <Button
                                            variant='contained'
                                            color='inherit'
                                          
                                            onClick={() => setStep(0)}
                                        >Atras</Button>
                                        <Button
                                            variant='contained'
                                            disabled={!res4 || !res5}
                                            onClick={() => handleSubmit()}
                                        >Enviar formulario</Button>
                                    </DialogActions>
                                </> : ''}
                                {
                            step == 2 ?
                                <>
                                    <Box>
                                        <Typography align='center' variant='h5' gutterBottom>¡Bienvenido a la YIGARED! </Typography>
                                        <Typography align='center'>Gracias por tu tiempo, tu opinión es valiosa para seguir mejorando continuamente.</Typography>
                                    </Box>
                                </> : ''}
                    </CardContent>
                </Card>
            </Container>
        </Paper>
    )
}
