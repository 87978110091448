import React, { useState, useEffect } from 'react'
import {
    TextField, Typography, Box, Grid, Button, Container,
    Select, MenuItem, InputLabel,
    Hidden, Dialog, Card, CardContent, Alert
    , DialogTitle, DialogContent, DialogActions
    , RadioGroup, Radio, FormControl, FormControlLabel, CircularProgress
} from "@mui/material";
import { db, firebase } from '../../db/firebase'
import Swal from 'sweetalert2'
import moment from 'moment'
import logo from '../../assets/img/elipago.png'
import logo_dark from '../../assets/img/logo_dark.png'
import tarjetas from '../../assets/img/tarjetas.png'
import logo1 from '../../assets/img/Logoscanalespago.png'
import { Link as RouterLink } from 'react-router-dom';
export const AbitmediaPage = () => {
    const [contractArray, setContractArray] = useState([]);
    const [open, setOpen] = useState(false);
    const [esfuerzo, setEsfuerzo] = useState('');
    const [satisfaccion, setSatisfaccion] = useState('');
    const [recomendacion, setRecomendacion] = useState('');
    const [cedula, setCedula] = useState('');
    const [idCliente, setIdCliente] = useState('');
    const [nombre, setNombre] = useState({ campo: '', valido: null })
    const [estado, setEstado] = useState({ campo: '', valido: null })
    const [correo, setCorreo] = useState({ campo: '', valido: null })
    const [telefono, setTelefono] = useState({ campo: '', valido: null })
    const [movil, setMovil] = useState({ campo: '', valido: null })
    const [direccionPrincipal, setDireccionPrincipal] = useState({ campo: '', valido: null })
    const [url, setURL] = useState('')
    const [deuda, setDeuda] = useState('')
    const [num_contrato, setNumContrato] = useState('')
    const [tab, setTab] = useState(false)
    const [modal, setModal] = useState(false)
    const [userInfo, setUserInfo] = useState('')
    const [linea, setLinea] = useState(1)
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    //
    const getClient = (cedula) => {
        setCedula(cedula)
        if (cedula && cedula.length >= 10) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "dni": cedula
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };
            fetch(`https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/get_clients`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const dataParse = JSON.parse(result);
                    //console.log(dataParse, 'parse')
                    if (dataParse && dataParse.estado === 'error') {
                        console.log('No se encontro')
                        Swal.fire({
                            icon: 'info',
                            text: 'No se encontro cliente',
                        })
                        setCedula('')
                    } else {
                        //const data = dataParse.datos[0]
                        const no_retirado = dataParse.datos.filter(item => item.estado !== 'RETIRADO')
                        //console.log(no_retirado, ' no retirado')
                        setContractArray(no_retirado)
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            setIdCliente('');
            setNumContrato('')
            setNombre({ campo: '', valido: null })
            setDireccionPrincipal({ campo: '', valido: null })
            setEstado({ campo: '', valido: null });
            setCorreo({ campo: '', valido: null });
            setTelefono({ campo: '', valido: null });
            setMovil({ campo: '', valido: null });
        }
    }
    //

    function obtenerLinea(nombre) {
        const regex = /L(\d+)/;
        const match = nombre.match(regex);
        if (match) {
            const numero = parseInt(match[1], 10);
            return numero;
        } else {
            return 1;
        }
    }


    const handleContrato = (e) => {

        setNumContrato(e)

        console.log('esto es e:', e)


        const p = contractArray.filter((item) => item.NUM_CONTRATO === e);
        //Obtenemos el index de la linea
        const index = contractArray.findIndex(item => item.NUM_CONTRATO === e)


        // ERRORES CON LA LINEA CUANDO EL CLIENTE TIENE RETIRADA




        const data = p[0]
        setUserInfo(data)

        var linea_verdadera = obtenerLinea(data.nombre)
        console.log('verdadera linea,', linea_verdadera)
        setLinea(linea_verdadera)


        console.log('user info:', data.servicios[index].id)
        //Consultadomos deuda
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var body = JSON.stringify({
            "cedula": cedula,
            "linea": linea_verdadera
        });
        console.log({
            "cedula": cedula,
            "linea": linea_verdadera
        })
        var requestOptions2 = {
            method: 'POST',
            headers: myHeaders,
            body: body,
        };
        fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/deuda", requestOptions2)
            .then(response => response.text())
            .then(result => {
                var res = JSON.parse(result)
                console.log(res, 'deuda')
                setDeuda(res.deuda)
            })
            .catch(error => console.log('error', error))
    }
    //
    const handleSubmit = () => {
        window.localStorage.clear()
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        //
        var newDeuda = deuda / 100
        console.log(newDeuda)
        console.log(newDeuda, 'new deuda')
        console.log(userInfo.servicios[linea - 1].id, 'id servicio')
        var raw = JSON.stringify({
            "cedula": userInfo.cedula,
            "nombre": userInfo.nombre,
            "direccion": `${Math.floor(Math.random() * 100)} ${userInfo.direccion_principal.substring(0, 30)}`,
            "mobile": userInfo.telefono,
            "email": userInfo.correo,

            "description": 'description',
            "deuda": newDeuda
        })
        console.log(userInfo.direccion_principal, 'direccion_principal')
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };
        fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/abitmedia2", requestOptions)
            .then(response => response.text())
            .then(result => {
                var res = JSON.parse(result)
                console.log(res, ' res')
                if (res.errors) {
                    Swal.fire({
                        icon: 'info',
                        text: `Tenemos incoveninentes al momento de pagar contacte con soporte 045010056, Code: ${res.errors.reference}`,
                    })
                }
                setLoading(false)
                setURL(res.data)
                window.localStorage.clear()
                //window.location.href = res.data.url;

                let ventanaPago = window.open(res.data.url, "mywindow", "width=900,height=900");
                window.localStorage.clear()
                //let htmlElement = newWindow.document.documentElement.innerHTML;
                //console.log(htmlElement)
                //setPage(htmlElement)
                //console.log(res.data)
                //Agregamos el tokens de abitmedia
                var intervalo = setInterval(function () {
                    if (ventanaPago.closed) {
                        setIsLoading(true);
                        console.log('La ventana ha sido cerrada');
                        var raw2 = JSON.stringify({
                            "token": res.data.token,
                            //  "token": "cha_VIpt9JIMXTMOMtsiZ7vW2180",
                        })
                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw2,
                        };

                        fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/abitmediaConsulta", requestOptions)
                            .then(response => response.text())
                            .then(result => {
                                let dataPago = JSON.parse(result)
                                if (dataPago?.data) {
                                    if (dataPago.data[0]?.status == 1) {
                                        handleOpen()
                                    }
                                }
                            }).catch(error => {
                                setLoading(false)
                                console.log('error', error)
                            }).finally(() => {
                                setIsLoading(false);
                            });
                        clearInterval(intervalo);
                    }
                }, 1000);

                var id = `${userInfo.nombre} ${res.data.token}`
                let date = new Date()

                let formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
                var newDeuda = deuda / 100

                db.collection("abitmedia_tokens").doc(id).set({
                    nombre: userInfo.nombre,
                    token: res.data.token,
                    linea: linea,
                    estado: 0,
                    cedula: userInfo.cedula,
                    fecha_generada: formattedDate,
                    deuda: deuda,
                    deuda_: newDeuda,
                    idservicio: userInfo.servicios[linea - 1].id,
                    created: firebase.firestore.FieldValue.serverTimestamp()
                })
            }).catch(error => {
                setLoading(false)
                console.log('error', error)
            });
    }
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEnviarEncuesta = () => {
        console.log("Holi")
        db.collection('encuesta_satisfaccion').doc().set({
            cedula: userInfo.cedula,
            nombre: userInfo.nombre,
            esfuerzo: esfuerzo,
            satisfaccion: satisfaccion,
            recomendacion: recomendacion,
            medioPago: "web",
            created: firebase.firestore.FieldValue.serverTimestamp()
        })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    text: 'Gracias por compartir su experiencia',
                })
                console.log('Encuesta guardada correctamente en Firestore');
                handleClose(); // Cerrar modal después de enviar la encuesta
            })
            .catch((error) => {
                console.error('Error al guardar la encuesta:', error);
            });
    };
    return (
        <>
            <Dialog open={isLoading}>
                <DialogContent style={{ textAlign: 'center' }}>
                    <CircularProgress color="primary" style={{ marginBottom: '16px' }} />
                    <Typography variant="subtitle1">Por favor, espere...</Typography>
                </DialogContent>
            </Dialog>
            <Grid
                container
                component="main"
                alignItems="center"
                justifyContent="center"
            >
                <Grid
                    item
                    xs={false}
                    sm={false}
                    md={5}
                >
                    <Hidden mdDown>
                        <Box style={{ backgroundImage: 'linear-gradient(130deg, #3C2180 0, #3C2180 20%, #5B0081 75%)', height: '100vh' }}>
                            <CardContent>
                                <Box p={5}>
                                    <Box pt={5} style={{ textAlign: 'center' }}>
                                        <img width="25%" alt='yiga5.com' src={logo_dark} />
                                        <img width="100%" alt='yiga5.com' src={logo} />
                                    </Box>

                                </Box>
                            </CardContent>
                        </Box>
                    </Hidden>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    sx={{
                        //backgroundColor: '#fff',
                        //color: '#fff',
                        //width: '100%',
                        //height: '100%',
                        //backgroundSize: 'cover',
                        //backgroundRepeat: 'no-repeat',
                        //backgroundPosition: 'center',
                        //backgroundImage: pagofondo,
                    }}
                >
                    <Box style={{ backgroundSize: '100%' }}>
                        <Container component="Box" maxWidth="md" sx={{ mb: 4 }}>
                            <Card>
                                <CardContent>
                                    {tab === true ?
                                        <Alert severity="success">Se ha actualizado la informacion con exito</Alert>
                                        :
                                        <>

                                            <Typography
                                                variant='h4'
                                                color='secondary'
                                                align='center'
                                                component='h1'
                                            ><strong>PAGA AQUÍ</strong></Typography>
                                            {/* <Typography variant='h5' component='h1'><strong>Actualización de datos</strong></Typography> */}
                                            <Box py={3}>
                                                <TextField
                                                    fullWidth
                                                    type='number'
                                                    value={cedula}
                                                    onChange={(e) => getClient(e.target.value)}
                                                    label="CÉDULA | RUC"
                                                />
                                            </Box>
                                            {
                                                contractArray && cedula.length >= 10 &&
                                                <Box pb={3}>
                                                    <FormControl fullWidth disabled={!contractArray}>
                                                        <InputLabel >LINEA DE CONTRATO</InputLabel>
                                                        <Select
                                                            value={num_contrato}
                                                            label="LINEA DE CONTRATO"
                                                            onChange={(e) => { handleContrato(e.target.value) }}
                                                        >

                                                            {contractArray && contractArray.map((item, index) => (
                                                                <MenuItem key={index} value={item.NUM_CONTRATO}>{item.NUM_CONTRATO} - <strong>{item.estado}</strong></MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            }
                                            {
                                                num_contrato &&
                                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            value={userInfo.nombre}
                                                            variant='outlined'
                                                            disabled
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box pt={2}></Box>
                                                        <TextField

                                                            label='DEUDA'
                                                            value={`$${deuda / 100}`}
                                                            name="DEUDA"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {/* <InputValidation
                                                            estado={telefono}
                                                            cambiarEstado={setTelefono}
                                                            label="TELÉFONO"
                                                            type='number'
                                                            helperText="Iniciar con 09 y minimo 10 digitos"
                                                            patterns={/^\d{10}$/}
                                                        /> */}
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {/* <InputValidation
                                                            estado={movil}
                                                            cambiarEstado={setMovil}
                                                            label="CELULAR"
                                                            type='number'
                                                            helperText="Iniciar con 09 y minimo 10 digitos"
                                                            patterns={/^\d{10}$/}
                                                        /> */}
                                                    </Grid>
                                                </Grid>
                                            }

                                            <Box display="flex" pt={1} flexDirection="row-reverse">
                                                <Button
                                                    //onClick={() => Swal.fire({ icon: 'info', text: 'Nos encontramos en actualizacion de nuestro boton de pago para darte una mejor experiencia. Continua disfrutando de nuestros demas servicios.'})}
                                                    onClick={() => handleSubmit()}
                                                    color='secondary'
                                                    fullWidth

                                                    variant='contained'
                                                    disabled={!userInfo || !deuda || parseFloat(deuda) === 0}
                                                >PAGAR</Button>
                                            </Box>


                                            <Box pt={5} style={{ textAlign: 'center' }}>

                                                <img width="50%" alt='yiga5.com' src={tarjetas} />
                                            </Box>

                                        </>}
                                </CardContent>
                            </Card>
                            <Box pt={5}>


                                <Typography variant='h6' align='center' color='secondary'>RECUERDA QUE TAMBIÉN PUEDES HACER TUS PAGOS EN:</Typography>
                            </Box>
                            <div style={{ justifyContent: 'center', display: 'flex' }} >
                                <img width="50%" alt='yiga5.com' src={logo1} />
                            </div>

                            <Box pt={2}>
                                <Button
                                    component={RouterLink} to="/accounts/login/"
                                    //onClick={() => handleSubmit()}
                                    color='secondary'
                                    //fullWidth
                                    variant='contained'
                                //disabled={!userInfo || !deuda}
                                >VOLVER ATRÁS</Button>
                            </Box>

                        </Container>
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Encuesta de satisfacción</DialogTitle>
                <DialogContent>
                    <form>
                        <FormControl component="fieldset">
                            <p>1. En una escala del 1 al 5, ¿Qué nivel de esfuerzo le supuso navegar a través del portal 24 en línea para resolver su requerimiento?</p>
                            <RadioGroup row aria-label="esfuerzo" name="esfuerzo" value={esfuerzo} onChange={(e) => setEsfuerzo(e.target.value)}>
                                {[1, 2, 3, 4, 5].map(valor => (
                                    <FormControlLabel key={valor} value={valor.toString()} control={<Radio />} label={valor.toString()} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        <FormControl component="fieldset">
                            <p>2. En una escala del 1 al 5, ¿Cómo califica su nivel de satisfacción con los servicios que ofrece el portal 24 en línea?</p>
                            <RadioGroup row aria-label="satisfaccion" name="satisfaccion" value={satisfaccion} onChange={(e) => setSatisfaccion(e.target.value)}>
                                {[1, 2, 3, 4, 5].map(valor => (
                                    <FormControlLabel key={valor} value={valor.toString()} control={<Radio />} label={valor.toString()} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        <FormControl component="fieldset">
                            <p>3. En una escala del 0 al 10, ¿Qué tan probable es que recomiende los servicios de Yiga5 a un amigo o familiar?</p>
                            <RadioGroup row aria-label="recomendacion" name="recomendacion" value={recomendacion} onChange={(e) => setRecomendacion(e.target.value)}>
                                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(valor => (
                                    <FormControlLabel key={valor} value={valor.toString()} control={<Radio />} label={valor.toString()} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleEnviarEncuesta}>Enviar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}