import { ListItemButton, List, ListItemAvatar, Avatar, Divider, Typography, ListItemText } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
export const Notifications = () => {
  const { notifications, queryDoc, isLastPage, } = useSelector(state => state.notificationsList);
  const userAuth = useSelector(state => state.userAuth)
  const { userInfo } = userAuth
  return (
    <>
      <List>

        {
          notifications.map((item, index) => (
            <>
              <ListItemButton alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar src={userInfo.picture}>
                    <PriorityHighIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={item.titulo_mensaje}
                  secondary={
                    <>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {item.descripcion_mensaje}
                      </Typography>
                      { }
                      {` — ${moment(item.created.toDate().toString()).fromNow()}`}
                    </>
                  }
                />
              </ListItemButton>
              <Divider variant="inset" component="li" />
            </>
          ))}
      </List>
    </>
  )
}
