import { useEffect, useState } from 'react';
import {
    Container, Box, CardContent, Card, Typography, Grid, TableCell, Button, TableContainer,
    Table, TableHead, TableRow, TableBody, Stack
} from '@mui/material/';
import { db } from '../../db/firebase'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBack } from '../../components/utils/ButtonBack';
export const CoinsHistory = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [data, setData] = useState([]);
    const [plan, setPlan] = useState([]);
    useEffect(() => {
        fetchData();
    }, [userInfo?.index_linea]);
    //const load
    const fetchData = async () => {
        const querySnapshot = await db.collection(`users/${userInfo.uid}/yigapuntos/${userInfo.servicios.id}/historico`).get()
        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        setData(data)
        console.log('hola')
        console.log(data, 'hola')
        //
        await db.collection(`users/${userInfo.uid}/yigapuntos/`).doc(`${userInfo.servicios.id}`).onSnapshot((doc) => {
            let data = doc.data()
            setPlan(data)
        })
    }
    return (
        <>
            <Container>
                <Box pt={5} pb={1}>
                    <Typography gutterBottom variant='h2' >
                        <strong>
                            Histórico de YigaCoins
                        </strong>
                    </Typography>
                </Box>
                <Card>
                    <CardContent>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Descripcion</TableCell>
                                        <TableCell align="right">Puntos</TableCell>
                                        <TableCell align="right">Fecha</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.descripcion}
                                            </TableCell>
                                            <TableCell align="right">{row.puntos}</TableCell>
                                            <TableCell align="right">{moment(new Date(row.created.seconds * 1000).toISOString()).format('DD/MM/YYYY')}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
                <ButtonBack />
            </Container>
        </>
    )
}