import { Container, Box, Button, Stack, TextField, Grid, Select, FormControl, InputLabel, MenuItem } from '@mui/material'
import React, { useState, useEffect } from 'react'
import logo_superior_1 from "../../assets/img/codigo_pago/logo_superior_1.png"
import footer_cierre_logo from "../../assets/img/codigo_pago/footer_cierre_logo.png"
import banner_puntos_pago from "../../assets/img/codigo_pago/banner_puntos_pago.png"
import forma_codigo_pago from "../../assets/img/codigo_pago/forma_codigo_pago.png"
import Swal from 'sweetalert2'
export const CodigoPagoPage = () => {
    const [movil, setMovil] = useState({ campo: '', valido: null })
    const [cedula, setCedula] = useState('')
    const [contractArray, setContractArray] = useState([])
    const [num_contrato, setNumContrato] = useState('')
    const [userInfo, setUserInfo] = useState('')
    const [linea, setLinea] = useState(1)
    const [deuda, setDeuda] = useState('')
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const getClient = (cedula) => {
        setCedula(cedula)
        if (cedula && cedula.length >= 10) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "dni": cedula
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };
            fetch(`https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/get_clients`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const dataParse = JSON.parse(result);
                    //console.log(dataParse, 'parse')
                    if (dataParse && dataParse.estado === 'error') {
                        console.log('No se encontro')
                        Swal.fire({
                            icon: 'info',
                            text: 'No se encontro cliente',
                        })
                        setCedula('')
                    } else {
                        //const data = dataParse.datos[0]
                        const no_retirado = dataParse.datos.filter(item => item.estado !== 'RETIRADO')
                        //console.log(no_retirado, ' no retirado')
                        setContractArray(no_retirado)
                    }
                })
                .catch(error => console.log('error', error));
        } else {

            setNumContrato('')

        }
    }
    const handleContrato = (e) => {
        setNumContrato(e)

        const p = contractArray.filter((item) => item.NUM_CONTRATO === e);
        //Obtenemos el index de la linea
        const index = contractArray.findIndex(item => item.NUM_CONTRATO === e)
        var linea_ = index + 1
        setLinea(index + 1)
        const data = p[0]
        setUserInfo(data)
        //Consultadomos deuda
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var body = JSON.stringify({
            "cedula": cedula,
            "linea": linea_
        });
        console.log({
            "cedula": cedula,
            "linea": linea_
        })
        var requestOptions2 = {
            method: 'POST',
            headers: myHeaders,
            body: body,
        };
        fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/deuda", requestOptions2)
            .then(response => response.text())
            .then(result => {
                var res = JSON.parse(result)
                console.log(res, 'deuda')
                setDeuda(res.deuda)
            })
            .catch(error => console.log('error', error))
    }
    return (
        <Container >
            <img src={logo_superior_1} alt='' width='100%' />
            <Container maxWidth="md">
                <img src={forma_codigo_pago} alt='' width='100%' />
            </Container>
            <Box py={5}>
                <Container maxWidth="sm">
                    <Box py={3}>
                        <InputLabel>CÉDULA | RUC</InputLabel>
                        <TextField
                            fullWidth
                            type='number'
                            value={cedula}
                            onChange={(e) => getClient(e.target.value)}

                        />
                    </Box>
                    {
                        contractArray && cedula.length >= 10 &&
                        <Box pb={3}>
                           
                                <InputLabel >LINEA DE CONTRATO</InputLabel>
                                <Select
                                    value={num_contrato}
                                    fullWidth
                                    onChange={(e) => { handleContrato(e.target.value) }}
                                >

                                    {contractArray && contractArray.map((item, index) => (
                                        <MenuItem key={index} value={item.NUM_CONTRATO}>{item.NUM_CONTRATO} - <strong>{item.estado}</strong></MenuItem>
                                    ))}
                                </Select>
                         
                        </Box>
                    }
                    {
                        num_contrato &&
                        <Box pb={2}>
                            <InputLabel>CODIGO DE PAGO</InputLabel>
                            <TextField
                                value={userInfo.codigo_pago}
                                variant='outlined'
                                size='large'
                                fullWidth
                            />
                        </Box>
                    }
                </Container>
                
            </Box>
            <img src={banner_puntos_pago} alt='' width='100%' />
            <img src={footer_cierre_logo} alt='' width='100%' />
        </Container>
    )
}