import React, { useState, useEffect } from 'react'
import { auth, db } from '../../../db/firebase'
import { Container, Box, Typography, TableCell, Table, TableBody, Toolbar, TableRow, Paper, TableHead, TableContainer, Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import { CancelOrder } from '../orders/CancelOrder';
import { ButtonBack } from '../../../components/utils/ButtonBack';
export const OrderPage = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [orders, setOrders] = useState();
    useEffect(() => {
        getProduct()
    }, [])

    const getProduct = async () => {
        var requestOptions = {
            method: 'GET'
        };
        const response = await fetch(`https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/order/${userInfo.cedula}`, requestOptions)
        const result = await response.json();
        setOrders(result)
        console.log(result, 'resultado')
    }
    function Estado(status) {
        console.log(status, 'order_status')
        let name;
        let color;
        if (status.status === 0) {
            name = "En proceso";
            color = 'warning';
        } else if (status.status === 1) {
            name = "Por Retirar";
            color = "info";
        } else if (status.status === 2) {
            name = "Entregado";
            color = 'success';
        } else if (status.status === 3) {
            name = "Abandonado";
            color = 'error';
        }
        return <Chip label={name} color={color} />;
    }
    return (
        <>
            <Container>
                <div >
                    <Typography gutterBottom variant='h2' component='h1'><strong>Mis Pedidos</strong></Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Numero del Pedido</TableCell>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Estado</TableCell>
                                    <TableCell>Cantidad</TableCell>
                                    <TableCell>Color</TableCell>
                                    <TableCell>Size</TableCell>
                                    <TableCell>Local de retiro</TableCell>
                                    <TableCell>Cancelar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders?.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell >{row?.product_photo[0] &&
                                            <img src={row?.product_photo[0]} alt='' width={50} />
                                        }</TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.order_id}
                                        </TableCell>
                                        <TableCell>{row.product_name}</TableCell>
                                        <TableCell><Estado status={row.order_status} /> </TableCell>
                                        <TableCell>{row.product_quantity}</TableCell>
                                        <TableCell>{row.product_color}</TableCell>
                                        <TableCell>{row.product_size}</TableCell>
                                        <TableCell>{row.order_send}</TableCell>
                                        <TableCell>
                                            <CancelOrder />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <Box py={2}></Box>
                <ButtonBack />
            </Container>
        </>
    )
}