import { Button, Dialog, IconButton, CircularProgress, Grid, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { firebase, db } from '../../db/firebase'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import { addPointsLine } from '../../redux/actions/accountActions';
export const BtnPagoRecurrente = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [modal, setModal] = useState(false)
    const [deuda, setDeuda] = useState(0)
    const [url, setURL] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        getDeuda()
    }, [])
    const getDeuda = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "cedula": userInfo.cedula,
            "linea": userInfo.index_linea
        });
        console.log({
            "cedula": userInfo.cedula,
            "linea": userInfo.index_linea
        })
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/deuda", requestOptions)
            .then(response => response.text())
            .then(result => {
                var res = JSON.parse(result)
                setDeuda(res.deuda)
            })
            .catch(error => console.log('error', error));
    }
    const handle = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer mjwguxipbjg1iitxkjxb6tz6s3tl8f-rrgi371ad1sjkhgnrs0fcvvn8tkzworjbkcbsm");
        myHeaders.append("Cookie", "_csrf=85997cf2f927b1da811c84c3cbebb86750f5a1cd566eb703eafe3bd44eb28443a%3A2%3A%7Bi%3A0%3Bs%3A5%3A%22_csrf%22%3Bi%3A1%3Bs%3A32%3A%22nnYxQwf0zpUoTnwGUw2E3r-1rl0towCl%22%3B%7D");
        var raw = JSON.stringify({
            "integration": true,
            "third": {
                "document": userInfo.cedula,
                "document_type": "05",
                "name": userInfo.cedula,
                "email": userInfo.email,
                "phones": userInfo.telefono,
                "address": userInfo.cedula,
                "type": "Individual"
            },
            "notify_url": null,
            "custom_value": null
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        let date = new Date()
        let formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
        fetch("https://api.abitmedia.cloud/pagomedios/v2/cards/register", requestOptions)
            .then(response => response.text())
            .then(result => {
                var res = JSON.parse(result)
                var id = `${userInfo.nombre} ${res.data.token}`
                setURL(res.data)
                console.log(result)

                var myHeaders2 = new Headers(); // Asegúrate de que 'myHeaders' esté definido antes de usarlo
                myHeaders.append("Authorization", "Bearer mjwguxipbjg1iitxkjxb6tz6s3tl8f-rrgi371ad1sjkhgnrs0fcvvn8tkzworjbkcbsm");
                var requestOptions2 = {
                    method: 'GET',
                    headers: myHeaders2,
                    redirect: 'follow'
                };
                fetch(`https://api.abitmedia.cloud/pagomedios/v2/cards?integration=true&document=${userInfo.cedula}`, requestOptions2)
                    .then(response => response.text()) // Usa response.json() para parsear la respuesta JSON
                    .then(result => {
                        var tarjeta = JSON.parse(result);
                         // No necesitas parsear 'result' ya que fetch() lo hace automáticamente
                        db.collection("abitmedia_tokens_recurrencia").doc(id).set({
                            nombre: userInfo.nombre,
                            token: tarjeta.data[0].cards[0].token,
                            linea: userInfo.index_linea,
                            estado: 2,
                            idservicio:userInfo.idservicio, 
                            cedula: userInfo.cedula,
                            fecha_generada: formattedDate,
                            created: firebase.firestore.FieldValue.serverTimestamp()
                        });
                        console.log(tarjeta); 
                    })
                    .catch(error => console.log('error', error));
                       
                        
                setLoading(false)
                window.localStorage.clear()
                window.open (res.data.url, "mywindow","width=900,height=900");
                window.localStorage.clear()
            })
            .catch(error => console.log('error', error));
        setLoading(true)
    }
    return (
        <>
            <Button
                onClick={() => handle()}
                disabled={loading}
                variant="contained"
            >{loading ? 'Cargando...' : 'Pago Recurrente'}</Button>
            <Dialog
                fullScreen
                fullWidth={true}
                maxWidth="lg"
                open={modal}
            >
                <DialogTitle>
                    {`Pago Recurrente`}
                    {modal ? (
                        <IconButton
                            aria-label="close"
                            onClick={() => setModal(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : null}

                </DialogTitle>
                {
                    loading ?
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            style={{ minHeight: '50vh' }}
                        >
                            <Grid item xs={3}>
                                <CircularProgress />
                            </Grid>

                        </Grid>
                        : <iframe
                            id="myIFrame"
                            height='900'
                            src={url.url}
                            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                        />
                }
            </Dialog>
        </>
    )
}
