import React from 'react'
import { Grid, Typography, Box, CardContent, Card } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import wifi from '../../assets/svg/wifi.svg'
import password from '../../assets/svg/password.svg'
import seguridad from '../../assets/svg/seguridad.svg'
export const ConfiguredRouter = () => {
    return (
        <div>
            <Box pb={5}>
                <Grid container spacing={5}>
                    <Grid item xs={12} >
                        <RouterLink style={{ textDecoration: 'none' }} to='/accounts/cambio-clave'>
                        <Card>
                            <CardContent>
                                <Box py={5}>
                                    <Grid container style={{ height: '100px' }}>
                                        <Grid item xs={8}>
                                            <Box display="flex" justifyContent="center">
                                                <Box>
                                                    <Typography sx={{ mb: 1.5 }} variant="h4">
                                                        <strong>Cambio de clave Wifi</strong>
                                                    </Typography>
                                                    <Typography color="textSecondary" variant="h6">
                                                        Cambio la clave actual de tu red wifi
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box display="flex" justifyContent="center">
                                                <img src={password} alt='soporte' width='300px' />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                            
                        </RouterLink>
                    </Grid>
                    <Grid item xs={6} >
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={5}>
                <Grid item xs={6} >
                    <RouterLink style={{ textDecoration: 'none' }} to='/accounts/404'>
                        <Card>
                                <CardContent>
                                    <Box py={5}>
                                        <Grid container style={{ height: '100px' }}>
                                            <Grid item xs={8}>
                                                <Box display="flex" justifyContent="center">
                                                    <Box>
                                                        <Typography sx={{ mb: 1.5 }} variant="h5">
                                                            <strong>Configurar mi Router</strong>
                                                        </Typography>
                                                        <Typography color="textSecondary" variant="h6">
                                                            Restaura tu router
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box display="flex" justifyContent="center">
                                                    <img src={wifi} alt='soporte' width='150px' />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                    </RouterLink>
                </Grid>
                <Grid item xs={6} >
                    <RouterLink style={{ textDecoration: 'none' }} to='/accounts/404'>
                        <Card>
                            <CardContent>
                                <Box py={5}>
                                    <Grid container style={{ height: '100px' }}>
                                        <Grid item xs={8}>
                                            <Box display="flex" justifyContent="center">
                                                <Box>
                                                    <Typography sx={{ mb: 1.5 }} variant="h5">
                                                        <strong>Analisis de mi Internet</strong>
                                                    </Typography>
                                                    <Typography color="textSecondary" variant="h6">
                                                    Analisis de mi Internet
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box display="flex" justifyContent="center">
                                                <img src={seguridad} alt='soporte' width='200px' />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </RouterLink>
                </Grid>
            </Grid>
        </div>
    )
}