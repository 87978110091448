import React, { useState } from 'react'
import { storage, db, firebase } from '../../../db/firebase'
import { Dialog, Tooltip, DialogContent, DialogActions, DialogTitle, IconButton, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';

export const CancelOrder = ({ props }) => {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState('')
    const handleAddProducts = async () => {
        //setLoading(true)
        //se pone estado abondonado
        await db.collection('store_orders').doc(data.order_id).set({
            estado: 2,
        })
        //se regresa el inventario
        await db.collection('store_inventario').doc(data.uid_producto).set({
            product_quantity: firebase.firestore.FieldValue.increment(+data.product_quantity),
        })
        setOpen(false)
    }
    const onData = () => {
        console.log(props, 'props')
        setData(props)
        setOpen(true)
    }
    return (
        <div >
            <Tooltip title="Cancelar">
                <IconButton onClick={() => onData()}>
                    <CancelIcon fontSize='small' />
                </IconButton>
            </Tooltip>
            <Dialog open={open} fullWidth maxWidth="xs">
                <DialogTitle>
                    Cancelar Orden
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    ¿Quieres cancelar el producto?
                </DialogContent>
                <DialogActions>
                    <button
                        onClick={() => handleAddProducts()}
                        className="btn btn-primary"
                    >Cancelar Producto</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
