
import React, { useEffect } from 'react'
import { Chip, Box, Button } from '@mui/material/';
import { useDispatch, useSelector } from 'react-redux';
import { getDatoFactura } from '../../redux/actions/accountActions';
import {
    DataGrid,
    esES
} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import { pdfView } from './pdfView';
import * as html2pdf from 'html2pdf.js';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

function Estado({ props }) {
    let name;
    let color;
    if (props == 'pagado') {
        name = 'PAGADO'
        color = 'success'
    } else {
        name = 'EMITIDA'
        color = 'info'
    }
    return (
        <Chip label={name} size='small' variant='outlined' color={color} />
    )
}
function Estado2({ props }) {

    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const createPDF = (props) => {
        // Crea un elemento div que contiene el contenido del PDF
        const content = document.createElement('div');
        content.innerHTML = `<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml" lang="" xml:lang="">
<head>
<title>Page 1</title>

<meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
<style type="text/css">
<!--
	p {margin: 0; padding: 0;}	.ft00{font-size:12px;font-family:Helvetica;color:#000000;}
	.ft01{font-size:12px;font-family:Helvetica;color:#000000;}
	.ft02{font-size:10px;font-family:Helvetica;color:#000000;}
	.ft03{font-size:10px;font-family:Helvetica;color:#000000;}
	.ft04{font-size:13px;font-family:Helvetica;color:#000000;}
	.ft05{font-size:15px;font-family:Helvetica;color:#000000;}
	.ft06{font-size:13px;font-family:Helvetica;color:#000000;}
	.ft07{font-size:10px;line-height:12px;font-family:Helvetica;color:#000000;}
	.ft08{font-size:13px;line-height:15px;font-family:Helvetica;color:#000000;}
	.ft09{font-size:12px;line-height:13px;font-family:Helvetica;color:#000000;}
-->
</style>
</head>
<body>
<div style="width:892px;height:999px;">
<img width="892" height="1261" src="https://sf.gov/sites/default/files/styles/default/public/2020-06/get-tested-icon.png?itok=16MzwFw3" alt="background image"/>
<p style="position:absolute;top:105px;left:660px;white-space:nowrap" class="ft00"><b>SUBTOTAL&#160;IVA&#160;0%&#160;</b>0.00</p>
<p style="position:absolute;top:131px;left:609px;white-space:nowrap" class="ft00"><b>SUBTOTAL&#160;no&#160;objeto&#160;de&#160;iva&#160;</b>0.00</p>
<p style="position:absolute;top:157px;left:599px;white-space:nowrap" class="ft00"><b>SUBTOTAL&#160;SIN&#160;IMPUESTOS&#160;</b>${props.subtotal}</p>
<p style="position:absolute;top:183px;left:599px;white-space:nowrap" class="ft00"><b>SUBTOTAL&#160;EXCENTO&#160;DE&#160;IVA&#160;</b>0.00</p>
<p style="position:absolute;top:328px;left:25px;white-space:nowrap" class="ft02"><b>INTERCOMMERCE&#160;S.A</b></p>
<p style="position:absolute;top:359px;left:25px;white-space:nowrap" class="ft02"><b>Direccion&#160;Matriz</b></p>
<p style="position:absolute;top:352px;left:125px;white-space:nowrap" class="ft07">MAPASINGUE&#160;ESTE&#160;AV.&#160;VIA&#160;DAULE&#160;Nro.&#160;1<br/>Km&#160;5.5</p>
<p style="position:absolute;top:389px;left:25px;white-space:nowrap" class="ft02"><b>Direccion&#160;Sucursal</b>MAPASINGUE&#160;ESTE&#160;AV.&#160;VIA&#160;DAULE&#160;Nro.&#160;1</p>
<p style="position:absolute;top:401px;left:198px;white-space:nowrap" class="ft03">Km&#160;5.5&#160;Mz&#160;1&#160;SL&#160;12</p>
<p style="position:absolute;top:425px;left:25px;white-space:nowrap" class="ft02"><b>Telefono&#160;</b>0979370118&#160;/&#160;045010056</p>
<p style="position:absolute;top:449px;left:25px;white-space:nowrap" class="ft03">OBLIGADO&#160;A&#160;LLEVAR&#160;CONTABILIDAD:&#160;SI</p>
<p style="position:absolute;top:476px;left:25px;white-space:nowrap" class="ft03">Agente&#160;Retencion&#160;Resolucion&#160;Nro.&#160;NAC-DNCRASC20-00000001</p>
<p style="position:absolute;top:229px;left:463px;white-space:nowrap" class="ft04"><b>R.U.C:</b></p>
<p style="position:absolute;top:228px;left:508px;white-space:nowrap" class="ft05">0992988061001</p>
<p style="position:absolute;top:257px;left:463px;white-space:nowrap" class="ft04"><b>FACTURA&#160;</b>NA</p>
<p style="position:absolute;top:286px;left:463px;white-space:nowrap" class="ft04"><b>NUMERO&#160;DE&#160;AUTORIZACION</b></p>
<p style="position:absolute;top:313px;left:463px;white-space:nowrap" class="ft08">2607202201099298806100120010130000000120034<br/>322913</p>
<p style="position:absolute;top:352px;left:463px;white-space:nowrap" class="ft04"><b>Fecha&#160;y&#160;Hora&#160;de&#160;Autorizacion&#160;</b>${props.operaciones[0].fecha_pago}</p>
<p style="position:absolute;top:380px;left:463px;white-space:nowrap" class="ft04"><b>AMBIENTE&#160;</b>PRODUCCION</p>
<p style="position:absolute;top:408px;left:463px;white-space:nowrap" class="ft04"><b>EMISION&#160;</b>NORMAL</p>
<p style="position:absolute;top:436px;left:463px;white-space:nowrap" class="ft04"><b>CLAVE&#160;DE&#160;ACCESO</b></p>
<p style="position:absolute;top:467px;left:463px;white-space:nowrap" class="ft08">Para&#160;la&#160;atencion&#160;de&#160;reclamos&#160;NO&#160;resueltos&#160;por&#160;el<br/>prestador,&#160;ingrese&#160;su&#160;reclamo&#160;al&#160;link:<br/>http//reclamoconsumidor.arcotel.gob.ec/osTicket,<br/>o&#160;para&#160;informacion&#160;comuniquese&#160;con&#160;el&#160;numero<br/>telefonico</p>
<p style="position:absolute;top:599px;left:25px;white-space:nowrap" class="ft00"><b>Razon&#160;Social/Nombres:&#160;</b>CONSORCIO&#160;CONSTANTI-CONSTRUDIPRO</p>
<p style="position:absolute;top:599px;left:641px;white-space:nowrap" class="ft00"><b>Identification:&#160;</b>${userInfo.cedula}</p>
<p style="position:absolute;top:632px;left:25px;white-space:nowrap" class="ft00"><b>Direccion:&#160;COOP&#160;HECTOR&#160;COBOS&#160;MZ&#160;11&#160;SL&#160;9</b></p>
<p style="position:absolute;top:631px;left:642px;white-space:nowrap" class="ft01">Fecha&#160;Emision:&#160;${props.emitido}</p>
<p style="position:absolute;top:700px;left:40px;white-space:nowrap" class="ft00"><b>Cod.&#160;Principal</b></p>
<p style="position:absolute;top:714px;left:70px;white-space:nowrap" class="ft01">S070</p>
<p style="position:absolute;top:700px;left:254px;white-space:nowrap" class="ft00"><b>Descripcion</b></p>
<p style="position:absolute;top:714px;left:213px;white-space:nowrap" class="ft01">PLAN&#160;POWER&#160;DEFENDER</p>
<p style="position:absolute;top:700px;left:393px;white-space:nowrap" class="ft00"><b>Detalles&#160;Adicionales</b></p>
<p style="position:absolute;top:700px;left:539px;white-space:nowrap" class="ft00"><b>Cantidad</b></p>
<p style="position:absolute;top:714px;left:556px;white-space:nowrap" class="ft01">1.0</p>
<p style="position:absolute;top:699px;left:620px;white-space:nowrap" class="ft01">Precio&#160;Unitario</p>
<p style="position:absolute;top:714px;left:644px;white-space:nowrap" class="ft00"><b>28.71</b></p>
<p style="position:absolute;top:700px;left:729px;white-space:nowrap" class="ft00"><b>Descuento</b></p>
<p style="position:absolute;top:714px;left:744px;white-space:nowrap" class="ft01">00.00</p>
<p style="position:absolute;top:782px;left:55px;white-space:nowrap" class="ft00"><b>Forma&#160;Pago</b></p>
<p style="position:absolute;top:796px;left:50px;white-space:nowrap" class="ft01">${props.formapago}</p>
<p style="position:absolute;top:782px;left:206px;white-space:nowrap" class="ft09"><b>Valor<br/></b>${props.cobrado}</p>
<p style="position:absolute;top:782px;left:260px;white-space:nowrap" class="ft00"><b>Plazo</b></p>
<p style="position:absolute;top:796px;left:273px;white-space:nowrap" class="ft01">0</p>
<p style="position:absolute;top:782px;left:316px;white-space:nowrap" class="ft00"><b>Tiempo</b></p>
<p style="position:absolute;top:796px;left:323px;white-space:nowrap" class="ft01">DIAS</p>
<p style="position:absolute;top:782px;left:620px;white-space:nowrap" class="ft00"><b>SUBTOTAL&#160;IVA&#160;%12</b></p>
<p style="position:absolute;top:782px;left:760px;white-space:nowrap" class="ft01">${props.subtotal}</p>
<p style="position:absolute;top:882px;left:94px;white-space:nowrap" class="ft01">Informacion&#160;Adicional:&#160;_______________________</p>
<p style="position:absolute;top:908px;left:94px;white-space:nowrap" class="ft01">CORREO:&#160;${props.correo}</p>
<p style="position:absolute;top:882px;left:686px;white-space:nowrap" class="ft00"><b>DESCUENTOS&#160;</b>0.00</p>
<p style="position:absolute;top:908px;left:749px;white-space:nowrap" class="ft00"><b>ICE&#160;</b>0.00</p>
<p style="position:absolute;top:935px;left:711px;white-space:nowrap" class="ft00"><b>IVA&#160;12%&#160;</b>$&#160;${props.impuesto}</p>
<p style="position:absolute;top:961px;left:677px;white-space:nowrap" class="ft00"><b>VALOR&#160;TOTAL&#160;</b>${props.total}</p>
</div>
</body>
</html>
`;

        // Convierte el contenido del PDF en un archivo
        const options = {
            filename: `factura.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        html2pdf().set(options).from(content).save();
    }
    return (
        <IconButton size='small' variant='contained' onClick={() => createPDF(props)}>
            <FileDownloadIcon />
        </IconButton >
    )
}
export const DatosFacturacion = () => {

    const columns = [
        {
            field: 'estado', headerName: 'Estado', width: 200,
            renderCell: (params) => {
                return <Estado props={params.row.estado} />
            }
        },
        { field: 'formapago', headerName: 'Forma de Pago', width: 200 },
        { field: 'vencimiento', headerName: 'Emitido', width: 200 },
        { field: 'emitido', headerName: 'Vencimiento', width: 200 },
        { field: 'subtotal', headerName: 'Sub Total', width: 150 },
        { field: 'total', headerName: 'Total', width: 200 },
        // {
        //     field: '', headerName: 'Descargar', width: 200,
        //     renderCell: (params) => {
        //         return <Estado2 props={params.row} />
        //     }
        // },
    ]
    const getFacturacion = useSelector(state => state.getFacturacion)
    const { facturas, loading } = getFacturacion
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getDatoFactura())
    }, [dispatch])
    return (
        <>
            <Box paddingTop={2} sx={{ height: 417, width: '100%' }}>
                <DataGrid
                    loading={loading}
                    density='compact'
                    disableSelectionOnClick
                    disableExtendRowFullWidth
                    rows={facturas ? facturas : []}
                    rowsPerPageOptions={[]}
                    hideFooter={true}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    columns={columns}
                />
            </Box>
        </>
    )
}