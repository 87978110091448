import {  Card, ListItemButton, Container, Box, Typography, CardContent } from '@mui/material'
import { Notifications } from '../../../components/account/Notifications'
export const NotificationsPage = () => {
  return (
    <>
      <Container>
        <Typography variant="h3" component="h1"><strong>Notificaciones</strong></Typography>
        <Box pt={2}></Box>
        <Card>
          <CardContent>
            <Notifications />
          </CardContent>
        </Card>
      </Container>
    </>
  )
}
