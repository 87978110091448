export const tipoProblemaReducer = (state = { parameters: [] }, action) => {
    switch (action.type) {
        case 'TIPO_PROBLEMA_LIST_REQUEST':
            return { ...state, loading: true }
        case 'PARAMETERS_LIST_SUCCESS':
            return { loading: false, success: true, parameters: action.payload }
        case 'PARAMETERS_LIST_FAIL':
            return { loading: false, error: action.payload }
        case 'PARAMETERS_LIST_RESET':
            return { parameters: {} }
        default:
            return state
    }
}
export const promocionesReducer = (state = { list_promociones: [] }, action) => {
    switch (action.type) {
        case 'PROMOCIONES_LIST_REQUEST':
            return { ...state, loading: true }
        case 'PROMOCIONES_LIST_SUCCESS':
            return { loading: false, success: true, list_promociones: action.payload }
        case 'PROMOCIONES_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
//
export const ubicacionReducer = (state = { list_ubicacion: [] }, action) => {
    switch (action.type) {
        case 'UBICACION_LIST_REQUEST':
            return { ...state, loading: true }
        case 'UBICACION_LIST_SUCCESS':
            return { loading: false, success: true, list_ubicacion: action.payload }
        case 'UBICACION_LIST_FAIL':
            return { loading: false, error: action.payload }
        case 'UBICACION_LIST_RESET':
            return { parameters: {} }
        default:
            return state
    }
}
export const getPlanesReducer = (state = { list_planes: [] }, action) => {
    switch (action.type) {
        case 'PLANES_LIST_REQUEST':
            return { ...state, loading: true }
        case 'PLANES_LIST_SUCCESS':
            return { loading: false, success: true, list_planes: action.payload }
        case 'PLANES_LIST_FAIL':
            return { loading: false, error: action.payload }
        case 'PLANES_LIST_RESET':
            return { parameters: {} }
        default:
            return state
    }
}