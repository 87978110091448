import { db } from '../../db/firebase'
export const listUbicacion = () => async (dispatch) => {
    try {
        dispatch({ type: 'UBICACION_LIST_REQUEST' })
        db.collection('parametros/cliente/ubicacion')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'UBICACION_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'UBICACION_LIST_FAIL',
            payload: error
        })
    }
}
export const listTipoProblema = () => async (dispatch) => {
    try {
        dispatch({ type: 'PARAMETERS_LIST_REQUEST' })
        db.collection('parametros/cliente/tipo_problema')
            .get().then((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
        
                dispatch({
                    type: 'PARAMETERS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'PARAMETERS_LIST_FAIL',
            payload: error
        })
    }
}
export const listPromociones = () => async (dispatch) => {
    try {
        dispatch({ type: 'PROMOCIONES_LIST_REQUEST' })
        db.collection('promociones')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
               
                dispatch({
                    type: 'PROMOCIONES_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'PROMOCIONES_LIST_FAIL',
            payload: error
        })
    }
}
export const listPlanes = () => async (dispatch) => {
    try {
        dispatch({ type: 'PLANES_LIST_REQUEST' })
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        }
        //192.168.90.21
        fetch("https://serverbd.intercommerce.com.ec/apiMikrowispPerfilesplanes/", requestOptions)
            .then(response => response.text())
            .then(result => {
                const dataJSON = JSON.parse(result)
                console.log(dataJSON, 'data json')
                dispatch({
                    type: 'PLANES_LIST_SUCCESS',
                    payload: dataJSON
                })
            })
            .catch(error => console.log('error', error));
    } catch (error) {
        dispatch({
            type: 'PLANES_LIST_FAIL',
            payload: error
        })
    }
}