import { useEffect, useState } from 'react';
import {
    Container, Box, Card, Typography, Grid, CardContent, Stack
} from '@mui/material/';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import icon_yigacoin from '../../assets/svg/icon_yigacoin.svg'
export const StorePage = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        fetchData();
    }, []);
    //const load
    const fetchData = async () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/premios", requestOptions)
            .then(response => response.text())
            .then(result => {
                const re = JSON.parse(result)
                console.log(re)
                setData(re)
            })
            .catch(error => console.log('error', error));
    }
    return (
        <>
            <Container>
                <Box pl={2} pb={2}>
                  
                    <Typography gutterBottom variant='h5' component='h2'>Una lista de nuestros premios canjeables por Yigacoins</Typography>
                </Box>
                <Grid container spacing={5}>
                    {
                        data.map((item, index) => (
                            <Grid key={item.id} item xs={3}>
                                <Card onClick={() => navigate(`/store/premio/${item.id}`)} style={{ height: '250px' }}>
                                    <Carousel
                                        infiniteLoop={true}
                                        autoPlay={true}
                                        showStatus={false}
                                        showIndicators={false}
                                        showThumbs={false}
                                        showArrows={false}
                                    >
                                        {item?.product_photo?.map((item, index) => (
                                            <img key={index} src={item} alt='' width='100%' />
                                        ))}
                                    </Carousel>
                                </Card>
                                <CardContent>
                                    <Typography>{item.product_name}</Typography>
                                    <Stack direction="row" spacing={1} alignItems='center' >
                                        <img src={icon_yigacoin} alt='' width='20px' />
                                        <Typography><strong>{item.product_price}</strong></Typography>
                                    </Stack>
                                </CardContent>
                            </Grid>
                        ))}
                </Grid>
            </Container>
        </>
    )
}