import { Container, MenuItem, Typography, Card, Paper, TextField, FormControl, Box, InputLabel, Select, CardContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { authState } from '../../redux/actions/userActions'
import { Servicios } from '../../components/servicios/Servicios'
import eli from '../../assets/img/eli.png'
export const TicketsPage = () => {
    const dispatch = useDispatch()
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [contractArray, setContractArray] = useState([]);
    const [cedula, setCedula] = useState('');
    const [num_contrato, setNumContrato] = useState('')
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const getClient = (cedula) => {
        setCedula(cedula)
        if (cedula && cedula.length >= 10) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "dni": cedula
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
            };
            fetch(`https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/get_clients`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const dataParse = JSON.parse(result)
                    if (dataParse && dataParse.estado === 'error') {
                        console.log('No se encontro')

                        setCedula('')
                    } else {
                        const no_retirado = dataParse.datos.filter(item => item.estado !== 'RETIRADO')
                        setContractArray(no_retirado)
                        console.log(no_retirado, 'no_retirado')
                    }
                })
                .catch(error => console.log('error', error));
        } else {
        }
    }
    const handleContrato = (e) => {
        setNumContrato(e)
        const p = contractArray.filter((item) => item.NUM_CONTRATO === e);
        //Obtenemos el index de la linea
        const index = contractArray.findIndex(item => item.NUM_CONTRATO === e)
        const data = p[0]
        const servicio = data.servicios[index]
        dispatch(authState({
            ...data,
            id_cliente: data.id,
            servicios: servicio,
            index_linea: index + 1
        }))
    }

    return (
        <Paper
            sx={{
                backgroundColor: 'transparent',
                color: '#fff',
                width: '100%',
                height: '100vh',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `linear-gradient(207deg, rgba(220,63,251,1) 0%, rgba(84,0,136,1) 62%)`,
            }}
        >
            <Container>
                <Box py={3}>
                <Card>
                    <CardContent>
                        <Typography color='secondary' variant="h3"><strong>Generar Tickets</strong> </Typography>
                        <Typography>Ingrese su número cédula, ruc o pasaporte</Typography>
                        <Box py={3}>
                            <TextField
                                fullWidth
                                type='number'
                                value={cedula}
                                onChange={(e) => getClient(e.target.value)}
                                label="CÉDULA | RUC"
                            />
                        </Box>
                        {
                            contractArray && cedula.length >= 10 &&
                            <Box pb={3}>
                                <Box pb={2}>
                                    <Typography>Seleccione la linea del titular que realiza la solicitud</Typography>
                                </Box>
                                <FormControl fullWidth disabled={!contractArray}>
                                    <InputLabel >LINEA DE CONTRATO</InputLabel>
                                    <Select
                                        value={num_contrato}
                                        label="LINEA DE CONTRATO"
                                        onChange={(e) => { handleContrato(e.target.value) }}
                                    >

                                        {contractArray && contractArray.map((item, index) => (
                                            <MenuItem key={index} value={item.NUM_CONTRATO}>{item.NUM_CONTRATO} - <strong>{item.estado}</strong></MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                        {
                            userInfo.id &&
                            <>
                                {cedula &&
                                    <Servicios />
                                }
                            </>
                        }     </CardContent>
                </Card>
                </Box>
            </Container>
        </Paper>
    )
}