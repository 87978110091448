import { Typography, Grid } from '@mui/material'
import React from 'react'
import image from '../../assets/img/eli.png'
export const NotFoundPage = () => {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={4} style={{textAlign: "center"}}>
          <img src={image} width='25%' />
          <Typography>Sigue disfrutando de nuestros servicios, muy pronto tendremos esta opcion disponible para ti.</Typography>
        </Grid>
      </Grid>
    </>
  )
}
