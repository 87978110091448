export const createSoporteTecnicoReducer = (state = { create: [] }, action) => {
    switch (action.type) {
        case 'CREATE_SOPORTE_TECNICO_REQUEST':
            return { ...state, loading: true }
        case 'CREATE_SOPORTE_TECNICO_SUCCESS':
            return { loading: false, success: true, create: action.payload }
        case 'CREATE_SOPORTE_TECNICO_FAIL':
            return { loading: false, error: action.payload }
        case 'CREATE_SOPORTE_TECNICO_RESET':
            return { parameters: {} }
        default:
            return state
    }
}