import Swal from 'sweetalert2'
import moment from 'moment'
import { db } from '../../db/firebase'
import { addPointsLine } from './accountActions';

export const createSoporTecnico =
  (formData, navigate) => async (dispatch, getState) => {
    try {
      dispatch({ type: "CREATE_SOPORTE_TECNICO_REQUEST" });
      const {
        userAuth: { userInfo },
      } = getState();
      //
      var today = new Date();
      const date = moment(today).format("YYYY-MM-DD h:mm:ss");
      var formdata = new FormData();
      formdata.append("cedula", userInfo.cedula);
      formdata.append("idcliente", userInfo.id);
      formdata.append("nombres", userInfo.nombre);
      formdata.append("estadocliente", "1");
      formdata.append("plan", "");
      formdata.append("direccion", userInfo.direccion_principal);
      formdata.append("email", userInfo.correo);
      formdata.append("usuario_ingreso", "24enlinea");
      //
      formdata.append("idservicio", userInfo.servicios.id);
      formdata.append("estadosolicitud", "0");
      formdata.append("fecha_ingreso", date);
      formdata.append("fecha_modificacion", date);
      formdata.append("codigo", "0");
      formdata.append("unidadnegocio", userInfo.ubicacion);
      formdata.append("telefonos", formData.telefono);
      formdata.append("tipoproblema", formData.tipoproblema);
      formdata.append("descripciontipootro", formData.descripciontipootro);
      formdata.append("usuarioacciones", "0");
      formdata.append("usuario_modificacion", userInfo.nombre);
      formdata.append("observacion", formData.descripciontipootro);
      //
      console.log("cedula", userInfo.cedula);
      console.log("idcliente", userInfo.id);
      console.log("nombres", userInfo.nombre);
      console.log("estadocliente", "1");
      console.log("plan", "");
      console.log("direccion", userInfo.direccion_principal);
      console.log("email", userInfo.correo);
      console.log("usuario_ingreso", "24enlinea");
      console.log("estadosolicitud", "0");
      console.log("fecha_ingreso", date);
      console.log("fecha_modificacion", date);
      console.log("codigo", "0");
      console.log("unidadnegocio", userInfo.ubicacion);
      console.log("telefonos", userInfo.telefono);
      console.log("tipoproblema", formData.tipoproblema);
      console.log("descripciontipootro", formData.descripciontipootro);
      console.log("usuarioacciones", "0");
      console.log("usuario_modificacion", userInfo.nombre);
      console.log("observacion", formData.descripciontipootro);
      console.log(userInfo, "form data");
      //
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        unidadnegocio: userInfo.NUM_CONTRATO,
        cedula: userInfo.cedula,
        idcliente: userInfo.id,
        nombres: userInfo.nombre,
        idservicio: userInfo.servicios.id,
        estadocliente: userInfo.estado,
        plan: userInfo.servicios.perfil,
        direccion: userInfo.direccion_principal,
        email: userInfo.correo,
        telefonos: formData.telefono,
        usuario_ingreso: "24enlinea",
        tipoproblema: formData.tipoproblema,
        descripciontipootro: formData.descripciontipootro,
      });

      var requestOptions = {
        method: "POST",
        body: raw,
        headers: myHeaders,
        redirect: "follow",
      };
      console.log(raw);
      // fetch("https://serverbd.intercommerce.com.ec/apitopwispticketsoportetecnico/", requestOptions)
      fetch(
        "https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/apiCRM/createTicketSoporteTecnico2",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const data1 = JSON.parse(result);
          console.log(data1);
          if (data1.respuesta === 'EL CLIENTE YA TIENE UN TICKET ACTIVO') {
            Swal.fire({
              icon: "warning",
              text: "EL CLIENTE YA TIENE UN TICKET ACTIVO",
            })
          } else {
            Swal.fire({
              icon: "success",
              text: "¡Su solicitud se registro con exito!",
            });
          }
          dispatch({
            type: "CREATE_SOPORTE_TECNICO_SUCCESS",
            payload: "",
          });
          navigate(`/`);
          //dispatch(addPointsLine('Gracias por abrir un Ticket', 100))
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      Swal.fire({
        icon: "info",
        text: `Por favor contactarse al 045010056, asistente virtual Eli o las redes sociales oficiales para una atención personalizada.`,
      });
      dispatch({
        type: "CREATE_SOPORTE_TECNICO_FAIL",
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
//
export const createCambioPlan =
  (formData, navigate) => async (dispatch, getState) => {
    try {
      dispatch({ type: "CREATE_CAMBIO_PLAN_REQUEST" });
      const {
        userAuth: { userInfo },
      } = getState();
      //
      var today = new Date();
      const date = moment(today).format("YYYY-MM-DD h:mm:ss");
      var formdata = new FormData();
      //
      formdata.append("unidad", userInfo.ubicacion);
      formdata.append("cedula", userInfo.cedula);
      formdata.append("idcliente", userInfo.id_cliente);
      formdata.append("nombres", userInfo.nombre);
      formdata.append("estadocliente", userInfo.estado);
      formdata.append("plan", userInfo.servicios.perfil);
      formdata.append("direccion", userInfo.direccion_principal);
      formdata.append("email", userInfo.correo);
      formdata.append("telefono", userInfo.telefono);
      formdata.append("fecha_ingreso", date);
      formdata.append("usuario_ingreso", "24enlinea");
      formdata.append("estadosolicitud", "0");
      formdata.append("plannuevo", formData.plan_nuevo);
      formdata.append("motivo", formData.descripciontipootro);
      formdata.append("fecha_modificacion", date);
      formdata.append("ruta_fotos", "");

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        unidad: userInfo.NUM_CONTRATO,
        cedula: userInfo.cedula,
        idcliente: userInfo.id,
        nombres: userInfo.nombre,
        estadocliente: userInfo.estado,
        plan: userInfo.servicios.perfil,
        direccion: userInfo.direccion_principal,
        email: userInfo.correo,
        idservicio: userInfo.servicios.id,
        telefono: userInfo.telefono,
        usuario_ingreso: "24enlinea",
        plannuevo: formData.plan_nuevo,
        motivo: formData.descripciontipootro,
        ruta_fotos: "",
        zapping: formData.zapping
      });

      var requestOptions = {
        method: "POST",
        body: raw,
        headers: myHeaders,
        redirect: "follow",
      };

      // fetch("https://serverbd.intercommerce.com.ec/apitopwispticketcambiodeplan/", requestOptions)
      fetch(
        "https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/apiCRM/createTicketCambioPlan",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const data1 = JSON.parse(result);
          console.log(data1);
          dispatch({
            type: "CREATE_CAMBIO_PLAN_SUCCESS",
            payload: "",
          });
          Swal.fire({
            icon: "success",
            text: "¡Su solicitud se registro con exito!",
          });
          //dispatch(addPointsLine('Gracias por abrir un Ticket', 100))
          navigate(`/`);
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      Swal.fire({
        icon: "info",
        text: `Por favor contactarse al 045010056, asistente virtual Eli o las redes sociales oficiales para una atención personalizada.`,
      });
      dispatch({
        type: "CREATE_CAMBIO_PLAN_FAIL",
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
export const createCambioDomicilio = (formData, navigate) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'CREATE_CAMBIO_DOMICILIO_REQUEST' })
    //
    const {
      userAuth: { userInfo }
    } = getState()
    //
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let datos1 = {
      "unidadnegocio": userInfo.direccion_principal,
      "cedula": userInfo.cedula,
      "idcliente": userInfo.id,
      "nombres": userInfo.nombre,
      "estadocliente": userInfo.estado,
      "plan": userInfo.servicios.perfil,
      "direccion": userInfo.direccion_principal,
      "email": userInfo.correo,
      "telefonos": userInfo.telefono,
      "usuario_ingreso": "24enlinea",
      "referencianuevadireccion": formData.descripciontipootro,
      "coordenadasnuevadireccion": formData.coordenadas
    }
    console.log(datos1, ' datos 1')
    var raw = JSON.stringify({
      "unidadnegocio": userInfo.direccion_principal,
      "cedula": userInfo.cedula,
      "idcliente": userInfo.id,
      "nombres": userInfo.nombre,
      "estadocliente": userInfo.estado,
      "plan": userInfo.servicios.perfil,
      "direccion": userInfo.direccion_principal,
      "email": userInfo.correo,
      "telefonos": userInfo.telefono,
      "idservicio": userInfo.servicios.id,
      "usuario_ingreso": "24enlinea",
      "referencianuevadireccion": formData.descripciontipootro,
      "coordenadasnuevadireccion": formData.coordenadas
    });
    //
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/apiCRM/createTicketCambioDomicilio", requestOptions)
      .then(response => response.text())
      .then(result => {
        const data1 = JSON.parse(result)
        console.log(data1, 'cambio')
        //dispatch(addPointsLine('Gracias por abrir un Ticket', 100))
        dispatch({
          type: 'CREATE_CAMBIO_DOMICILIO_SUCCESS',
          payload: '',
        })
        Swal.fire({ icon: 'success', text: '¡Su solicitud se registro con exito!', })
        navigate(`/`)
        //dispatch(addPointsLine('Gracias por abrir un Ticket', 100))
      })
      .catch(error => console.log('error', error));
  } catch (error) {
    Swal.fire({ icon: 'info', text: `Por favor contactarse al 045010056, asistente virtual Eli o las redes sociales oficiales para una atención personalizada.` })
    dispatch({
      type: 'CREATE_CAMBIO_DOMICILIO_FAIL',
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    })
  }
}
export const createSuspension = (formData, navigate) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'CREATE_SUSPENSION_REQUEST' })
    //
    const {
      userAuth: { userInfo }
    } = getState()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "unidadnegocio": "",
      "cedula": userInfo.cedula,
      "idcliente": userInfo.id,
      "nombres": userInfo.nombre,
      "estadocliente": userInfo.estado,
      "plan": userInfo.servicios.perfil,
      "direccion": userInfo.direccion_principal,
      "email": userInfo.correo,
      "telefono": userInfo.telefono,
      "idservicio": userInfo.servicios.id,
      "usuario_ingreso": "24enlinea",
      "cerrado": "SI",
      "tipo": "problema",
      "motivo_suspension": formData.descripciontipootro
    });

    var requestOptions = {
      method: 'POST',
      body: raw,
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/apiCRM/createTicketSuspension", requestOptions)
      .then(response => response.text())
      .then(result => {
        const data1 = JSON.parse(result)
        console.log('Hola')
        //dispatch(addPointsLine('Gracias por abrir un Ticket', 100))
        console.log(data1, 'cambio')
        dispatch({
          type: 'CREATE_SUSPENSION_SUCCESS',
          payload: '',
        })
        Swal.fire({ icon: 'success', text: '¡Esto no es una confirmación de suspensión. Debe esperar la llamada de un asesor para confirmar su requerimiento.!', })
        navigate(`/`)
      })
      .catch(error => console.log('error', error));
  } catch (error) {
    Swal.fire({ icon: 'info', text: `Por favor contactarse al 045010056, asistente virtual Eli o las redes sociales oficiales para una atención personalizada.` })
    dispatch({
      type: 'CREATE_SUSPENSION_FAIL',
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    })
  }
}
//
export const createMigracion = (formData, navigate) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'CREATE_MIGRACION_REQUEST' })
    const {
      userAuth: { userInfo }
    } = getState()
    //
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "unidadnegocio": userInfo.ubicacion,
      "cedula": userInfo.cedula,
      "idcliente": userInfo.id,
      "nombres": userInfo.nombre,
      "estadocliente": userInfo.estado,
      "plan": userInfo.servicios.perfil,
      "direccion": userInfo.direccion_principal,
      "email": userInfo.email,
      "contacto": userInfo.telefono,
      "usuario_ingreso": "24enlinea",
      "idservicio": userInfo.servicios.id,
      "coordenadas": formData.coordenadas,
      "referencianuevadireccion": formData.direccion,
      "nuevo_servicio": formData.servicio
    });


    var requestOptions = {
      method: 'POST',
      body: raw,
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/apiCRM/createTicketMigracion", requestOptions)
      .then(response => response.text())
      .then(result => {
        Swal.fire({ icon: 'success', text: 'Se envio la informacion de Migracion', })
        navigate(`/`)
        //dispatch(addPointsLine('Gracias por abrir un Ticket', 100))
        console.log(result)
      })
      .catch(error => console.log('error', error));


  } catch (error) {
    Swal.fire({ icon: 'info', text: `Por favor contactarse al 045010056, asistente virtual Eli o las redes sociales oficiales para una atención personalizada.` })
    dispatch({
      type: 'CREATE_MIGRACION_FAIL',
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    })
  }
}
