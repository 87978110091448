
import { auth, db, firebase } from '../../db/firebase';
import Swal from 'sweetalert2'
export const clientEmails = (cedula) => async (dispatch) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
            "cedula": cedula
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/GetClientsDetails", requestOptions)
            .then(response => response.text())
            .then(result => {
                const dataJSON = JSON.parse(result)
                console.log(dataJSON, 'resultado')
                if (dataJSON.estado === 'error') {
                    Swal.fire({
                        text: dataJSON.mensaje,
                        showConfirmButton: false
                    })
                    //console.log('Erro', )
                } else {
                    //Obtenemos los correos de ese cliente
                    const contracts = dataJSON.datos
                    const data = [...contracts.map(doc => {
                        return {
                            'correo': doc.correo,
                        }
                    })]
                    dispatch({
                        type: 'EMAIL_VERIFICATION_SUCCESS',
                        payload: data,
                    })
                }
            })
            .catch(error => console.log('error', error));
    } catch (error) {
        dispatch({
            type: 'EMAIL_VERIFICATION_FAIL',
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const signup = (formData, navigate) => async (dispatch) => {
    try {
        console.log(formData, ' FORM DATA')
        const userCredential = await auth.createUserWithEmailAndPassword(formData.email, formData.password);
        const user = userCredential.user;
        console.log('userCredential:', userCredential);
        const currentUser = firebase.auth().currentUser;
        await currentUser.updateProfile({
            displayName: formData.cedula,
        });
        navigate("/accounts/creating-account/");
        await user.sendEmailVerification();
        //puntos
        let puntos = 50;
        //obtenemos los servicios
        let cedula = formData.datos_clientes[0].cedula
        const no_retirado = formData.datos_clientes.filter(item => item.estado !== 'RETIRADO')
        const serviciosUnicosArray = no_retirado.flatMap((usuario) => usuario.servicios)
            .filter((servicio, index, servicios) => {
                return servicios.findIndex((s) => s.id === servicio.id) === index;
            });
     
        //obtenemos su plan y le damos sus puntos
        serviciosUnicosArray.map((servicio) => {
            if(servicio.costo >= 15.68 && servicio.costo <= 23.40){
                puntos *=0.5
            }
            if(servicio.costo >= 23.40 && servicio.costo <= 26.89){
                puntos *=1
            }
            if(servicio.costo >= 26.90 && servicio.costo <= 31.89){
                puntos *=2
            }
            if(servicio.costo >= 31.90 && servicio.costo <= 49.89){
                puntos *=3
            }
            if(servicio.costo >= 49.90){
                puntos *=4
            }
            //nuevoServicio.puntos = puntos
            console.log('puntos', puntos)
            console.log('cedula', cedula, 'servicio', servicio.id)
            //incrementamos puntos
            const increment = firebase.firestore.FieldValue.increment(puntos)
            db.collection(`users/${cedula}/yigapuntos/`).doc(`${servicio.id}`).set({
                idservicio: servicio.id,
                idperfil: servicio.idperfil,
                perfil: servicio.perfil,
                instalado: servicio.instalado,
                puntosBase: 100,
                puntos: increment,
                created: firebase.firestore.FieldValue.serverTimestamp()
            })
            //planes de puntos
            db.collection(`users/${cedula}/yigapuntos/${servicio.id}/historico`).add({
                idservicio: servicio.id,
                idperfil: servicio.idperfil,
                perfil: servicio.perfil,
                instalado: servicio.instalado,
                puntosBase: 100,
                puntos: increment,
                // descripcion: 'Test',
                descripcion: 'Creacion de cuenta en 24EnLinea',
                created: firebase.firestore.FieldValue.serverTimestamp()
            });

        });


        await db.collection('users').doc(formData.cedula).set({
            uid: formData.cedula,
            email_index: formData.email_index,
            picture: '',
            dolares: 1,
            token: '',
            value: 0,
            password: formData.password,
            email: formData.email,
            created: firebase.firestore.FieldValue.serverTimestamp()
        });

        await auth.signOut();
        Swal.fire({ icon: 'success', text: 'Su cuenta ha sido creada exitosamente, entra en tu correo para verificar tu cuenta.', showConfirmButton: false });
    } catch (error) {
        console.log(error);
        let errorMessage = error.message;
        if (error.code === 'auth/email-already-in-use') {
            errorMessage = 'La dirección de correo electrónico ya está en uso por otra cuenta.';
        }
        if (error.code === 'auth/invalid-email') {
            errorMessage = 'La dirección de correo electrónico no es válida, contacte con soporte Telf.: 097 937 0118.';
        }
        Swal.fire({
            icon: 'error',
            text: errorMessage,
            showConfirmButton: false
        });
    }
}