import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/img/logo_violeta.png'
import { Alert, Typography, Radio, RadioGroup, FormControlLabel, FormLabel, FormControl, Box, Button, Grid, Link, Card, CardContent, DialogActions } from '@mui/material';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import InputValidation2 from '../../components/utils/InputValidation2';
import { signup } from '../../redux/actions/authActions';
export const SignupPage = () => {
    let navigate = useNavigate()
    const [stepper, setStepper] = useState(1)
    const [cedula, setCedula] = useState({ campo: '', valido: true })
    //
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState({ campo: '', valido: null })
    const [confirmPassword, setConfirmPassword] = useState({ campo: '', valido: null })
    //Datos Obtenidos 
    const [datosClientes, setDatosClientes] = useState([])
    const [emailIndex, setValue] = useState('');
    const userSignup = useSelector(state => state.userSignup)
    const { error: errorCreate } = userSignup
    const dispatch = useDispatch()

    const handleChange = (event) => {
        setValue(event.target.value);
        console.log(event.target.value)
    };
    const hideemail = (user_email) => {
        var avg, splitted, part1, part2;
        splitted = user_email.split("@");
        part1 = splitted[0];
        avg = part1.length / 2;
        part1 = part1.substring(0, (part1.length - avg));
        part2 = splitted[1];
        return part1 + "***@" + part2;
    }
    const validationPassword = () => {
        if (password.campo.length > 0) {
            if (password.campo !== confirmPassword.campo) {
                setConfirmPassword((prevState) => {
                    return { ...prevState, valido: false }
                });
            } else {
                setConfirmPassword((prevState) => {
                    return { ...prevState, valido: true }
                });
            }
        }
    }
    let theme = createTheme({
        palette: {

            background: {
                default: '#1d0226',
                paper: '#2D033B',
            },
            primary: {
                main: '#7635DC'
            },
            secondary: {
                main: '#6d1b7b',
            },

            //},
            // typography: {
            //     button: {
            //     textTransform: "none"
            //}
        },
        components: {
            // Name of the component

            MuiButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        borderRadius: '1rem',
                        textTransform: 'none',
                        fontSize: '1rem',
                        boxShadow: '0 8px 20px -12px rgba(0,0,0,0.5)',
                    },
                },
            },

            MuiCard: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        boxShadow: '0 8px 20px -12px rgba(0,0,0,0.3)',
                        borderRadius: '1rem',
                    },
                },
            },
        },
    })
    const getClientsDetails = async (cedula) => {
        try {
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
                "cedula": cedula
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(`${process.env.REACT_APP_API_24}/GetClientsDetails`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const dataJSON = JSON.parse(result)
                    console.log(dataJSON, 'resultado')
                    if (dataJSON.estado === 'error') {
                        Swal.fire({
                            icon: 'warning',
                            text: dataJSON.mensaje,
                            showConfirmButton: false
                        })
                    }
                    if (dataJSON.estado === 'exito') {
                        const contracts = dataJSON.datos
                        // const data = [...contracts.map(doc => {
                        //     return {
                        //         'correo': doc.correo,
                        //     }
                        // })]
                        setStepper(2)
                        setDatosClientes(contracts)
                    }
                    setLoading(false)
                })
                .catch(error => console.log('error', error));
        } catch (error) {
            setLoading(false)
            dispatch({
                type: 'EMAIL_VERIFICATION_FAIL',
                payload: error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
            })
        }
    }
    const submitHandler = () => {
        let email = datosClientes[emailIndex].correo
        //let plan = datosClientes[emailIndex].servicios[emailIndex].perfil
        dispatch(signup({
            'cedula': cedula.campo,
            'password': password.campo,
            'email': email,
            'datos_clientes': datosClientes,
            'email_index': emailIndex
        }, navigate))
    }
    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid
                    container
                    component="main"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        height: '100vh'
                    }}
                >
                    <Grid item xs={12} sm={8} md={8} lg={4} elevation={5}>
                        <Box style={{ textAlign: "center" }}>
                            <img width={200} src={logo} alt='' />
                        </Box>
                        <Card style={{ backgroundColor: '#ffff' }}>
                            <CardContent>
                                {stepper === 1 ?
                                    <Box>
                                        <Typography align='center' variant='h4' component='h1' gutterBottom>
                                            <strong>Crear una Cuenta</strong>
                                        </Typography>
                                        <InputValidation2
                                            estado={cedula}
                                            cambiarEstado={setCedula}
                                            type="number"
                                            label="Numero de Cedula o Ruc"
                                            helperText="Sólo correos asociados a la institución '@ups.edu.ec'"
                                        //patterns={/^[a-zA-Z0-9_.+-]+@+(est\.)?(ups.edu.ec)/}
                                        />
                                        <Box pb={1}>
                                            <InputValidation2
                                                estado={password}
                                                cambiarEstado={setPassword}
                                                type="password"
                                                label="Contraseña"

                                                helperText="Minimo 8 caracteres."
                                                patterns={/^.{8,30}$/}
                                            />
                                        </Box>
                                        <Box >
                                            <InputValidation2
                                                estado={confirmPassword}
                                                cambiarEstado={setConfirmPassword}
                                                type="password"
                                                label="Confirmar contraseña"

                                                helperText="Las contraseñas no coinciden."
                                                funcion={validationPassword}
                                            />
                                        </Box>
                                        <Typography gutterBottom>
                                            Al hacer click en "Crear cuenta", indicas que has leído y aceptas las
                                            <Link component={RouterLink} to={'/policy_personal/'}> Política de tratamiento de datos personales</Link>
                                            .
                                        </Typography>
                                        <DialogActions>
                                            <Button onClick={() => navigate('/')}>Atras</Button>
                                            <Button
                                                variant="contained"
                                                style={{ textTransform: 'capitalize' }}
                                                color="primary"
                                                disabled={loading}
                                                onClick={() => getClientsDetails(cedula.campo)}
                                            >Siguiente</Button>
                                        </DialogActions>
                                        <Box display="flex" flexDirection="row-reverse">

                                        </Box>
                                        <Link component={RouterLink} to={'/accounts/login/'}>¿Ya tienes una cuenta?</Link>
                                    </Box>
                                    :
                                    <>
                                        <Box >
                                            {errorCreate &&
                                                <Box py={4}>
                                                    <Alert severity="error">
                                                    </Alert>
                                                </Box>}
                                            <Box pt={2}>
                                                <Typography>Seleccione uno de los correos electronicos que tenga acceso para enviar su activacion de cuenta.</Typography>
                                            </Box>
                                            <Box py={4}>
                                                <FormControl component="fieldset">
                                                    <FormLabel id="demo-radio-buttons-group-label" component="legend">Correo electrónico</FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="female"
                                                        name="radio-buttons-group"
                                                        aria-label="gender"
                                                        value={emailIndex}
                                                        onChange={handleChange}
                                                    >
                                                        {datosClientes?.map((item, index) => (
                                                            <FormControlLabel key={index} value={`${index}`} control={<Radio />} label={hideemail(item.correo)} />
                                                        ))}
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                            <Box py={2}>
                                                <Typography variant="caption" display="block" color="initial">Hemos ocultado su correo electronico con * (ateristicos) para proteger sus datos.</Typography>
                                                <Typography variant="caption" display="block" color="initial">Si no tienes acceso a los correos mostrados, contacte con soporte Telf.: 093 923 8655</Typography>
                                            </Box>
                                            <DialogActions>
                                                <Button onClick={() => setStepper(1)} color='info'>Atras</Button>
                                                <Button color="primary" onClick={() => submitHandler()} variant="contained">Enviar codigo</Button>
                                            </DialogActions>
                                        </Box>
                                    </>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </ThemeProvider >
        </>
    )
}

