import { useEffect, useState } from 'react';
import {
    Container, Box, CardContent, Card, Typography, Grid, Stack, Dialog, DialogContent, DialogActions, Button
} from '@mui/material/';
import { db, firebase } from '../../db/firebase'
import fondo_yigacoins from '../../assets/img/yigacoins/fondo_yigacoins.png'
import tesoro_yigacoin from '../../assets/img/yigacoins/icon1.jpg'
import popup_yigacoins from '../../assets/img/yigacoins/popup_yigacoins.png'
import yigacoins_monedas from '../../assets/img/yigacoins/icon2.jpg'
import cofre_monedas from '../../assets/img/yigacoins/icon3.jpg'
import Circle from 'react-circle';
import yigacoins_slider from '../../assets/img/yigacoins/coinsSaldoFondo01.png'
import yigacoins_eli from '../../assets/img/yigacoins/eli_yigacoins.png'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
export const CoinsPage = () => {
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [data, setData] = useState([]);
    const [plan, setPlan] = useState({
        puntos: 0
    });
    const [open, setOpen] = useState(false);
    useEffect(() => {
        in_yigacoins()
    }, [userInfo?.index_linea]);
    const fetchData = async () => {

        const querySnapshot = await db.collection(`users/${userInfo.uid}/yigapuntos/${userInfo.servicios.id}/historico`).get()
        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        setData(data)
        await db.collection(`users/${userInfo.uid}/yigapuntos/`).doc(`${userInfo.servicios.id}`).onSnapshot((doc) => {
            let data = doc.data()
            console.log(data, 'datas')
            setPlan(data)
        })
    }
    const in_yigacoins = async () => {
        const querySnapshot = await db.collection(`users/${userInfo.uid}/yigapuntos/`).get();
        const documents = querySnapshot.docs.map(doc => doc.data());
        if (documents.length <= 0) {
            console.log(documents, 'no hay documentos')
            setOpen(true)
        } else {
            setOpen(false)
            fetchData()
        }
        setLoading(false)
    }
    const css_fondo = {
        height: '400px',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 20,
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundImage: `url('${fondo_yigacoins}')`
    }
    const css_cuadro1 = {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: 20,
        display: 'flex',
        position: 'relative',
        background: 'linear-gradient(to top, #6c6630, black)'
    }
    const handleCoins = async () => {
        let puntos = 50
        if (userInfo.servicios.costo >= 15.68 && userInfo.servicios.costo <= 23.40) {
            puntos *= 0.5
        }
        if (userInfo.servicios.costo >= 23.40 && userInfo.servicios.costo <= 26.89) {
            puntos *= 1
        }
        if (userInfo.servicios.costo >= 26.90 && userInfo.servicios.costo <= 31.89) {
            puntos *= 2
        }
        if (userInfo.servicios.costo >= 31.90 && userInfo.servicios.costo <= 49.89) {
            puntos *= 3
        }
        if (userInfo.servicios.costo >= 49.90) {
            puntos *= 4
        }
        //nuevoServicio.puntos = puntos
        console.log('puntos', puntos)
        console.log('cedula', userInfo, 'servicio', userInfo.servicios.id)
        //incrementamos puntos
        const increment = firebase.firestore.FieldValue.increment(puntos)
        await db.collection(`users/${userInfo.cedula}/yigapuntos/`).doc(`${userInfo.servicios.id}`).set({
            idservicio: userInfo.servicios.id,
            idperfil: userInfo.servicios.idperfil,
            perfil: userInfo.servicios.perfil,
            instalado: userInfo.servicios.instalado,
            puntosBase: 100,
            puntos: increment,
            created: firebase.firestore.FieldValue.serverTimestamp()
        })
        //planes de puntos
        await db.collection(`users/${userInfo.cedula}/yigapuntos/${userInfo.servicios.id}/historico`).add({
            idservicio: userInfo.servicios.id,
            idperfil: userInfo.servicios.idperfil,
            perfil: userInfo.servicios.perfil,
            instalado: userInfo.servicios.instalado,
            puntosBase: 100,
            puntos: increment,
            descripcion: 'Test',
            created: firebase.firestore.FieldValue.serverTimestamp()
        });
        setOpen(false)
        window.location.reload()
    }
    return (
        <>
            {
                loading === false ?
                    <>
                        {
                            open && <div style={{ zIndex: 9999, background: 'rgba(0, 0, 0, 0.5)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div
                                    style={{ position: 'relative', maxWidth: '80%', maxHeight: '80%', backgroundColor: '#fff', overflow: 'hidden', borderRadius: '8px' }}
                                >
                                    <img style={{ objectFit: 'cover' }} width='100%' src={popup_yigacoins} alt='' />
                                    <div style={{ bottom: '20px', left: '50%', transform: 'translateX(-50%)', position: 'absolute' }}>
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={5}
                                        >
                                            <Button onClick={() => handleCoins()} color='warning' variant='contained' size='large'>
                                                <Box py={1}>
                                                    <Typography variant='h5'>
                                                        <strong>SÍ ACEPTO</strong>
                                                    </Typography>
                                                </Box>
                                            </Button>
                                            <Button onClick={() => navigate('/')} color='warning' variant='contained' size='large'>
                                                <Box py={1}>
                                                    <Typography variant='h5'>
                                                        <strong>NO GRACIAS</strong>
                                                    </Typography>
                                                </Box>
                                            </Button>
                                        </Stack>
                                    </div>

                                </div>
                            </div>
                        }

                        <div style={css_fondo}>
                            <Box pb={5}>
                                <Typography gutterBottom color='white' align='center' variant='h2'>
                                    <strong>¿Qué son los YigaCoins?</strong>
                                </Typography></Box>
                        </div>
                        <Box pb={5}></Box>
                        <Box py={5}>
                            <Container maxWidth='sm'>
                                <Typography gutterBottom variant='h4' color='GrayText' align='center'>Yigacoins es un programa de recompensas para nuestros clientes, a traves
                                    de una moneda digital que puede canjearse por beneficios y premios. </Typography>
                            </Container>
                        </Box>
                        <Box py={5}>
                            <div style={css_cuadro1}>
                                <Container maxWidth='md'>
                                    <Box py={5}></Box>
                                    <Grid container spacing={0}>
                                        <Grid item md={6}>
                                            <Typography gutterBottom color='white' align='center' variant='h4'>
                                                <strong>Yiga Coins a la fecha</strong>
                                            </Typography>
                                            <Box py={2}></Box>
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={0}
                                            >
                                                <div style={{ position: 'relative' }}>
                                                    <img src={yigacoins_slider} alt='' width='100%' style={{ zIndex: 999, position: 'absolute' }} />
                                                    <div style={{ padding: 40, rotate: '360deg' }}>
                                                        <Circle
                                                            responsive={true}
                                                            progress={plan.puntos / 100}
                                                            progressColor="#FFDC00"
                                                            bgColor='#B37F04'
                                                            textColor='transparent'
                                                            lineWidth={30}
                                                            roundedStroke={true}
                                                        />
                                                    </div>
                                                </div>
                                            </Stack>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Card style={{ backgroundColor: '#FFDC00' }}>
                                                <CardContent>
                                                    <Typography variant='h5' align='center'>
                                                        <strong>Tienes acumulado</strong>
                                                    </Typography>
                                                    <Typography align='center' variant='h3'>
                                                        <strong>{plan.puntos} YC</strong></Typography>
                                                </CardContent>
                                            </Card>
                                            <img src={yigacoins_eli} width='100%' alt='' />
                                        </Grid>
                                    </Grid>
                                </Container>
                            </div>
                        </Box>
                        <Box py={5}>
                            <Container maxWidth='md'>
                                <Grid container spacing={5}>
                                    <Grid item md={4} >
                                        <div id="arrow">
                                            <img style={{ borderRadius: 20 }} src={yigacoins_monedas} onClick={() => navigate('/coins/desafios')} alt='' width='100%' />
                                            <Typography align='center' variant='h4'>
                                                <strong>Acumula</strong>
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item md={4} >
                                        <div id="arrow">
                                            <img style={{ borderRadius: 20 }} src={tesoro_yigacoin} onClick={() => navigate('/store/productos')} alt='' width='100%' />
                                            <Typography align='center' variant='h4'>
                                                <strong>Premios</strong>
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item md={4} >
                                        <div id="arrow">
                                            <img style={{ borderRadius: 20 }} src={cofre_monedas} alt='' width='100%' onClick={() => navigate('/coins/historico')} />
                                            <Typography align='center' variant='h4'>
                                                <strong>Histórico</strong>
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                        <Stack justifyContent="center"
                            alignItems="center">
                            <Button onClick={() => navigate('/policy')} variant='contained'>Terminos y Condiciones</Button>
                        </Stack>
                    </>
                    : 'Cargando...'
            }
        </>
    )
}