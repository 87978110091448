import React, { useState, useEffect } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Box, Typography, Paper, Table, TableBody, TableCell, TableRow, Button, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Divider, TableContainer } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AñadirCamara from './AñadirCamara';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridExpandMoreIcon,
} from '@mui/x-data-grid';
export const Camaras = () => {
  const userAuth = useSelector(state => state.userAuth);
  const { userInfo } = userAuth;
  const [cameras, setCameras] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectionModel, setSelectionModel] = useState([]);
  const [open, setOpen] = useState(false);
  const [cameraToEdit, setCameraToEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCameraIndex, setSelectedCameraIndex] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [urlCamaras, setUrlCamaras] = useState('');


  useEffect(() => {
    if (userInfo) {
      const randomParam = Math.random().toString(36).substring(7);
      setUrlCamaras(`https://dataguardian.intercommerce.com.ec/zm/v1/camaras/?id_mikro=0${userInfo.id}&random=${randomParam}`);
    }
  }, [userInfo]);

  useEffect(() => {
    if (urlCamaras) {
      cargarCamaras();
    }
  }, [urlCamaras]);

  const cargarCamaras = () => {
    setLoading(true);
    const randomParam = Math.random().toString(36).substring(7);
    fetch(urlCamaras)
      .then((response) => response.json())
      .then((data) => {
        setCameras(data.map((camera) => ({
          id: camera.Id,
          name: camera.Name,
          function: camera.Function,
          origen: "192.168.18.252",
          Eventos: "null",
          Hora: "null",
          Día: "null",
          Semana: "null",
          Mes: "null",
          Archivado: "null",
          urlStream: camera.urlStream,
          conkey: Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000,
        })));
        setLoading(false);
        console.log(data)
      })
      .catch((error) => {
        console.error('Error al obtener los datos:', error);
        setLoading(false);
      });
  };

  const getUrlStream = (row, escala, ancho, alto) => {
    const connkey = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
    return `http://192.168.5.246/zm/cgi-bin/nph-zms?scale=${escala}&width=${ancho}&height=${alto}&mode=jpeg&maxfps=60&buffer=2000&monitor=${row?.id}&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJab25lTWluZGVyIiwiaWF0IjoxNzEwNzk2NTEzLCJleHAiOjMzMjQ2Nzk2NTEzLCJ1c2VyIjoiYWRtaW4iLCJ0eXBlIjoiYWNjZXNzIn0.L43bX9YaC5yfGXmhMizSgNwAJmRb26R_6dKMDEgw_S0&connkey=${row?.connkey}`;
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Nombre', width: 230 },
    { field: 'function', headerName: 'Función', width: 100 },
    { field: 'origen', headerName: 'Origen', width: 120 },
  ];

  const handleOpenEditModal = () => {
    if (selectionModel.length > 0) {
      const cameraId = selectionModel[0];
      const camera = cameras.find((camera) => camera.id === cameraId);
      setCameraToEdit(camera);
      setOpen(true);
    }
  };

  const handleDelete = () => {
    if (selectionModel.length > 0) {
      const cameraId = selectionModel[0];
      // Implementa la lógica para borrar una cámara con el id cameraId
    }
  };

  const handleOpenAddModal = () => {
    <AñadirCamara />
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenDialog = (index) => {
    setSelectedCameraIndex(index);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSelectionModelChange = (newRowSelectionModel) => {
    setSelectionModel(newRowSelectionModel);
  };

  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props} sx={{ justifyContent: 'space-between', padding: '0 16px', alignItems: 'center' }}>
      {/* Izquierda: Filtro de búsqueda */}
      <Box>
        <GridToolbarQuickFilter quickfiltertext={searchText} />
      </Box>
      {/* Derecha: Botones de acción */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title="Añadir nuevo monitor">
          <span>
            <AñadirCamara onClick={handleOpenAddModal} size="large" />
          </span>
        </Tooltip>
        <Tooltip title="Editar">
          <span>
            <IconButton onClick={handleOpenEditModal} disabled={selectionModel.length !== 1} size="large">
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Borrar">
          <span>
            <IconButton onClick={handleDelete} disabled={selectionModel.length < 1} size="large">
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Divider orientation="vertical" flexItem sx={{ mx: 2, my: 'auto' }} />
      </Box>
    </GridToolbarContainer>
  );

  return (
    <>
      <Accordion elevation={2} sx={{ padding: "10px", m: "1px" }}>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography sx={{ fontSize: "16px", textAlign: "left" }}>
            <strong>Datos de Cámaras</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box           sx={{
              height: 300,
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
              "& .super-app-theme--header": {
                backgroundColor: "LightSlateGray",
                color: "white",
                fontSize: "16px",
              },
            }}
          >
            <DataGrid
              rows={cameras}
              columns={columns}
              pageSize={5}
              checkboxSelection
              onRowSelectionModelChange={handleSelectionModelChange}
              selectionModel={selectionModel}
              components={{
                Toolbar: CustomToolbar,
              }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <br></br>

      <Grid container spacing={2}>
        {cameras.map((camera, index) => (
          <Grid item xs={12} md={6} key={camera.id}>
            <Paper elevation={3} onClick={() => handleOpenDialog(index)}>
              <img
                style={{ width: '100%', height: '100%' }}
                src={camera.urlStream}
                title={`Cámara ${camera.name}`}
                alt={`Cámara ${camera.name}`}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Diálogo con carrusel */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg">
        <DialogTitle>{`Cámara ${cameras[selectedCameraIndex]?.name}`}</DialogTitle>
        <DialogContent>
          <Carousel>
            {cameras.map((camera) => (
              <Paper elevation={3}>
                <iframe style={{ width:"1920px", height: "1080px" }} src={camera.urlStream} alt={`Cámara ${camera.name}`} />
              </Paper>
            ))}
          </Carousel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cerrar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Camaras;
