import {
    Typography, Container, Box, CardContent, Card, TextField, Select,
    MenuItem, Grid, FormControl, Button, Stack, DialogActions, InputLabel, Autocomplete, Toolbar
} from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listPlanes, listTipoProblema, listUbicacion } from '../../redux/actions/parametersActions';
import { createCambioDomicilio, createSuspension } from '../../redux/actions/serviciosActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from "react-router-dom";
import InputValidation from '../../components/utils/InputValidation';
import Swal from 'sweetalert2'
import { GeolocationGoogle } from '../../components/utils/GeolocationGoogle';
export const SuspensionServicioPage = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    //
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [position, setPosition] = useState({
        lat: latitude,
        lng: longitude,
    })
    //
    const [ubicacion, setUbicacion] = useState('')
    const [tipo, seTipo] = useState('')
    const [direccion, setDireccion] = useState('')
    const [plan, setPlan] = useState('')
    const [descripcion, setDescripcion] = useState({ campo: '', valido: true })
    //
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    //
    const getPlanes = useSelector(state => state.getPlanes)
    const { list_planes } = getPlanes
    //
    const createSoporteTecnico = useSelector(state => state.createSoporteTecnico)
    const { loading } = createSoporteTecnico
    //
    const ubicaciones = useSelector(state => state.ubicacion)
    const { list_ubicacion } = ubicaciones
    useEffect(() => {
        dispatch(listTipoProblema())
        dispatch(listUbicacion())
        dispatch(listPlanes())
    }, [])
    const submitHandler = (e) => {
        e.preventDefault()
        Swal.fire({
            text: "¿Esta seguro de enviar?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Acepto'
        }).then((result) => {
            if (result.isConfirmed) {
               
                dispatch(createSuspension({
               
                
                    descripciontipootro: descripcion.campo
             

                }, navigate))
            }
        })
    }
    //
    const inputs = [
        {
            label: 'NOMBRE ',
            value: userInfo.nombre
        },
        {
            label: 'CEDULA',
            value: userInfo.cedula
        },
        {
            label: 'ESTADO',
            value: userInfo.estado
        },
        {
            label: 'LINEA DE CONTRATO',
            value: userInfo.NUM_CONTRATO
        },
        {
            label: 'DIRRECION',
            value: userInfo.direccion_principal
        },
        {
            label: 'CORREO',
            value: userInfo.correo
        },
    ]
    return (

            <form onSubmit={submitHandler}>
                            <Box pb={2}>
                <Typography
                    variant='h5'
                    color='textPrimary'
                    component='h1'
                    style={{ textTransform: 'capitalize' }}
                ><strong>Suspensio de Servicio</strong></Typography>
            </Box>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            {inputs.map((item, index) => (
                                <Grid key={index} item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={item.label}
                                        disabled
                                        value={item.value}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Box py={2}>
                            
                        </Box>
                        <Stack spacing={2}>
                            

                            <InputValidation
                                estado={descripcion.campo}
                                cambiarEstado={setDescripcion}
                                multiline={true}
                                label="RENUNCIA O RETIRO"
                                name="motivo"
                                rows={4}
                                maxRows={4}
                                disabled={loading}
                            />
                        </Stack>
                    </CardContent>
                </Card>
                <Box py={2}>
                    <DialogActions>
                        <LoadingButton
                            size='large'
                            loading={loading}
                            disabled={loading || !descripcion.campo }
                            type="submit"
                            variant="contained"
                        >
                            Enviar información
                        </LoadingButton>
                    </DialogActions>
                </Box>
            </form>

    )
}
