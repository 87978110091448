import React from 'react'
import { AppBar, Box, ListItemText, Typography, ListItemIcon, ListItem, Avatar } from '@mui/material/';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
export const MenuAccount = ({ props }) => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    return (
        <ListItem  style={{ borderRadius: 12, margin: "0px 0px 4px" }} component={RouterLink} to="/accounts/profile/" button>
            <ListItemIcon>
                <Avatar src={userInfo.picture ? userInfo.picture : ''}></Avatar>
            </ListItemIcon>
            <ListItemText primary={
                <div style={{ width: 175, whiteSpace: 'nowrap' }}>
                    <Box component="div"
                        sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                    >
                        <Typography variant="body2">{userInfo && userInfo.nombre}</Typography>
                        <Typography  variant="body2">Cuenta</Typography>
                    </Box>
                </div>
            } />
        </ListItem>
    )
}
