import React, { useState, useEffect } from 'react'
import {
  Typography, Container, DialogContent,
 Card, CardContent
} from "@mui/material";
import { db, firebase, functions } from '../../db/firebase'
import Swal from 'sweetalert2'
import InputValidation from '../../components/utils/InputValidation';
import fondo from "../../assets/img/actualiza_datosmama.jpeg"
import { Link } from "react-router-dom";
export const ActualizarDatosPageTerms = () => {
    return (
        <>
            <Container>
              <Card>
                <CardContent>
                <Typography align='center'><strong>TÉRMINOS Y CONDICIONES PROMOCIONES MAYO “YO QUIERO A MI MAMÁ TECNOLÓGICA” DE YIGA5®</strong></Typography>
                        <Typography><strong>PARTICIPANTES</strong></Typography>
                        <Typography>Participan en el sorteo de premios todos los clientes Yiga5®: nuevos clientes que adquieran planes Yiga5® y clientes antiguos que actualicen sus datos.</Typography>
                        <Typography><strong>VIGENCIA</strong></Typography>
                        <Typography>1 al 31 de Mayo de 2023.</Typography>
                        <Typography><strong>COBERTURA / TERRITORIO</strong></Typography>
                        <Typography>Clientes de provincias de Guayas y Santa Elena donde exista servicio de internet de Yiga5®.</Typography>
                        <Typography><strong>DESCRIPCIÓN DE PREMIOS</strong></Typography>
                        <Typography>Clientes nuevos entran a sorteo de 3 laptops, 5 celulares y 5 tablets, un total de 13 premios. Clientes antiguos que actualicen datos entran a sorteo de 1 celular y 1 tablet, un total de 2 premios. El total a sortear de promociones de mayo es de 15 premios.</Typography>
                        <Typography><strong>MECÁNICA DE PROMOCIONES</strong></Typography>
                        <Typography>Entran al sorteo de premios clientes nuevos que adquieran un plan (Power Defender Plus+, Max Defender Plus+, Tendencia Defender Plus+, Influencer Defender Plus+, Épico Defender Plus+, Legendario Defender Plus+).
                            Clientes antiguos que tengan activo cualquier plan de Yiga5®, deben actualizar sus datos en la página <a href='https://24enlinea.yiga5.com/#/actualizar_datos'>https://24enlinea.yiga5.com/#/actualizar_datos</a> y completar el formulario.
                        </Typography>
                        <Typography><strong>PUBLICIDAD Y COMUNICACIÓN</strong></Typography>
                        <Typography>Yiga5® comunicará a clientes antiguos a través de medios digitales sobre actualización de datos. Para clientes nuevos la empresa contacta por sus medios: fuerza de venta, Distribuidores, canales digitales, redes sociales, call center, puntos de atención Matriz, Isla en CC Mall El Fortín. Participan los clientes que hayan sido contactados y hayan adquirido un plan de internet Yiga5® a través de cualquiera de sus canales.
                        </Typography>
                        <Typography><strong>SORTEO DE PREMIOS</strong></Typography>
                        <Typography>El sorteo se realizará a través de Facebook LIVE de la página oficial de Yiga5® https://Facebook.com/Yiga5.ec el 5 de junio de 2023.
                        </Typography>
                        <Typography><strong>EXCLUSIONES DE PARTICIPANTES</strong></Typography>
                        <Typography>No participan colaboradores de la empresa Yiga5® o sus distribuidores.
                            No participan clientes con servicio suspendido.
                            No participan clientes que no hayan completado los datos en a página de actualización.
                            No participan quienes no hayan completado la instalación y no cuenten con el servicio de internet Yiga5®.
                        </Typography>
                        <Typography><strong>AUTORIZACIÓN DE DATOS PERSONALES</strong></Typography>
                        <Typography>El cliente autoriza a Yiga® poder contactarse mediante los números celulares o correo electrónico registrados en su contratación o página de actualización de datos, para envío de información de premios, promociones o comunicaciones que la empresa requiera hacia sus usuarios de servicio; así como declara que los datos registrados son correctos y pertenecen a titular de contrato de planes de servicio de internet de Yiga5®.
                        </Typography>
                        <Typography><strong>ENTREGA DE PREMIOS</strong></Typography>
                        <Typography>Yiga5® se contactará con ganadores vía celular, según el teléfono registrado por el cliente. El lapso de retiro del premio será acorde a la ubicación de los ganadores, en oficina matriz o puntos de distribuidor si así lo acuerdan ambas partes; pero no podrá superar 1 mes posterior al sorteo, es decir, entre el 6 de junio al 5 de julio de 2023. En caso que el cliente no retire el premio en el lapso y punto acordado, Yiga5® puede hacer uso del premio para otro sorteo o promoción bajo la mecánica que estipule conveniente.
                        </Typography>
                        <img src={fondo} width='10%' />
                        </CardContent>
              </Card>
            </Container>
     
        </>
    )
}