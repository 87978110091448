import React, { useState, useEffect } from 'react'
import { db } from '../../db/firebase'
import { Card, Box, Typography } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { useDispatch, useSelector } from "react-redux";
import { listPromociones } from '../../redux/actions/parametersActions';
export const Promociones = () => {
    const { success, list_promociones } = useSelector(state => state.promocionesList)
    return (
        <>
            <Box pb={3} pt={5} pl={2}>
                <Typography variant="h5"><strong>Promociones</strong> </Typography>
            </Box>
            <Carousel
                autoPlay
                showStatus={false}
                showArrows={false}
                showIndicators={false}
                showThumbs={false}
                dynamicHeight={true}
            >
                {
                    list_promociones.map((item, index) => (
                

                            <Card key={Math.random()}>
                                <img  src={item.photo} />
                            </Card>
                   
                    ))}
            </Carousel>
        </>
    )
}
