import {
    Typography, Box, CardContent, Card, TextField,
    Grid, Stack, DialogActions, Autocomplete
} from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listTipoProblema, listUbicacion } from '../../redux/actions/parametersActions';
import { createSoporTecnico } from '../../redux/actions/serviciosActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from "react-router-dom";
import InputValidation from '../../components/utils/InputValidation';
import Swal from 'sweetalert2'
export const SoporteTecnicoPage = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    //
    const [tipo, seTipo] = useState('')
    const [telefono, setTelefono] = useState({ campo: '', valido: true })
    const [descripcion, setDescripcion] = useState({ campo: '', valido: true })
    //
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    //
    const tipoProblema = useSelector(state => state.tipoProblema)
    const { parameters } = tipoProblema
    //
    const createSoporteTecnico = useSelector(state => state.createSoporteTecnico)
    const { loading } = createSoporteTecnico
    //
    const ubicaciones = useSelector(state => state.ubicacion)
    const { list_ubicacion } = ubicaciones
    useEffect(() => {
        dispatch(listTipoProblema())
        dispatch(listUbicacion())
    }, [])
    const submitHandler = (e) => {
        e.preventDefault()
        Swal.fire({
            //title: '¿Esta seguro de enviar?',
            text: "¿Esta seguro de enviar?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Acceptar'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(createSoporTecnico({
                    id: '',
                    telefono: telefono.campo,
                    tipoproblema: tipo,
                    descripciontipootro: descripcion.campo,
                }, navigate))
            }
        })
    }
    const inputs = [
        {
            label: 'NOMBRE ',
            value: userInfo.nombre
        },
        {
            label: 'CEDULA',
            value: userInfo.cedula
        },
        {
            label: 'ESTADO',
            value: userInfo.estado
        },
        {
            label: 'LINEA DE CONTRATO',
            value: userInfo.NUM_CONTRATO
        },
        {
            label: 'DIRRECION',
            value: userInfo.direccion_principal
        },
        {
            label: 'CORREO',
            value: userInfo.correo
        }
    ]
    return (
        <form onSubmit={submitHandler}>
            <Box pb={2}>
                <Typography
                    variant='h5'
                    color='textPrimary'
                    component='h1'
                    style={{ textTransform: 'capitalize' }}
                ><strong>Soporte Técnico</strong></Typography>
            </Box>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        {inputs.map((item, index) => (
                            <Grid key={index} item md={6}>
                                <TextField
                                    fullWidth
                                    label={item.label}
                                    disabled
                                    value={item.value}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Box pt={3} pb={1}>
                        <InputValidation
                            estado={telefono}
                            cambiarEstado={setTelefono}
                            label="TELEFONO"
                            disabled={loading}
                            helperText="Iniciar con 09 y minimo 10 digitos"
                            patterns={/^\d{10}$/}
                        />
                    </Box>
                    <Stack spacing={2}>
                        {parameters &&
                            <Autocomplete
                                options={parameters}
                                disabled={!parameters}
                                onInputChange={(e, newInputValue) => seTipo(newInputValue)}
                                getOptionLabel={(option) => option.id}
                                renderInput={(params) =>
                                    <TextField
                                        name="problema"
                                        {...params}
                                        label="TIPO DE PROBLEMA"

                                    />}
                            />
                        }
                        <InputValidation
                            estado={descripcion}
                            cambiarEstado={setDescripcion}
                            multiline={true}
                            label="DESCRIPCION DE TIPO DE PROBLEMA"
                            name="email"
                            rows={4}
                            maxRows={4}
                            disabled={loading}
                        />
                    </Stack>
                </CardContent>
            </Card>
            <Box py={2}>
                <DialogActions>
                    <LoadingButton
                        size='large'
                        
                        disabled={!descripcion.campo || !tipo}
                        type="submit"
                        variant="contained"
                    >
                        Enviar informacion
                    </LoadingButton>
                </DialogActions>
            </Box>
        </form>
    )
}
