import { TextField, Box, Button, Tooltip, Divider, Select, AlertTitle, Alert, Dialog, DialogTitle, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import Loader from '../../components/utils/Loader'
import RoomIcon from "@mui/icons-material/Room";
export const GeolocationGoogle = ({ position, setPosition }) => {

  const [open, setOpen] = useState(false)

  useEffect(() => {
    console.log(position, ' position')
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    function success(pos) {
      var crd = pos.coords;
      setPosition({
        lat: crd.latitude,
        lng: crd.longitude,
      })
    };
    function error(err) {
      console.warn('ERROR(' + err.code + '): ' + err.message);
    };
    navigator.geolocation.getCurrentPosition(success, error, options);
  }, [])
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDEjx4Biuau-25bv8HabCJWhBeY8MKzaZc"
  })
  const containerStyle = {
    width: '100%',
    height: '500px'
  };
  const containerStyle2 = {
    width: '400px',
    height: '200px'
  };
  const onLoad = marker => {
    console.log('marker: ', marker)
  }
  const setClickedLatLng = (e) => {
    console.log('marker: ', e.lat, e.lng)

    setPosition({
      lat: e.lat,
      lng: e.lng,
    })
  }
  return (
    <>
      <Box pb={2}>
        {position.lat && <>
          {
            isLoaded && <GoogleMap
              mapContainerStyle={containerStyle2}
              center={position}
              clickableIcons={false}
              zoom={20}
              zoomOnClick={false}

            >
              <Marker
                onLoad={onLoad}
                position={position} />
            </GoogleMap>
          }
          
        </>
        }
      </Box>
      <Button variant='outlined' onClick={() => setOpen(!open)}>
        Seleccionar ubicacion del domicilio .<RoomIcon />
      </Button>
      <Dialog maxWidth="lg" fullWidth  open={open}>
        <DialogTitle>Ubicacion del domicilio</DialogTitle>
        {position.lat ? <>

          {
            isLoaded && <GoogleMap
              mapContainerStyle={containerStyle}
              center={position}
              clickableIcons={false}
              zoom={20}
              zoomOnClick={false}
              onClick={e => setClickedLatLng(e.latLng.toJSON())}
            >
              <Marker
                onLoad={onLoad}
                position={position} />
            </GoogleMap>
          }
          <Box py={2} px={1}>
            <Button variant='contained' onClick={() => setOpen(false)}>Confirmar ubicacion</Button>
          </Box>
        </> :
          <Loader />
        }
      </Dialog>
    </>
  )
}