import React, { useState } from 'react';
import {
    Box, Button, Dialog, TextField, Typography,
    MenuItem, IconButton,
    Grid, FormControl, InputLabel, Select
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

const style = {
    width: 500, // Ancho aumentado
    p: 4,
};
const AñadirCamara = () => {

    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [open, setOpen] = useState(false);
    console.log(userInfo)
    const [cameraData, setCameraData] = React.useState({
        nombre: '',
        tipoDeOrigen: 'ffmpeg',
        funcion: '',
        analysisEnabled: false,
        monitoresEnlazados: null,
        functionOptions: '',
        user: '',
        password: '',
        canal: '',
        zona: '',
        ip: '',
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(cameraData)
        const raw = {
            "Name": cameraData.nombre,
            "Protocol": "rtsp",
            "Type": "ffmpeg",
            "Function": cameraData.funcion,
            "Method": "rtpUni",
            "ZoneCount": cameraData.zona + '',
            "User": cameraData.user,
            "Passw": cameraData.password,
            "Ip": cameraData.ip,
            "Channel": cameraData.canal + '',
            "id_mikro": `0${userInfo.id}`,
        }

        try {
            const response = await fetch('https://dataguardian.intercommerce.com.ec/zm/v1/camaras/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(raw)
            });

            handleClose();
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result);
            Swal.fire({
                title: '¡Éxito!',
                text: 'La cámara ha sido añadida correctamente.',
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } catch (error) {
            console.error('Error al enviar los datos:', error);
            Swal.fire({
                title: 'Error',
                text: 'No se pudo añadir la cámara.',
                icon: 'error',
                confirmButtonText: 'Entendido'
            });
        }
    };


    // Esta función maneja los cambios de los inputs del formulario
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setCameraData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const functionOptions = ["Monitor", "Record", "Mocord"];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>
            <IconButton onClick={handleClickOpen} size="large">
                <AddIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <Box
                    sx={{
                        width: 500,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        '& .MuiFormControl-root': { m: 1 } // Aplica margen a todos los FormControl
                    }}
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off"
                >
                    <Typography variant="h6" gutterBottom component="div">
                        Añadir Cámara
                    </Typography>
                    <TextField
                        label="Nombre"
                        name="nombre"
                        value={cameraData.nombre}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        label="Ip"
                        name="ip"
                        value={cameraData.ip}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        label="User"
                        name="user"
                        value={cameraData.user}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />

                    <TextField
                        label="Password"
                        name="password"
                        value={cameraData.password}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        label="Canal"
                        name="canal"
                        type="number"
                        value={cameraData.canal}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: "1",
                            min: "0",
                        }}
                    />
                    <TextField
                        label="Zona"
                        name="zona"
                        type="number"
                        value={cameraData.zona}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: "1",
                            min: "0",
                        }}
                    />

                    <FormControl fullWidth margin="normal" variant="outlined" >
                        <InputLabel id="funcion-label">Función</InputLabel>
                        <Select
                            labelId="funcion-label"
                            id="funcion"
                            value={cameraData.funcion}
                            onChange={(e) => {
                                setCameraData({ ...cameraData, funcion: e.target.value });
                            }}
                            label="Función"
                        >
                            {functionOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box textAlign="center" sx={{ mt: 2, '& > :not(style)': { m: 1 } }}>
                        <Button type="submit" variant="contained" color="primary">
                            Guardar
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </Box>

                </Box>
            </Dialog>
        </div>
    );
};

export default AñadirCamara;
