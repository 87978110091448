import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { authReducer, userDetailsReducer, getClientReducer, notificationsListReducer } from '../redux/reducers/userReducers'
import { getPlanesReducer, promocionesReducer, tipoProblemaReducer, ubicacionReducer } from '../redux/reducers/parametersReducers'
import { clientEmailsReducer, userLoginReducer, userSignupReducer } from './reducers/authReducers';
import { createSoporteTecnicoReducer } from './reducers/serviciosReducers';
import { getDatosFacturacionReducer } from './reducers/accountReducers';
const reducer = combineReducers({
    userAuth: authReducer,
    getClient: getClientReducer,
    tipoProblema: tipoProblemaReducer,
    userDetails: userDetailsReducer,
    userLogin: userLoginReducer,
    userSignup: userSignupReducer,
    clientEmails: clientEmailsReducer,
    ubicacion: ubicacionReducer,
    getPlanes: getPlanesReducer,
    getFacturacion : getDatosFacturacionReducer,
    notificationsList: notificationsListReducer,
    promocionesList:  promocionesReducer,
    //
    createSoporteTecnico:createSoporteTecnicoReducer
    // userLogin: userLoginReducer,
    // userSignup: userSignupReducer,
})
export const replaceItem = (collection, item) => {
    const index = collection.findIndex((entry) => entry.id === item.id);
    return [...collection.slice(0, index), item, ...collection.slice(index + 1)];
};
const middleware = [thunk]
const store = createStore(reducer,
    composeWithDevTools(applyMiddleware(...middleware)))
export default store