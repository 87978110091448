import React, { useState, useEffect } from 'react'
import InputValidation from '../../components/utils/InputValidation'
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
export const CambioClaveWifiPage = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [name, setName] = useState({ campo: '', valido: true })
    const [pass, setPass] = useState({ campo: '', valido: true })
    const [host, setHost] = useState('')
    const [mac, setMac] = useState(false)
    const [loading, setLoading] = useState(false)
    //
    const onSubmit = () => {
        setLoading(true)
        if (host && mac) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            //192.168.18.18
            var raw = JSON.stringify({
                "host": host,
                "username": "u2000r18",
                "password": "iTcGy3@Wop4",
                "port": 22,
                "serialNumberONU": userInfo.ROUTER_MAC,
                "wlanName": name.campo,
                "wlanPass": pass.campo
            });
            console.log({
                "host": host,
                "username": "u2000r18",
                "password": "iTcGy3@Wop4",
                "port": 22,
                "serialNumberONU": userInfo.ROUTER_MAC,
                "wlanName": name.campo,
                "wlanPass": pass.campo
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch("https://serverapiolt.intercommerce.com.ec/api/appChangeWlanName-Password", requestOptions)
                .then(response => response.text())
                .then(result => {
                    setLoading(false)
                    setName({ campo: '', valido: true })
                    setPass({ campo: '', valido: true })

                    Swal.fire({ icon: 'success', text: '¡Se cambiaron los datos de la red!', })
                })
                .catch(error => {
                    setLoading(false)
                    Swal.fire({ icon: 'error', text: error, })
                    console.log('error', error)
                });
        }
    }
    useEffect(() => {
        onLoadNodo()
    }, [])
    const onLoadNodo = () => {
        var patterns = /^(?=.*[A-Za-z0-9])[A-Za-z0-9]{16}$/
        if (patterns) {
            if (patterns.test(userInfo.ROUTER_MAC)) {
                setMac(true)
            } else {
                setMac(false)
                Swal.fire({ icon: 'info', text: `Por favor contactarse al 045010056, asistente virtual Eli o las redes sociales oficiales para una atención personalizada.` })

            }
        }
        //
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "nodo": userInfo.servicios.nodo
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/nodo", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result);
                if (data.code == 200) {
                    setHost(data.host)
                } else {
                    Swal.fire({ icon: 'info', text: 'Hubo un problema contacte con soporte' })
                }
            }).catch(error => {
                console.log('error', error)
                Swal.fire({ icon: 'error', text: error })
            });
    }
    return (
        <>
            <Typography variant='h5'><strong>Cambio de Clave Wifi</strong></Typography>
            <Card>
                <CardContent>
                    <InputValidation
                        estado={name}
                        cambiarEstado={setName}
                        label="NOMBRE DE LA RED"
                        patterns={/^[A-Za-z0-9]+$/}
                        name="Name"
                        helperText="Solo letras y numeros sin espacios, minimo 8 caracteres y maximo 10"
                    />
                    <Box py={4}>
                        <InputValidation
                            estado={pass}
                            cambiarEstado={setPass}
                            label="CONTRASEÑA"
                            patterns={/^[A-Za-z0-9]+$/}
                            name="Password"
                            helperText="Solo letras y numeros sin espacios, minimo 8 caracteres y maximo 10"
                        />
                    </Box>
                    <LoadingButton
                        size='large'
                        loading={loading}
                        disabled={loading || !name.campo || !pass.campo || mac == false || pass.valido == false || name.valido == false}
                        onClick={() => onSubmit()}
                        variant="contained"
                    >
                        Guardar Cambios
                    </LoadingButton>

                    <Box py={2}>
                        <Alert severity="info">El cambio de clave wifi puede tardar, espere un momento..</Alert>
                    </Box>

                </CardContent>
            </Card>

        </>
    )
}
