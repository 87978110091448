import React, { useState, useEffect } from 'react'
import { auth, db } from '../../../db/firebase'
import { CartProducts } from './CartProducts';
import { useNavigate } from 'react-router-dom'
import { Container, Typography, Grid, Card, CardContent, Toolbar, Box, Button, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { ButtonBack } from '../../../components/utils/ButtonBack';
export const CartPage = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [showModal, setShowModal] = useState(false);
    const [cartProducts, setCartProducts] = useState([]);
    let navigate = useNavigate()
    useEffect(() => {
        getCart()
    }, [])
    const getCart = async () => {
        db.collection(`users/${userInfo.uid}/cart`).onSnapshot(snapshot => {
            const newCartProduct = snapshot.docs.map((doc) => ({
                ID: doc.id,
                ...doc.data()
            }));
            console.log(newCartProduct, 'new Cart')
            setCartProducts(newCartProduct);
        })
    }
    const triggerModal = () => {
        setShowModal(true);
    }
    const product_quantity = cartProducts.map(cartProduct => {
        return cartProduct.product_quantity;
    })
    const reducerOfQty = (accumulator, currentValue) => accumulator + currentValue;
    const totalQty = product_quantity.reduce(reducerOfQty, 0);
    const price = cartProducts.map((cartProduct) => {
        return cartProduct.product_total_price;
    })
    const reducerOfPrice = (accumulator, currentValue) => accumulator + currentValue;
    const totalPrice = price.reduce(reducerOfPrice, 0);
    let Product;
    const cartProductIncrease = (cartProduct) => {
        console.log(cartProduct, 'cartProduct')
        Product = cartProduct;
        Product.product_quantity = Product.product_quantity + 1;
        Product.product_total_price = Product.product_quantity * Product.product_price;
        db.collection(`users/${userInfo.uid}/cart`).doc(cartProduct.ID).update(Product).then(() => {
            console.log('increment added');
        })
    }
    const cartProductDecrease = (cartProduct) => {
        Product = cartProduct;
        if (Product.product_quantity > 1) {
            Product.product_quantity = Product.product_quantity - 1;
            Product.product_total_price = Product.product_quantity * Product.product_price;
            db.collection(`users/${userInfo.uid}/cart`).doc(cartProduct.ID).update(Product).then(() => {
                console.log('decrement');
            })
        }
    }
    // state of totalProducts
    const [totalProducts, setTotalProducts] = useState(0);
    // getting cart products   
    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                db.collection('Cart ' + user.uid).onSnapshot(snapshot => {
                    const qty = snapshot.docs.length;
                    setTotalProducts(qty);
                })
            }
        })
    }, [])
    return (
        <>

            <Container>
                {cartProducts.length > 0 && (
                    <div >
                        <Typography gutterBottom variant='h2' component='h1'><strong>Mi Carrito</strong></Typography>
                        <CartProducts cartProducts={cartProducts}
                            cartProductIncrease={cartProductIncrease}
                            cartProductDecrease={cartProductDecrease}
                        />
                        <div>
                            <Box py={2}>
                                <Grid container spacing={5}>
                                    <Grid item lg={8}>
                                    </Grid>
                                    <Grid item lg={2}>
                                        <Typography variant='h6'>Subtotal</Typography>
                                    </Grid>
                                    <Grid item lg={2}>
                                        <Typography variant='h6'><span>{totalPrice}</span></Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid container spacing={5}>
                                <Grid item lg={8}>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Box py={2}></Box>
                                    <Card variant='outlined'>
                                        <CardContent>
                                            <div className='summary-box'>
                                                <Button onClick={() => navigate('/store/checkout')} fullWidth variant='contained'>Comprar Ahora</Button>
                                                {/* <StripeCheckout
                                                    stripeKey='pk_test_51Hhu6bK4kL4WRmvGEUkTmdFw1lUtTAnadBSDb0eXGuA2JJGrntIBdm10llYu5RbPbLbaS1My74Rgdi0n5ePYIGB600p3V4GKmK'
                                                    token={handleToken}
                                                    billingAddress
                                                    shippingAddress
                                                    name='All Products'
                                                    amount={totalPrice * 100}
                                                ></StripeCheckout>
                                                <h6 className='text-center'
                                                    style={{ marginTop: 7 + 'px' }}>OR</h6>
                                                <button className='btn btn-secondary btn-md'
                                                    onClick={() => triggerModal()}>Cash on Delivery</button> */}
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            {/* <Typography textAlign='right' variant='h6'>Numero de Productos: <span>{totalQty}</span></Typography> */}
                        </div>
                    </div>
                )}
                {cartProducts.length < 1 && (
                    <Stack direction='column' spacing={2} justifyContent="center" alignItems="center">
                        <Typography align='center' variant='h5'>No hay Productos...</Typography>
                        <Button variant='contained' onClick={() => navigate('/store/productos')} >Buscar Premios</Button>
                    </Stack>
                )}
                {showModal === true && (
                    'modal'
                    // <Modal TotalPrice={totalPrice} totalQty={totalQty}
                    //     hideModal={hideModal}
                    // />
                )}
                <Box p={2}></Box>
                <ButtonBack />
            </Container>
        </>
    )
}