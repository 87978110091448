import {
    Typography, Container, Box
} from '@mui/material'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { listTipoProblema, listUbicacion } from '../../redux/actions/parametersActions';
import { Outlet, useLocation  } from "react-router-dom";
export const ServiciosPage = () => {
    const location = useLocation();
    var pathname = location.pathname.split( '/' )
    //var path_id = pathname[2];
    //var ret = path_id.replace('-',' ');

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listTipoProblema())
        dispatch(listUbicacion())
    }, [])
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Outlet />
        </Container>
    )
}
