const userInfo = {


}
export const authReducer = (state = { userInfo }, action) => {
    switch (action.type) {
        case 'AUTH_SUCCESS':
            return { loading: false, userInfo: action.payload }
        case 'AUTH_FAIL':
            return { loading: false, error: action.payload }
        case 'AUTH_ADD_ITEMS':
            return { ...state, userInfo: { ...action.payload, ...state.userInfo } };
        case 'AUTH_LOGOUT':
            return { userInfo: {} }
        default:
            return state
    }
}
export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case 'USER_DETAILS_REQUEST':
            return { ...state, loading: true }

        case 'USER_DETAILS_SUCCESS':
            return { loading: false, success: true, userInfo: action.payload }

        case 'USER_DETAILS_FAIL':
            return { loading: false, error: action.payload }

        case 'USER_DETAILS_RESET':
            return { user: {} }

        default:
            return state
    }
}
export const getClientReducer = (state = { client: [] }, action) => {
    switch (action.type) {
        case 'GET_CLIENT_REQUEST':
            return { ...state, loading: true }

        case 'GET_CLIENT_SUCCESS':
            return { loading: false, success: true, client: action.payload }

        case 'GET_CLIENT_FAIL':
            return { loading: false, error: action.payload }

        case 'GET_CLIENT_RESET':
            return { user: {} }

        default:
            return state
    }
}
export const notificationsListReducer = (state = { loading: false, notifications: [], previous: null }, action) => {
    switch (action.type) {

        case 'NOTIFICATIONS_LIST_SUCCESS':
            return { ...state, loading: false, notifications: action.payload, queryDoc: action.queryDoc, isLastPage: action.isLastPage }

        case 'NOTIFICATIONS_CREATE_SUCCESS':
            return { ...state, notifications: [action.payload, ...state.notifications] };

        case 'NOTIFICATIONS_LIST_FAIL':
            return { ...state, loading: false, error: action.payload }

        default:
            return state
    }
}