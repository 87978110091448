import {
    Container, Typography, Grid, ToggleButtonGroup, Button, Toolbar, Stack, ToggleButton, Box
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { db, firebase } from '../../db/firebase'
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import icon_yigacoin from '../../assets/svg/icon_yigacoin.svg'
import ReactImageMagnify from 'react-image-magnify';
import { ButtonBack } from '../../components/utils/ButtonBack';
export const ProductPage = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    let { id } = useParams();
    const [product, setProduct] = useState('');
    const [product_variants, setProductVariants] = useState(null);
    const [product_soldout, setProductSouldout] = useState(false);
    const [color, setColor] = useState('');
    const [size, setSize] = useState('');
    let navigate = useNavigate()
    useEffect(() => {
        getProduct()
    }, [])
    const getProduct = async () => {
        var requestOptions = {
            method: 'GET'
        };
        const response = await fetch(`https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/premios/${id}`, requestOptions)
        const result = await response.json();
        console.log(result)
        setProduct(result)
        //if (result.product_type === 1) {
        console.log('producto variante', result.product_variants[0])
        setProductVariants(result.product_variants[0])
        handleAlignment(result.product_color[0], result.product_size[0])
        //}
    }
    const addToCart = () => {
        //console.log(product_variants, 'product_variants')
        product_variants['product_quantity'] = 1;
        product_variants['product_total_price'] = product_variants.product_quantity * product_variants.product_price;
        db.collection(`users/${userInfo.uid}/cart`).doc(product_variants.id).set({
            ...product_variants,
            product_id: product_variants.id,
            created: firebase.firestore.FieldValue.serverTimestamp()
        })
        navigate('/store/cart')
    }
    const handleAlignment = (color, size) => {

        if (color !== null && size !== null) {
            setColor(color);
            setSize(size);
            handleVariants(color, size)
        }
    };
    const handleVariants = (color, size) => {
        if (product) {
            console.log(product, 'product')
            let variant_id = `${id}-${size}-${color}`
            console.log(variant_id, '_id')
            const productoEncontrado = product.product_variants.find((item) => item.id === variant_id);
            setProductVariants(productoEncontrado)
            setProductSouldout(productoEncontrado?.product_quantity === 0 || productoEncontrado?.product_quantity === undefined)
        }
    };
    return (
        <>
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item lg={7}>
                        {
                            product?.product_photo &&
                            product_variants?.product_photo?.map((item, index) => (
                                <ReactImageMagnify key={index} {...{
                                    smallImage: {
                                        alt: 'Wristwatch by Ted Baker London',
                                        isFluidWidth: true,
                                        src: item,
                                        sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                                    },
                                    largeImage: {
                                        src: item,
                                        width: 1920,
                                        height: 1920
                                    },
                                    style: { zIndex: 999 },
                                    lensStyle: { backgroundColor: 'rgba(0,0,0,.5)' }
                                }} />
                            ))
                        }
                    </Grid>
                    <Grid item lg={5}>
                        <Stack spacing={4}>
                            <Typography variant='h3' component='h1'><strong>{product.product_name}</strong> </Typography>
                            <Stack direction="row" spacing={1}>
                                <Typography color='gold' variant='h2' component='h2'>
                                    <strong>{product.product_price}</strong>
                                </Typography>
                                <img src={icon_yigacoin} alt='' width='40px' />
                            </Stack>
                            {product.product_type === 1 &&
                                <div>
                                    <Typography>Color</Typography>
                                    <Stack direction="row" spacing={2}>
                                        <ToggleButtonGroup
                                            value={color}
                                            exclusive
                                            onChange={(e, newAlignment) => handleAlignment(newAlignment, size)}
                                        >
                                            {product?.product_color?.map((item, index) => (
                                                <ToggleButton key={index} value={item} aria-label="left aligned">
                                                    {item}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </Stack>
                                    <Typography>Tamaño</Typography>
                                    <Stack direction="row" spacing={2}>
                                        <ToggleButtonGroup
                                            value={size}
                                            exclusive
                                            onChange={(e, newAlignment) => handleAlignment(color, newAlignment)}
                                        >
                                            {product?.product_size?.map((item, index) => (
                                                <ToggleButton key={index} value={item} aria-label="left aligned">
                                                    {item}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </Stack>
                                </div>}


                            <p>{`Solo quedan ${product_variants?.product_quantity} en stock (hay mas unidades en camino.)`}</p>
                            <Button
                                disabled={product_soldout}
                                onClick={() => addToCart()}
                                disableElevation
                                size='large'
                                color='secondary'
                                variant='contained'
                            > {product_soldout ? 'Producto Agotado' : 'Agregar al Carrito'}</Button>
                            <div>
                                <Typography variant='h6'><strong>

                                    Detalles del Producto</strong> </Typography>
                                <Typography variant='body1'>{product.product_description}</Typography>
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
                <Box pt={2}></Box>
                <ButtonBack />
            </Container>
        </>
    )
}
