import React, { useState, useEffect } from 'react'
import { db } from '../../db/firebase'
import { Grid, Card, CardContent, Box, Typography } from '@mui/material';
import { DashboardSvg } from '../../components/utils/svg/DashboardSvg';
import { Link as RouterLink } from 'react-router-dom';
const servicios = [
    {
        titulo: 'Soporte tecnico',
        descripcion: 'Genere un ticket de ayuda si su servicio de internet presenta problemas',
        link: '/servicios/soporte-tecnico',
        blocked: false
    },
    {
        titulo: 'Cambio de Plan',
        descripcion: 'Si necesitas más velocidad para tu navegación, genera un cambio de plan desde aquí',
        link: '/servicios/cambio-plan',
        img: '',
        blocked: false
    },
    {
        titulo: 'Cambió de domicilio',
        descripcion: '¿Se cambio de domicilio? Presione aquí para realizar su cambio de servicio de internet',
        link: '/servicios/cambio-domicilio',
        img: '',
        blocked: false
    },
    {
        titulo: 'Iniciar proceso de anulación de servicio',
        descripcion: '¿Deseas iniciar el proceso de anulación de servicio?, Esperamos verte pronto',
        link: '/servicios/anulacion-de-servicio',
        img: '',
        blocked: false
    },
    {
        titulo: 'Migración de Servicio',
        descripcion: 'Nos ajustamos a tu necesidad. ¿Deseas migrar tu servicio de Wireless a Fibra?',
        link: '/servicios/migracion-servicio',
        img: '',
        blocked: false
    },
    {
        titulo: 'Contrata nuestros Servicios',
        descripcion: 'Déjanos tu solicitud de servicio, y espera que un asesor se contacte contigo',
        link: '/servicios/contratar-servicio',
        img: '',
        blocked: false
    },
    {
        titulo: 'Servicio de Cámaras',
        descripcion: 'Déjanos tu solicitud de servicio, y espera que un asesor se contacte contigo',
        link: '/servicios/contratar-camaras',
        img: '',
        blocked: false
    },
    {
        titulo: 'Comprar Zapping',
        descripcion: '¿Deseas adquirir el servicio? Comprar zapping aqui',
        link: '/servicios/zapping',
        img: '',
        blocked: false
    },
    {
        titulo: 'Descargar licencia Zapping',
        descripcion: '¿Ya cuentas con el servicio zapping? Descarga tu licencia',
        link: '/accounts/zapping',
        img: '',
        blocked: true
    },
]
export const Servicios = () => {

    return (
        <>
            <Box pb={3} pt={5} pl={2}>
                <Typography variant="h5"><strong>Servicios</strong> </Typography>
            </Box>
            <Grid container spacing={5}>
                {servicios.map((item, index) => (
                    <Grid key={index} item xs={12} lg={4} md={6}>
                        <RouterLink style={{ textDecoration: 'none' }} to={item.link}>
                            <Card id="arrow" /* style={item.blocked === true ? { backgroundColor: "#f3f3f3" } : {}} */> {/* #f0f0f0 */}
                                <CardContent>
                                    <Box py={5}>
                                        <Grid container style={{ height: '100px' }}>
                                            <Grid item xs={8}>
                                                <Box display="flex" justifyContent="center">
                                                    <Box>
                                                        <Typography sx={{ mb: 1.5 }} variant="h6">
                                                            <strong>{item.titulo}</strong>
                                                        </Typography>
                                                        <Typography color="textSecondary" variant="body2">{item.descripcion}</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box display="flex" justifyContent="center">
                                                    <DashboardSvg key={index} item={index} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </RouterLink>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
