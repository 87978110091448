import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Typography, Grid, Container, Card, Box, TextField } from '@mui/material/';
import { db, firebase } from '../../db/firebase'
import { addPointsLine } from '../../redux/actions/accountActions';
export const EditPage = (props) => {
    const getClient = useSelector(state => state.getClient)
    const { client } = getClient
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const dispatch = useDispatch()
    const handleFile = () => {
        //dispatch(addPointsLine('Gracias por abrir un actualizar su foto de perfil', 50))
    }
    const update_nacimiento = () => {

    }
    return (
        <>
            <Container>
                <Box pb={3} pt={5} pl={2}>
                    <Typography variant="h5"><strong>Mi cuenta</strong> </Typography>
                </Box>
                <Grid container spacing={5}>
                    <Grid item lg={6} md={12}>
                        <Card>
                            <Box p={2}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    py={2}>
                                    <Avatar src={userInfo.picture ? userInfo.picture : ''} sx={{ width: 150, height: 150 }} />
                                </Box>
                                <input onClick={handleFile} type="file" id="myfile" name="myfile" />
                                <Box py={2}>
                                    <TextField fullWidth value={userInfo.NUM_CONTRATO} disabled label='CONTRATO' />
                                </Box>
                                <Box py={2}>
                                    <TextField fullWidth value={userInfo.nombre} disabled label='NOMBRES' />
                                </Box>
                                <Box py={2}>
                                    <TextField fullWidth value={userInfo.cedula} disabled label='CEDULA' />
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item lg={6} md={12}>
                        <Card  >
                            <Box p={2}>
                                <Box py={2}>
                                    <TextField fullWidth value={userInfo.servicios.perfil} disabled label='PLAN DE INTERNET' />
                                </Box>
                                <Box py={2}>
                                    <TextField fullWidth value={userInfo.REFERENCIA} disabled label='DIRECCION' />
                                </Box>
                                <Box py={2}>
                                    <TextField fullWidth value={userInfo.correo} disabled label='CORREO' />
                                </Box>
                                <Box py={2}>
                                    <TextField fullWidth value={userInfo.telefono} disabled label='TELEFONO' />
                                </Box>
                                
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}