import {
    Typography, Container, Box, CardContent, Card, TextField, Select,
    MenuItem, Grid, FormControl, Button, Stack, DialogActions, InputLabel, Autocomplete, Toolbar
} from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listPlanes, listTipoProblema, listUbicacion } from '../../redux/actions/parametersActions';
import moment from 'moment/moment';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from "react-router-dom";
import InputValidation from '../../components/utils/InputValidation';
import Swal from 'sweetalert2'
import { GeolocationGoogle } from '../../components/utils/GeolocationGoogle';
export const ContratarPage = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    //
  
    const [position, setPosition] = useState({
        lat: 1,
        lng: 1,
    })
    //
    const [cedula, setCedula] = useState('')
    const [nombre, setNombre] = useState({ campo: '', valido: true })
    const [direccion, setDireccion] = useState({ campo: '', valido: true })
    const [celular, setCelular] = useState({ campo: '', valido: true })
    const [telefono, setTelefono] = useState({ campo: '', valido: true })
    const [correo, setCorreo] = useState({ campo: '', valido: true })
    const [nacionalidad, setNacionalidad] = useState({ campo: '', valido: true })
    const [fechaNacimiento, setFechaNacimiento] = useState({ campo: '', valido: true })
    const [plan, setPlan] = useState('')

    //
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    //
    const getPlanes = useSelector(state => state.getPlanes)
    const { list_planes } = getPlanes
    //
    const createSoporteTecnico = useSelector(state => state.createSoporteTecnico)
    const { loading } = createSoporteTecnico
    //
    const ubicaciones = useSelector(state => state.ubicacion)
    const { list_ubicacion } = ubicaciones
    useEffect(() => {
        dispatch(listTipoProblema())
        dispatch(listUbicacion())
        dispatch(listPlanes())

    }, [])
    const submitHandler = (e) => {
        e.preventDefault()
        Swal.fire({
            text: "¿Esta seguro de enviar?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Acepto'
        }).then((result) => {
            if (result.isConfirmed) {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                let dat2 = {
                    user_uid: '24enlinea',
                    cedula: cedula,
                    nombres: nombre.campo,
                    correo: correo.campo,
                    telefono: telefono.campo,
                    celular: celular.campo,
                    coordenadas: {
                        latitude: position.lat,
                        longitude: position.lng
                    },
                    plan: plan.plan,
                    nacionalidad: nacionalidad.campo,
                    direccion: direccion.campo,
                    fechaNacimiento: fechaNacimiento.campo,
                }
                console.log(dat2, ' data2')
                var raw = JSON.stringify({
                    user_uid: '24enlinea',
                    cedula: cedula,
                    nombres: nombre.campo,
                    correo: correo.campo,
                    telefono: telefono.campo,
                    celular: celular.campo,
                    coordenadas: {
                        latitude: position.lat,
                        longitude: position.lng
                    },
                    plan: plan.plan,
                    nacionalidad: nacionalidad.campo,
                    direccion: direccion.campo,
                    fechaNacimiento: fechaNacimiento.campo,
                });
            
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'manual'
                };

                fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/soei/solicitud_servicio", requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        navigate(`/`)
                        Swal.fire({ icon: 'success', text: 'Se envio la informacion a un operador, en pocos minutos se contactaran contigo', })
                    })
                    .catch(error => console.log('error', error));
            }
        })
    }
    //Consultar cedula
    const apiCivil = (cedula) => {
        setCedula(cedula)
        if (cedula && cedula.length >= 10) {
            fetch(`https://serverbd.intercommerce.com.ec/?cedula=${cedula}`, { method: 'GET' })
                .then(response => response.text())
                .then(result => {
                    var dataParse = JSON.parse(result)
                    var data = dataParse[0]
                    //          
                    function calcularEdad(fecha_nacimiento) {
                        console.log('datos', data);
                        //console.log('Nombre' + data[0].nombre);
                        var hoy = new Date();
                        var cumpleanos = new Date(fecha_nacimiento);
                        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
                        var m = hoy.getMonth() - cumpleanos.getMonth();
                        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
                            edad--;
                        }
                        return edad;
                    }
                    const formatBirth = moment(data.fechanacimiento, "DD MM YYYY").format("YYYY/MM/DD");
                    var age = calcularEdad(formatBirth)

                    //
                    if (data.descripcion === "FALLECIDO") {
                        return Swal.fire({ icon: "info", text: "Este cliente se encuentra fallecido.", timer: 5000 })
                    }
                    if (age <= 18) {
                        return Swal.fire({ icon: "info", text: "Este cliente es menor de edad.", timer: 5000 })
                    }
                    setCedula(cedula);
                    setNombre({ campo: data.nombre, valido: true });
                    setDireccion({ campo: data.direccion, valido: true });
                    setNacionalidad({ campo: data.nacionalidad, valido: true })
                    setFechaNacimiento({ campo: data.fechanacimiento, valido: true })
                    //
                })
                .catch(error => console.log('error', error));
        }
    }
    //
    const handlePlane = (e) => {
        const p = list_planes.filter((item) => item.plan === e);
        console.log(e, 'plan')
        const data = p[0]
        console.log('data', data)
        setPlan(data)
    }
    function convertInter(value) {
        // item id
        // const string = value;
        // const match = string.match(/\d+/g);
        // var value1 = match[0];
        // var value2 = match[1];
        // value1 = value1 / 1024
        // value2 = value2 / 1024
        // return `${value1.toFixed(2)} MB / ${value2.toFixed(2)} MB`
        if (value == 354) {
            return '35 Mbps'
        }
        if (value == 355) {
            return '100 Mbps'
        }
        if (value == 356) {
            return '150 Mbps'
        }
        if (value == 357) {
            return '200 Mbps'
        }
        if (value == 358) {
            return '300 Mbps'
        }
        if (value == 374) {
            return '70 Mbps'
        }
    }
    return (

        <form onSubmit={submitHandler}>
                        <Box pb={2}>
                <Typography
                    variant='h5'
                    color='textPrimary'
                    component='h1'
                    style={{ textTransform: 'capitalize' }}
                ><strong>Contratar</strong></Typography>
            </Box>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>

                            <Box pb={3}>
                                <TextField
                                    value={cedula}
                                    onChange={(e) => apiCivil(e.target.value)}
                                    label="CÉDULA | RUC"
                                />
                            </Box>
                            <InputValidation
                                estado={nombre}
                                cambiarEstado={setNombre}
                                multiline={true}
                                label="NOMBRES"
                                name="nombre"
                                disabled={loading}
                            />
                            <InputValidation
                                estado={celular}
                                cambiarEstado={setCelular}
                                multiline={true}
                                label="CELULAR"
                                name="nombre"
                                disabled={loading}
                            />
                            <InputValidation
                                estado={telefono}
                                cambiarEstado={setTelefono}
                                multiline={true}
                                label="TELEFONO"
                                name="nombre"
                                disabled={loading}
                            />

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <InputValidation
                                estado={direccion}
                                cambiarEstado={setDireccion}
                                multiline={true}
                                label="DIRECCION"
                                name="motivo"
                                disabled={loading}
                            />
                            <InputValidation
                                estado={fechaNacimiento}
                                cambiarEstado={setFechaNacimiento}
                                multiline={true}
                                label="FECHA NACIMIENTO"
                                name="nombre"
                                disabled={loading}
                            />
                            <InputValidation
                                estado={nacionalidad}
                                cambiarEstado={setNacionalidad}
                                multiline={true}
                                label="NACIONALIDAD"
                                name="nombre"
                                disabled={loading}
                            />
                            <InputValidation
                                estado={correo}
                                cambiarEstado={setCorreo}
                                multiline={true}
                                label="CORREO"
                                name="nombre"
                                disabled={loading}
                            />
                        </Grid>
                    </Grid>
                    <Box pb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="ubicacion">SELECCIONAR PLAN</InputLabel>
                            <Select
                                labelId="ubicacion"
                                id="ubicacion"
                                value={plan.plan}
                                label="SELECCIONAR PLAN"
                                onChange={(e) => handlePlane(e.target.value)}
                            >
                                {
                                    userInfo && list_planes && list_planes.map((item, index) => (
                                        item.id == 354 &&
                                        <MenuItem key={index} value={item.plan}>{item.plan} - <strong>${item.costo}</strong> - {convertInter(item.id)}</MenuItem>
                                    ))
                                }
                                {
                                    userInfo && list_planes && list_planes.map((item, index) => (
                                        item.id == 374 &&
                                        <MenuItem key={index} value={item.plan}>{item.plan} - <strong>${item.costo}</strong> - {convertInter(item.id)}</MenuItem>
                                    ))
                                }
                                {
                                    userInfo && list_planes && list_planes.map((item, index) => (
                                        item.id != 362 && item.id != 363 && item.id != 364 && item.id != 365 && item.id != 366 && item.id != 367 && item.id != 374 &&
                                        <MenuItem key={index} value={item.plan}>{item.plan} - <strong>${item.costo}</strong> - {convertInter(item.id)}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box py={2}>
                        <GeolocationGoogle position={position} setPosition={setPosition} />
                    </Box>
                    <Stack spacing={2}>

                    </Stack>
                </CardContent>
            </Card>
            <Box py={2}>
                <DialogActions>
                    <LoadingButton
                        size='large'
                        loading={loading}
                        //disabled={loading || !plan || !cedula.campo || !nombre.campo || !correo.campo}
                        type="submit"
                        variant="contained"
                    >
                        Enviar informacion
                    </LoadingButton>
                </DialogActions>
            </Box>
        </form>

    )
}
