export const getDatosFacturacionReducer = (state = { facturas: [] }, action) => {
    switch (action.type) {
        case 'GET_FACTURA_REQUEST':
            return { ...state, loading: true }
        case 'GET_FACTURA_SUCCESS':
            return { loading: false, success: true, facturas: action.payload }
        case 'GET_FACTURA_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}