import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Swal from 'sweetalert2';
export const DeleteCardButton = ({token, setLoading}) => {
    useEffect(() => {
    }, [])
    const handleDelete = async () => {
        console.log(token, 'props')
        Swal.fire({
            title: '¿Quitar el método de pago?',
            text: 'Si quitas este método de pago, no podrás pagar tu servicio de Internet de manera automatica a menos que vuelvas a añadirlo',
            showCancelButton: true,
            confirmButtonText: "Eliminar",
        }).then((result) => {
            if (result.isConfirmed) {
                delete_cards()
            } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
            }
        });
    }
    const delete_cards = async () => {
        try {
            var myHeaders = new Headers()
            myHeaders.append("Authorization", "Bearer mjwguxipbjg1iitxkjxb6tz6s3tl8f-rrgi371ad1sjkhgnrs0fcvvn8tkzworjbkcbsm");
            var requestOptions = {
                headers: myHeaders,
                method: 'DELETE'
            }
            const response = await fetch(`https://api.abitmedia.cloud/pagomedios/v2/cards/${token}`, requestOptions)
            const result = await response.text();
            var re = JSON.parse(result)
            if(re.status === 200){
                Swal.fire("Saved!", "Tarjeta eliminada", "success");
                setLoading(true)
            }
            console.log(re)
        } catch (error) {
            console.error('Error:', error);
        }
    }
    return (
        <>
            <Button onClick={() => handleDelete()} variant='contained' color='error'>
                <DeleteOutlineOutlinedIcon />
            </Button>
        </>
    )
}
