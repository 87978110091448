import React from 'react'
import { Stack, CircularProgress } from '@mui/material';
export default function Loader() {
    return (<Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%' }}>
        <CircularProgress />
    </Stack>)
}