import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Card, CardContent, Typography, Box, Grid, Button, Stack, } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { SelectLinea } from '../components/account/SelectLinea';
import { ButtonAbitmedia } from '../components/account/ButtonAbitmedia';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Servicios } from '../components/servicios/Servicios';
import { Promociones } from '../components/account/Promociones';
import { db, firebase } from '../db/firebase'
import yigacoins from '../assets/svg/icon_yigacoin.svg'
import mcdonalds from '../assets/img/mcdonalds.png'
import mcdonalds2 from '../assets/img/mcdonalds2.png'
import { BtnPagoRecurrente } from '../components/account/BtnPagoRecurrente';
export const DashboardPage = () => {
    let navigate = useNavigate()
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    //
    useEffect(() => {
        //abitmedia()
        load()
    }, [])
    const load = async () => {
        const querySnapshot = await db.collection(`abitmedia_tokens_recurrencia`).get()
        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        console.log(data, 'data')
    }
    const abitmedia = () => {
        db.collection("abitmedia_tokens").orderBy("created", "desc").get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var data = doc.data()
                    //Estado 0 es cuando el token no esta utilizado 
                    if (data.estado === 0) {
                        const fs = new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
                        //const fg = new Date(data.fecha_generada).toLocaleString("en-US", { timeZone: "America/New_York" })
                        console.log(' fecha generada1: ', data.fecha_generada)
                        //console.log(' fecha generada2: ', fg)
                        var fecha_actual = new Date(fs)
                        var fecha_generada = new Date(data.fecha_generada)
                        console.log(' fecha generada3: ', fecha_generada)
                        //
                        var fecha_limite = fecha_generada
                        fecha_limite.setMinutes(fecha_generada.getMinutes() + 50)
                        fecha_limite.setSeconds(0)
                        console.log(data.nombre)

                        console.log(' fecha servidor: ', fecha_actual)
                        console.log(' fecha limite: ', fecha_limite)
                        console.log(' ')
                        //Detectamos si el token no paso el limite de 30 min para cambiarle el estado a 1 estado utilizado
                        if (fecha_actual > fecha_limite) {
                            console.log(data.nombre)
                            console.log(' fecha limite: ', fecha_limite)
                            console.log(' fecha actual: ', fecha_actual)
                            console.log(' ')
                            const hours = fecha_actual.getHours()
                            const minutes = fecha_actual.getMinutes()
                            const seconds = fecha_actual.getSeconds()
                            const day = fecha_actual.getDay()
                            var fecha_itrans = fecha_actual
                            //Cambiamos la fecha si es fin de semana para el dia lunes
                            if (day === 5 || day === 6 || day === 0) {
                                fecha_itrans.setHours(8)
                                fecha_itrans.setMinutes(0)
                                fecha_itrans.setSeconds(0)
                                if (day === 5) {
                                    fecha_itrans.setDate(fecha_actual.getDate() + 3)
                                }
                                if (day === 6) {
                                    fecha_itrans.setDate(fecha_actual.getDate() + 2)
                                }
                                if (day === 0) {
                                    fecha_itrans.setDate(fecha_actual.getDate() + 1)
                                }
                            } else if (hours >= 16 || minutes > 0 || seconds > 0) {
                                fecha_itrans.setHours(8)
                                fecha_itrans.setMinutes(0)
                                fecha_itrans.setSeconds(0)
                                fecha_itrans.setDate(fecha_itrans.getDate() + 1)
                            }
                            console.log(data.nombre)
                            console.log('fecha_itrans: ', fecha_itrans)
                            console.log('token: ', data.token)
                            console.log(' ')
                            let fetch = require('node-fetch');
                            var accessToken = '2y-13-uo10szmexbbvgbjxdibmsojzsw6frjieiuburvrwtddtl-fejncpslhogzbifcgnspn-tzweq'
                            var requestOptions = {
                                method: 'GET'
                            }
                            fetch(`https://cloud.abitmedia.com/api/payments/status-transaction?access-token=${accessToken}&token=${data.token}`, requestOptions)
                                .then(response => response.text())
                                .then(result => {
                                    const re = JSON.parse(result)
                                    if (re.message === "Información de la transacción") {
                                        let myHeaders = new fetch.Headers();
                                        myHeaders.append("Content-Type", "application/json");
                                        var moment = require('moment');
                                        var time = moment(fecha_itrans).tz("America/New_York").format('hhmmss')
                                        var date = moment(fecha_itrans).tz("America/New_York").format('YYYYMMDD')
                                        var contrapartida = '01000' + data.cedula + '0' + data.linea
                                        //
                                        var raw = JSON.stringify({
                                            "tipoMensaje": "0200",
                                            "bitmapPrimario": "F01A040108008000",
                                            "bitmap1": "0000004000000000",
                                            "bitmap2": contrapartida,
                                            "bitmap3": "000101",
                                            "bitmap4": data.deuda,
                                            "bitmap12": time,
                                            "bitmap13": date,
                                            "bitmap15": date,
                                            "bitmap22": "WEB",
                                            "bitmap32": "03007",
                                            "bitmap37": "000001",
                                            "bitmap49": "840",
                                            "bitmap90": data.deuda
                                        })
                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: raw,
                                        }
                                        fetch("http://45.236.168.20:8085/iTransWService/g2c/rest/yiga5/transaccionar", requestOptions)
                                            .then(response => response.text())
                                            .then(result => {
                                                console.log(data.nombre)
                                                console.log('PAGO')
                                                console.log('token: ', data.token)
                                                console.log(' ')
                                                const id = `${data.nombre} ${data.token}`
                                                db.collection("abitmedia_tokens").doc(id).update({
                                                    estado: 2
                                                }).catch((error) => {
                                                    db.collection("logs").add({
                                                        message: `No se encontro el id ${data.nombre} ${data.token}`,
                                                        created: firebase.firestore.FieldValue.serverTimestamp(),
                                                        function: 'CRON ABITMEDIA',
                                                        usuario: '24ENLINEA'
                                                    })
                                                })
                                            }).catch(error => {
                                                db.collection("logs").add({
                                                    message: `API ITRANS TRANSACCIONAR ERROR ${data.nombre} ${data.token}`,
                                                    error: error,
                                                    created: firebase.firestore.FieldValue.serverTimestamp(),
                                                    function: 'CRON ABITMEDIA',
                                                    usuario: '24ENLINEA'
                                                })
                                                console.log('error itrans trasaccionar', error)
                                            })
                                    }
                                    if (re.message === "Solicitud de pago no encontrada") {
                                        console.log(data.nombre)
                                        console.log('NO SE ENCONTRO TOKEN')
                                        console.log('token: ', data.token)
                                        console.log(' ')
                                    }
                                    if (re.message === "No se encontró pago para la transacción") {
                                        console.log(data.nombre)
                                        console.log('NO ESTA PAGADO')
                                        console.log('token: ', data.token)
                                        console.log(' ')
                                    }
                                })
                        } else {
                            console.log(data.nombre)
                            console.log('PASO DEL TIEMPO DEL TOKEN')
                            console.log('token: ', data.token)
                            console.log(' ')
                            db.collection("abitmedia_tokens").doc(`${data.nombre} ${data.token}`).update({
                                estado: 1
                            })
                        }
                    }
                })
            })
    }
    return (
        <>
            <Box pb={2} pt={4} pl={1}>
                <Typography variant="h5" component='h1' gutterBottom>
                    SALUDOS, {userInfo && userInfo.nombre} <span>👋</span>
                </Typography>
            </Box>
            <SelectLinea props={userInfo} />
            <Grid container spacing={5}>
                <Grid item xs={12} lg={6} md={12} >
                    <Card

                        sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            bgcolor: '#EBDFFA',
                            overflow: 'hidden',
                            height: '300px'
                        }}
                    >
                        <CardContent >
                            <Box pl={4} pr={4} pt={5} pb={2} >
                                <div>
                                    <Typography sx={{ mb: 1.5 }} style={{ color: 'black' }}>
                                        Su valor pendiente de pago es:
                                    </Typography>
                                    <Typography variant='h6' style={{ color: 'black' }}>{userInfo && userInfo.servicios && userInfo.servicios.perfil}</Typography>
                                    <Typography variant='h2' style={{ color: 'black' }}>
                                        <strong>${userInfo && userInfo.facturacion && userInfo.facturacion.total_facturas}</strong>
                                    </Typography>
                                </div>
                                <Stack justifyContent="flex-end" direction="row" spacing={2}>
                                    {userInfo && userInfo.facturacion && userInfo.facturacion.total_facturas > 0 &&
                                        <ButtonAbitmedia />
                                    }

                                    <Button
                                        onClick={() => navigate('/account/subscription/')}
                                        variant='contained'
                                        color='warning'
                                    >Suscripción</Button>

                                </Stack>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={12} lg={6} xs={12}>
                    <Card
                        sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            bgcolor: '#00B2FF',
                            overflow: 'hidden',
                            color: 'white',
                            height: '300px'
                        }}
                    >
                        <CardContent>
                            <Box pl={4} pr={4} pt={5} pb={2} >
                                <div>
                                    <img src={yigacoins} alt='' width={75} />
                                    <Typography variant='h4'><strong>YigaCoins</strong> </Typography>
                                    <Typography sx={{ mb: 1.5 }} >
                                        Descubre tus YigaCoins conoce los beneficios que Yiga5 tiene para ti
                                    </Typography>
                                </div>
                                <Box display="flex" flexDirection="row-reverse">
                                    <RouterLink style={{ textDecoration: 'none' }} to="coins/coins">
                                        <Button variant='contained' color='warning'>Ingresar</Button>
                                    </RouterLink>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Box py={5}>
                <Typography variant="h5"><strong>Beneficios</strong></Typography>
            </Box>

            <Card
                sx={{
                    flexDirection: { xs: 'column', md: 'row' },
                    overflow: 'hidden',
                    height: '300px'
                }}
            >
                <CardContent sx={{
                    padding: '0px',
                    margin: '0px'
                }}>
                    <Grid container columnSpacing={10}>
                        <Grid item xs={4} container direction="row"
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                background: 'red',
                            }}>
                            <img src={mcdonalds2} alt='' style={{ width: '40%' }} />
                        </Grid>
                        <Grid item xs={8} container direction="row"
                            sx={{
                                justifyContent: "start",
                                alignItems: "center",
                                background: '#faf8df',
                                height: '300px'
                            }}>
                            <Box>
                                <Typography style={{ color: 'black' }}>
                                    {/* Promoción única Yiga5 */}
                                    Beneficio exclusivo por ser cliente Yiga5
                                </Typography>
                                <Box pt={3} pb={2}>
                                    <Typography variant='h5' style={{ color: '#FFBC0F', fontWeight: 'bold' }}>MCDONALDS</Typography>
                                    <Typography variant='h4' style={{ color: 'black', fontWeight: 'bold' }}>
                                        RECIBE TU CÓDIGO PROMOCIONAL
                                    </Typography>
                                </Box>
                                <Button
                                    onClick={() => navigate('/beneficios/mcdonalds/')}
                                    variant='contained'
                                    style={{ background: '#DB0009' }}
                                >Obtener</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Servicios />
            <Promociones />
        </>
    )
}