import React, { Fragment } from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/userActions';

import { Avatar, Menu, MenuItem, IconButton, Box } from '@mui/material';
import { MenuAccount } from './MenuAccount';
function MenuUser() {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logoutHandler = () => {
        dispatch(logout())
    }
    return (
        <React.Fragment>
            <IconButton
                aria-label="account of current user"
                aria-haspopup="true"
                aria-controls={open ? 'menu-list-grow' : undefined}
                color="inherit"
                onClick={handleClick}
            >
                <Avatar src={userInfo.picture ? userInfo.picture : ''} />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box style={{ borderRadius: 12, margin: '0px 4px 0px' }}>
                    <MenuAccount />
                    <Box py={1}>
                    <MenuItem component={RouterLink} to="/accounts/profile/" style={{ borderRadius: 12, margin: '0px 4px 0px' }} onClick={handleClose}>Cuenta</MenuItem>
                    </Box>

               
                    <MenuItem style={{ borderRadius: 12, margin: '0px 4px 0px' }} onClick={() => logoutHandler()}>Cerrar sesión</MenuItem>
                </Box>
            </Menu>
        </React.Fragment>
    )
}
export default MenuUser
