import React, { useState, useEffect } from 'react'
import { db, firebase } from '../../../db/firebase'
import { Container, Typography, Select, MenuItem, Box, Grid, Button, TextField, Stack, Card, CardContent } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import { ButtonBack } from '../../../components/utils/ButtonBack';
export const CheckoutPage = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [local, setLocal] = useState('');
    //
    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [movil, setMovil] = useState('');
    const [email, setEmail] = useState('');
    const [local_message, setLocalMessage] = useState(null);
    const [local_url, setLocalURL] = useState(null);
    const [cartProducts, setCartProducts] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        getCart()
    }, [])
    const getCart = async () => {
        db.collection(`users/${userInfo.uid}/cart`).onSnapshot(snapshot => {
            const newCartProduct = snapshot.docs.map((doc) => ({
                ID: doc.id,
                ...doc.data()
            }));
            console.log(newCartProduct, 'new Cart')
            setCartProducts(newCartProduct);
        })
        setNombre(userInfo.nombre)
        setTelefono(userInfo.telefono)
        setMovil(userInfo.movil)
        setEmail(userInfo.email)
    }
    const onSubmit = async () => {
        db.collection(`users/${userInfo.uid}/yigapuntos/`).doc(`${userInfo.servicios.id}`).get().then((doc) => {
            let yigacoins_ = doc.data()
            console.log(yigacoins_.puntos)
            console.log(totalPrice)
            if (yigacoins_.puntos >= totalPrice) {
                //
                const increment = firebase.firestore.FieldValue.increment(-totalPrice)
                db.collection(`users/${userInfo.uid}/yigapuntos/`).doc(`${userInfo.servicios.id}`).update({
                    puntos: increment
                })
                //
                const randomNumber = Math.floor(Math.random() * 100);
                cartProducts.map((item, index) => {
                    let order_id = `ORDER_${randomNumber}_${item.id}`
                    let product_id = item.id
                    db.collection(`users/${userInfo.uid}/order`).doc(order_id).set({
                        order_id: order_id,
                        ...item,
                        order_send: local,
                        order_total: totalPrice,
                        order_status: 0,
                        order_received: firebase.firestore.FieldValue.serverTimestamp(),
                        created: firebase.firestore.FieldValue.serverTimestamp()
                    })
                    console.log(item, 'item')
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    var raw = JSON.stringify({
                        "product_id": item.product_id,
                        "product_name": item.product_name,
                        "product_photo": item.product_photo,
                        "order_send": local,
                        "order_id": order_id,
                        "product_quantity": item.product_quantity,
                        "product_size": item.product_size,
                        "product_price": item.product_price,
                        "product_color": item.product_color,
                        "customer_dni": userInfo.uid,
                        "customer_email": email,
                        "customer_cell_phone": movil,
                        "customer_phone": telefono,
                        "customer_name": nombre,
                    });
                    var requestOptions = {
                        method: 'PUT',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };
                    fetch(`https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/store/${product_id}`, requestOptions)
                        .then(response => response.text())
                        .then(result => console.log(result))
                        .catch(error => console.log('error', error));
                    db.collection(`users/${userInfo.uid}/cart`).doc(product_id).delete()
                })
                navigate(`/store/order/`)
                Swal.fire({
                    icon: 'success',
                    text: 'Tu compra fue exitosa. Tu pedido estará listo para el retiro en aproximadamente un día',
                    showConfirmButton: false
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    text: 'No tienes los YigaCoins suficientes!',
                    showConfirmButton: false
                })
            }
        })
    }
    const price = cartProducts.map((cartProduct) => {
        return cartProduct.product_total_price;
    })
    const reducerOfPrice = (accumulator, currentValue) => accumulator + currentValue;
    const totalPrice = price.reduce(reducerOfPrice, 0);
    const handleLocal = (e) => {
        setLocal(e)
        if (e.includes('Santa Elena')) {
            setLocalURL('https://maps.app.goo.gl/r9ywmq3hzAU884br5')
            setLocalMessage('Sector K1 Cabo Quiroz, entrada a Santa Elena')
        }
        if (e.includes('Mall El Fortín')) {
            setLocalURL('https://maps.app.goo.gl/PB64TeuWGP6Nimph8')
            setLocalMessage('Mall El Fortín Piso 1, al lado de Banco Bolivariano')
        }
        if (e.includes('Nexus')) {
            setLocalURL('https://maps.app.goo.gl/2vt4Y8eBVRuKcKwH9')
            setLocalMessage('Av. Narcisa de Jesús 6421 y Puente de La Joya, Parque de Negocios Nexus I')
        }
    }
    return (
        <>
            <Container>
                <Typography gutterBottom variant='h2' component='h1'><strong>Compra Ahora</strong></Typography>
                <Grid container spacing={5}>
                    <Grid item md={6}>
                        <Stack spacing={2}>
                            <Typography variant='h5'> <strong>1.Informacion de Contacto</strong>  </Typography>
                            <TextField
                                onChange={(e) => setNombre(e.target.value)}
                                variant='outlined'
                                value={nombre}
                                fullWidth
                            />
                            <TextField onChange={(e) => setEmail(e.target.value)} variant='outlined' value={email} fullWidth />
                            <TextField onChange={(e) => setTelefono(e.target.value)} variant='outlined' value={telefono} fullWidth />
                            <TextField onChange={(e) => setMovil(e.target.value)} variant='outlined' value={movil} fullWidth />
                            <Typography variant='h5'> <strong>2.-Localidad de Retiro</strong> </Typography>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                fullWidth
                                value={local}
                                variant='outlined'
                                onChange={(e) => handleLocal(e.target.value)}
                            >
                                <MenuItem value="Mall El Fortín Piso 1, al lado de Banco Bolivariano">Guayaquil - Centro de Experiencia</MenuItem>
                                <MenuItem value="Av. Narcisa de Jesús 6421 y Puente de La Joya, Parque de Negocios Nexus I">Guayaquil - Oficina Matriz Nexus 1</MenuItem>
                                <MenuItem value="Sector K1 Cabo Quiroz, entrada a Santa Elena">Santa Elena - Distribuidor Autorizado</MenuItem>
                            </Select>
                            <Box py={2}>
                            {
                                local_message
                            }
                            </Box>
                            <Box pb={2}>
                                <Button disabled={!local_url} onClick={() => window.open(local_url)} variant='contained'>Ver en Google Maps</Button>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item md={6}>
                        <Card>
                            <CardContent>
                                <Stack spacing={2}>
                                    <Typography variant='h5'><strong>3.-Canje</strong> </Typography>
                                    <Typography variant='h5' textAlign='right'>
                                        <strong>Total: <span>{totalPrice}</span></strong>
                                    </Typography>
                                    <Button disabled={!local} fullWidth onClick={() => onSubmit()} variant='contained'>Pagar YigaCoins</Button>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Box p={2}></Box>
                <ButtonBack />
            </Container>
        </>
    )
}