import { auth, db, storage, firebase, functions } from '../../db/firebase';
export const authState = (formData, factura) => ({
    type: 'AUTH_SUCCESS',
    payload: {
        ...formData,
        ...factura,
    }
});
export const emailSelection = (formData) => async (dispatch) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
            "cedula": formData.dni
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/GetClientsDetails", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data1 = JSON.parse(result)
                if (data1.estado === 'error') {
                    console.log('Esta cedula no pertenece', result)
                    dispatch({
                        type: 'USER_LOGIN_REQUEST',
                        payload: 'No pertenece',
                    })
                }
                const email_ = data1.datos[0].correo
                //console.log(types_plan, 'plan')
                const sign = auth.signInWithEmailAndPassword(email_, formData.password)
                //console.log(sign.user.uid)
                const user = {
                    uid: sign.user.uid
                }
                const userDB = db.collection('users').doc(user.uid).get()
                const data = {
                    cedula: userDB.data().cedula,
                    email: userDB.data().email,
                    phone: userDB.data().phone,
                }
            })
            .catch(error => console.log('error', error));
        dispatch({
            type: 'EMAIL_SELECTION_REQUEST'
        })
    } catch (error) {
        dispatch({
            type: 'EMAIL_SELECTION_FAIL',
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const login = (formData) => async (dispatch) => {
    try {
        dispatch({ type: 'USER_LOGIN_REQUEST' })
        //
        const mikrowisp = functions.httpsCallable('mikrowisp')
        mikrowisp(formData.dni)
            .then(res => {
                const data1 = res.data
                console.log(data1, 'data1')
                if (data1.estado === 'error') {
                    const error = {
                        code: 'not-found-client',
                        message: 'No existe el cliente con este numero de cedula'
                    }
                    dispatch({
                        type: 'USER_LOGIN_FAIL',
                        payload: error,
                    })
                }
                console.log('Datos de usuario:', data1)
                const email_ = data1[0].correo
                const sign = auth.signInWithEmailAndPassword(email_, formData.password)
                    .then((userCredential) => {
                        // Signed in
                        var user = userCredential.user;
                        console.log(user, ' user')
                        // ...
                    })
                    .catch((error) => {
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.log(error, 'error 2')
                        dispatch({
                            type: 'USER_LOGIN_FAIL',
                            payload: error,
                        })
                    });
            })
            .catch(error => console.log('error', error));
    } catch (error) {
        console.log(error, 'error')
    }
}
export const apiUserEmails = (formData) => async (dispatch) => {
    try {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
            "cedula": formData.cedula.campo
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/GetClientsDetails", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data1 = JSON.parse(result)

                // console.log(data1, 'a')

                if (data1.estado === 'error') {
                    console.log('Esta cedula no pertenece', result)
                    dispatch({
                        type: 'USER_LOGIN_REQUEST',
                        payload: 'No pertenece',
                    })
                }

                const email_ = data1.datos[0].correo
                const cedula_ = data1.datos[0].correo

                const data = auth.createUserWithEmailAndPassword(email_, formData.password.campo)
                    .then((userCredential) => {
                        // Signed in
                        var user = userCredential.user;
                        console.log('Hola 223', user)

                        //userCredential.sendEmailVerification()


                        db.collection('users').doc(formData.cedula.campo).set({
                            uid: formData.cedula.campo,
                            email: formData.name,
                        }).then(() => {
                            //Se realizo todo con exito

                            console.log('')
                        }).catch((error) => {
                            //return res.status(200).json("No se puedo crear los logs de licencia", error)
                        });


                        const user1 = firebase.auth().currentUser;
                        user1.updateProfile({
                            displayName: formData.cedula.campo,
                        }).then(() => {



                            //console.log('Hola entraste')



                            dispatch({
                                type: 'USER_LOGIN_SUCCESS',
                                payload: data1.datos[0]
                            })


                        }).catch((error) => {
                            // An error occurred
                            // ...
                        });
                        // ...
                    })
                    .catch((error) => {
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        // ..
                    });





            })
            .catch(error => console.log('error', error));

    } catch (error) {
        dispatch({
            type: 'USER_LOGIN_FAIL',
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const userDetails = (formData) => async (dispatch, getState) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "dni": formData
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/get_clients", requestOptions)
            .then(response => response.text())
            .then(result => {
                const result_json = JSON.parse(result)
                const data = result_json.datos[0]
                console.log('test', result)
                dispatch({
                    type: 'AUTH_SUCCESS',
                    payload: { ...data }
                })
            })
            .catch(error => console.log('error', error))
    } catch (error) {
        dispatch({
            type: 'USER_DETAILS_FAIL',
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const logout = () => async (dispatch) => {
    dispatch({ type: 'USER_LOGOUT' })
    dispatch({ type: 'AUTH_LOGOUT' })
    await auth.signOut()
}
//
export const getClient = (formData) => async (dispatch) => {
    try {
        dispatch({ type: 'GET_CLIENT_REQUEST' })
        const mikrowisp = functions.httpsCallable('mikrowisp')
        mikrowisp(formData)
            .then(res => {
                const data1 = res.data
                console.log(data1, 'data 1')
                if (data1.estado === 'error') {
                    const error = {
                        code: 'not-found-client',
                        message: 'No existe el cliente con este numero de cedula'
                    }
                    dispatch({
                        type: 'GET_CLIENT_FAIL',
                        payload: error,
                    })
                }
                var cedula = data1[0].cedula
                var _data = data1[0]
                var ubicacion = ''
                //
                if(_data.NUM_CONTRATO.includes('G')){
                    //console.log('Guayaquil')
                    ubicacion = 'GUAYAQUIL'
                }
                if(_data.NUM_CONTRATO.includes('D')){
                    //console.log('Duran')
                    ubicacion = 'DURAN'
                }
              
                db.collection("users").doc(`${cedula}`).get()
                    .then((doc) => {
                        if (doc.exists) {
                            var dataClient = doc.data()
                            const no_retirado = data1.filter(item => item.estado !== 'RETIRADO')
                            console.log(no_retirado, 'no_retirado')
                            const dataT = {
                                ...dataClient,
                                index_linea: 1,
                                picture: dataClient.picture,
                                ubicacion: ubicacion,
                                ...data1[0],
                                servicios: no_retirado[0].servicios[0],
                            }
                            dispatch({
                                type: 'GET_CLIENT_SUCCESS',
                                payload: no_retirado,
                            })
                            dispatch({
                                type: 'AUTH_ADD_ITEMS',
                                payload: dataT,
                            })
                        } else {
                            console.log("No se encontro usuario");
                        }
                    }).catch((error) => {
                        console.log("Error getting document:", error);
                    });
            })
            .catch(error => console.log('error', error));
    } catch (error) {
        dispatch({
            type: 'GET_CLIENT_FAIL',
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
//
export const getInvoice = (formData) => async (dispatch) => {
    try {
        
        dispatch({ type: 'GET_INVOICE_REQUEST' })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
            "limit": 25,
            "idcliente": formData.idcliente
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        }
        fetch("https://mikrowisp.intercommerce.com.ec/api/v1/GetInvoices/", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data1 = JSON.parse(result)
                const factura = data1.facturas[0]
                if (data1.estado === 'error') {
                    const error = {
                        code: 'not-found-client',
                        message: 'No existe el cliente con este numero de cedula'
                    }
                    dispatch({
                        type: 'GET_INVOICE_FAIL',
                        payload: error,
                    })
                }
                dispatch({
                    type: 'GET_INVOICE_SUCCESS',
                    payload: factura,
                })
                const data_factura = {
                    factura: factura
                }
                dispatch({
                    type: 'AUTH_ADD_ITEMS',
                    payload: data_factura,
                })
            })
            .catch(error => console.log('error', error));

    } catch (error) {
        dispatch({
            type: 'GET_INVOICE_FAIL',
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const listNotifications = (cedula, startAfterDoc, persistProducts = []) => async (dispatch, getState) => {
    try {
        let ref = await db.collection("users").doc(cedula).collection("notifications")
            .orderBy("created", "desc").limit(40)
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...persistProducts,
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
     
            dispatch({
                type: 'NOTIFICATIONS_LIST_SUCCESS',
                payload: data,
                queryDoc: snapshot.docs[totalCount - 1],
                isLastPage: totalCount < 1
            })
        })
    } catch (error) {
        dispatch({
            type: 'NOTIFICATIONS_LIST_FAIL',
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}