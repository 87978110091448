import React, { useState, useEffect } from 'react';
import {
    AppBar, Box, Toolbar, Badge, Typography, Stack
} from '@mui/material/';
import { auth, db } from '../../db/firebase';
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import logoyigastore from '../../assets/icons/logoyigastore.png'
export const NavbarStore = (props) => {
    const dispatch = useDispatch();
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    let navigate = useNavigate()
    const location = useLocation();
  
    const { window } = props;

    return (
        <>
            <AppBar position="static" color='transparent' elevation={0}>
                <Toolbar>
                    <Typography sx={{ flexGrow: 1, }} variant="h4" color="inherit" component="div">
                       
                       {
                        location.pathname !== '/coins/coins' ? <img src={logoyigastore} style={{ cursor: 'pointer' }} onClick={() => navigate('/store/productos')} alt='' width='30%' /> : ''
                       }
                        
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Stack alignItems="center" direction="row" spacing={5}>
                            {/* <Typography>
                                <strong>Y{puntos.puntos} </strong>
                            </Typography> */}
                            <Badge onClick={() => navigate('/store/order')} sx={{ cursor: 'pointer' }} badgeContent={0} color="primary">
                                <LocalMallIcon color='disabled' fontSize='large' />
                            </Badge>
                            <Badge onClick={() => navigate('/store/cart')} sx={{ cursor: 'pointer' }} badgeContent={0} color="primary">
                                <LocalGroceryStoreOutlinedIcon color='disabled' fontSize='large' />
                            </Badge>
                        </Stack>
                    </Box>
                </Toolbar>
            </AppBar >
            {
                        location.pathname !== '/coins/coins' ?    <Toolbar /> : ''
                       }

         
            {props.children}
        </>
    )
}