import { useEffect, useState } from 'react';
import { Container, Box, Typography, Button, Grid, Stack } from '@mui/material'
import { useSelector } from 'react-redux';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css'
import Loader from '../../components/utils/Loader';
import { ButtonBack } from '../../components/utils/ButtonBack';
import { DeleteCardButton } from './DeleteCardButton';
import { useNavigate } from 'react-router-dom';
export default function SubscriptionPage() {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [loading, setLoading] = useState(false)
    const [cards, setCards] = useState([])
    const navigate = useNavigate();
    const load_cards = async () => {
        setLoading(true)
        try {
            if (userInfo.cedula) {
                var myHeaders = new Headers()
                myHeaders.append("Authorization", "Bearer mjwguxipbjg1iitxkjxb6tz6s3tl8f-rrgi371ad1sjkhgnrs0fcvvn8tkzworjbkcbsm");
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                }
                const response = await fetch(`https://api.abitmedia.cloud/pagomedios/v2/cards?integration=true&document=${userInfo.cedula}`, requestOptions)
                const result = await response.text();
                var ressponse = JSON.parse(result)
                console.log(ressponse)
                setCards(ressponse.data[0].cards)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error('Error:', error);
        }
    }
    useEffect(() => {
        load_cards()
    }, [userInfo.cedula])
    let state = {
        number: '454063******0031',
        expiry: '',
        cvc: '',
        name: '',
        focus: ''
    }
    return (
        <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={5}>
                <Box pl={2}>
                    <Typography variant='h3' gutterBottom component='h1'><strong>Pagos y Suscripción</strong></Typography>
                    <Typography variant='body' gutterBottom component='p'>Tus datos de pago, transacciones, pagos periódicos.</Typography>
                </Box>
                <Button onClick={() => navigate('/account/subscription/addcard')} variant='contained' color='info'>Agregar Metodo de Pago</Button>
                <Typography variant='h6' component='h2'><strong>Métodos de Pago</strong></Typography>
            </Stack>
            {loading === false ?
                <Box py={5}>
                    {cards.length > 0 ? cards.map((item, index) => (
                        <Grid container spacing={5}>
                            <Grid key={index} item xs={12} md={3}>
                                <Stack spacing={2} direction="row" alignItems="flex-end">
                                    <Cards
                                        cvc={state.cvc}
                                        expiry={state.expiry}
                                        focused="number"
                                        name="*************"
                                        number={item.number}
                                    />
                                     <DeleteCardButton setLoading={setLoading} token={item.token} /> 
                                </Stack>
                            </Grid>
                        </Grid>
                    )) :
                        <Box sx={{ height: 500 }}>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                sx={{ height: '100%' }}>
                                <Typography align='center' >No tiene tarjetas</Typography>
                            </Stack> 
                        </Box>}
                </Box>
                : <Box sx={{ height: 580 }}><Loader /></Box>}
            <ButtonBack />
        </Container>
    )
}