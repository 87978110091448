import {
    Typography, Container, Box, CardContent, Card, TextField, Select,
    MenuItem, Grid, FormControl, Button, Stack, DialogActions, InputLabel, Autocomplete, Toolbar
} from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listPlanes, listTipoProblema, listUbicacion } from '../../redux/actions/parametersActions';
import { createCambioDomicilio, createMigracion } from '../../redux/actions/serviciosActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from "react-router-dom";
import InputValidation from '../../components/utils/InputValidation';
import Swal from 'sweetalert2'
import { GeolocationGoogle } from '../../components/utils/GeolocationGoogle';
export const MigracionServicioPage = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    //
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [position, setPosition] = useState({
        lat: latitude,
        lng: longitude,
    })
    //
    const [ubicacion, setUbicacion] = useState('')
    const [servicio, setServicio] = useState('')
    const [direccion, setDireccion] = useState('')
    const [plan, setPlan] = useState('')
    const [descripcion, setDescripcion] = useState({ campo: '', valido: true })
    //
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    //
    const getPlanes = useSelector(state => state.getPlanes)
    const { list_planes } = getPlanes
    //
    const createSoporteTecnico = useSelector(state => state.createSoporteTecnico)
    const { loading } = createSoporteTecnico
    //
    const ubicaciones = useSelector(state => state.ubicacion)
    const { list_ubicacion } = ubicaciones
    useEffect(() => {
        dispatch(listTipoProblema())
        dispatch(listUbicacion())
    }, [])
    const submitHandler = (e) => {
        e.preventDefault()
        Swal.fire({
            text: "¿Esta seguro de enviar?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Acepto'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(createMigracion({

                    tipoproblema: servicio,
                    servicio: servicio,
                    direccion: descripcion.campo,
                    coordenadas: `${position.latitude},${position.longitude}`,

                }, navigate))
            }
        })
    }
    //
    const inputs = [
        {
            label: 'NOMBRE ',
            value: userInfo.nombre
        },
        {
            label: 'CEDULA',
            value: userInfo.cedula
        },
        {
            label: 'ESTADO',
            value: userInfo.estado
        },
        {
            label: 'LINEA DE CONTRATO',
            value: userInfo.NUM_CONTRATO
        },
        {
            label: 'DIRRECION',
            value: userInfo.direccion_principal
        },
        {
            label: 'CORREO',
            value: userInfo.correo
        },
    ]
    return (

        <form onSubmit={submitHandler}>
            <Box pb={2}>
                <Typography
                    variant='h5'
                    color='textPrimary'
                    component='h1'
                    style={{ textTransform: 'capitalize' }}
                ><strong>Migración de Servicio</strong></Typography>
            </Box>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        {inputs.map((item, index) => (
                            <Grid key={index} item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label={item.label}
                                    disabled
                                    value={item.value}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Box pt={4} display='flex'>
                        <Box pr={2} >
                            <TextField
                                name='Latitud'
                                disabled
                                value={position.lat}
                            />
                        </Box>
                        <TextField
                            name='Longitud'
                            disabled
                            value={position.lng}
                        />
                    </Box>
                    <Box py={4}>
                        <GeolocationGoogle position={position} setPosition={setPosition} />
                    </Box>
                    <Stack spacing={2}>


                        <InputValidation
                            estado={direccion}
                            cambiarEstado={setDireccion}
                            label="DIRECCION"
                            name="motivo"
                            disabled={loading}
                        />
                        <InputValidation
                            estado={descripcion.campo}
                            cambiarEstado={setDescripcion}
                            multiline={true}
                            label="REFERENCIA NUEVA DIRECCION"
                            name="motivo"
                            rows={4}
                            maxRows={4}
                            disabled={loading}
                        />
                        <FormControl fullWidth>
                            <InputLabel id="servicio">NUEVO SERVICIO</InputLabel>
                            <Select
                                labelId="servicio"
                                id="servicio"
                                value={servicio}
                                label="NUEVO SERVICIO"
                                onChange={(e) => setServicio(e.target.value)}
                            >
                                <MenuItem value="WIRELESS">WIRELESS</MenuItem>
                                <MenuItem value="FIBRA">FIBRA</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </CardContent>
            </Card>
            <Box py={2}>
                <DialogActions>
                    <LoadingButton
                        size='large'
                        loading={loading}
                        disabled={loading || !descripcion.campo}
                        type="submit"
                        variant="contained"
                    >
                        Enviar informacion
                    </LoadingButton>
                </DialogActions>
            </Box>
        </form>

    )
}
