import { useEffect, useState } from 'react';
import {
    Container, Box, CardContent, Card, Typography, Grid, TableCell, Button, TableContainer,
    Table, TableHead, TableRow, TableBody, Stack
} from '@mui/material/';
import { SelectLinea } from '../../components/account/SelectLinea';
import { db } from '../../db/firebase'
import moment from 'moment'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBack } from '../../components/utils/ButtonBack';
export const CoinsDesafio = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [data, setData] = useState([]);
    const [plan, setPlan] = useState([]);
    const dispatch = useDispatch()
    useEffect(() => {
        fetchData();
    }, [userInfo?.index_linea]);
    //const load
    const fetchData = async () => {
        const querySnapshot = await db.collection(`users/${userInfo.uid}/yigapuntos/${userInfo.servicios.id}/historico`).get()
        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        setData(data)
        console.log('hola')
        console.log(data, 'hola')
        //
        await db.collection(`users/${userInfo.uid}/yigapuntos/`).doc(`${userInfo.servicios.id}`).onSnapshot((doc) => {
            let data = doc.data()
            setPlan(data)
        })
    }

    return (
        <>
            <Box p={5}></Box>
            <div style={{ borderRadius: '20px', height: '800px', display: 'flex', position: 'relative', background: `url('https://firebasestorage.googleapis.com/v0/b/yiga5-24enlinea.appspot.com/o/yigacoins%2Fman-neon-suit-sits-chair-with-neon-sign-that-says-word-it.png?alt=media&token=1cecc1ad-cb30-4144-b689-b4ccac658db4')` }}>
                <div style={{ background: 'rgba(154, 137, 231, 0.5)', borderRadius: '20px', top: '0', left: '0', position: 'absolute', width: '100%', height: '100%' }}></div>
                <div style={{ zIndex: 1, width: '100%' }}>
                    <Container style={{ height: '300px', justifyContent: 'flex-end' }}>
                        <Box py={5}>
                            <Typography color='white' variant='h6' gutterBottom>
                                ¡Hazte adicto a los YigaCoins, es muy fácil!
                                Sólo tienes que canalizar todas tus gestiones mediante nuestra APP YigaExperience para canjear premios y beneficios.
                            </Typography>
                            <Box py={5}></Box>
                            <Grid container spacing={2}>
                                <Grid item md={4}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant='h3' align='center'>
                                                <strong>Basic</strong>
                                            </Typography>

                                            <Stack direction="row" spacing={2}>
                                                <AutoAwesomeIcon color='info' />
                                                <Typography>Creación de cuenta en App Yiga Experience (opción tu perfil)</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={2}>
                                                <AutoAwesomeIcon color='info' />
                                                <Typography >Apertura de tickets por cambio de propietario</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={2}>
                                                <AutoAwesomeIcon color='info' />
                                                <Typography>Actualizacion de datos cada 3 meses</Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item md={4}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant='h3' align='center'>
                                                <strong>Medium</strong>
                                            </Typography>
                                            <Stack direction="row" spacing={2}>
                                                <AutoAwesomeIcon color='info' />
                                                <Typography>Uso de los totems</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={2}>
                                                <AutoAwesomeIcon color='info' />
                                                <Typography>Apertura de tickets por cambio de direccion con status finalizado</Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item md={4}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant='h3' align='center'>
                                                <strong>Pro</strong>
                                            </Typography>
                                            <Stack direction="row" spacing={2}>
                                                <AutoAwesomeIcon color='info' />
                                                <Typography>Pagos puntuales registrados dentro del ciclo de facturacion</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={2}>
                                                <AutoAwesomeIcon color='info' />
                                                <Typography>Aperturar de tickets por cambio de plan con status finalizado (Upgrade)</Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                        <ButtonBack />
                    </Container>
         
                </div>
            </div>
        </>
    )
}