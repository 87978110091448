import React from 'react'
import { Typography, Container, Box, CardContent, Card, Link, Button } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom';
export const PolicyPersonalPage = () => {
  return (
    <Container>
      <Box py={5}></Box>
      <Card elevation={5}>
        <CardContent>
          <Typography align='center' variant='h3' component="h1"><strong>Política de tratamiento de datos personales</strong> </Typography>
          <p>Su privacidad y el respeto a sus datos es importante para INTERCOMMERCE S.A (YIGA5). Sus datos personales serán tratados de forma ulterior sin ninguna excepción acorde a la presente Política, y normativas ecuatorianas aplicables relativas a la protección de datos, entre otras, la Ley Orgánica de Protección de Datos Personales vigente. Esta declaración le informa sobre otros tratamientos de tipo ulterior que realizamos, adicionales al tratamiento de interés legítimo estipulado en la Política de Privacidad, y sobre el cual se solicita su consentimiento individual o total:</p>
          <p>
            Tratamiento de datos para envío de comunicaciones comerciales propios</p>
          <p>
            Autorizo a INTERCOMMERCE S.A. a utilizar mis datos personales y que los mismos sean tratados por ellos para que me sean ofrecidos, mediante cualquier medio de comunicación, información comercial relacionada a beneficios, productos y servicios de INTERCOMMERCE S.A.</p>
          <p>
            Declaro conocer que el no conceder esta autorización no afectará la prestación del servicio.</p>
          <p>
            Tratamiento de datos para fines estadísticos y analíticos
          </p>
          <p>
            Envío de comunicaciones comerciales de terceros</p>
          <p>
            Autorizo a INTERCOMMERCE S.A. a utilizar mis datos personales (nombres y apellidos, correo electrónico y/o celular) que los mismos sean tratados por encargados, para que me sean ofrecidos, mediante cualquier medio de comunicación, productos y servicios de terceros, con los cuales INTERCOMMERCE S.A. mantiene alianzas estratégicas para brindar beneficios en los sectores de telecomunicaciones, tecnología, consumo masivo, estilo de vida, negocios, educación y salud.
          </p>
          <p>
            Declaro conocer que el no conceder esta autorización no afectará la prestación del servicio contratado.</p>
        </CardContent>
        <Box pb={2} pl={2}>
          <Button variant='outlined' >
            <Link component={RouterLink} to={'/accounts/signup/'}>Volver atras</Link>
          </Button>
        </Box>
      </Card>
    </Container>
  )
}
