import { auth, db } from '../../../db/firebase'
import { Grid, Button, Table, Box, ButtonGroup, Typography, Card, CardContent, Tooltip, IconButton, Stack, Divider } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import RemoveIcon from '@mui/icons-material/Remove';
import { ButtonBack } from '../../../components/utils/ButtonBack';
export const IndividualCartProduct = ({ cartProduct, cartProductIncrease, cartProductDecrease }) => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const handleCartProductIncrease = () => {
        cartProductIncrease(cartProduct);
    }
    const handleCartProductDecrease = () => {
        cartProductDecrease(cartProduct);
    }
    const handleCartProductDelete = () => {
        db.collection(`users/${userInfo.uid}/cart`).doc(cartProduct.ID).delete().then(() => {
            console.log('successfully deleted');
        })
    }
    return (
        <div>
            <Grid container spacing={5}>
                <Grid item xs={12} lg={2}>
                    <img width='100%' src={cartProduct.product_photo[0]} alt={cartProduct.product_name} />
                </Grid>
                <Grid item xs={12} lg={4} style={{ display: 'flex' }}>
                    <Stack direction="column" alignItems="flex-start" justifyContent="center">
                        <Typography variant='h6'>{cartProduct.product_name}</Typography>
                        <Typography color='GrayText' variant='body2'>{cartProduct.product_price}</Typography>
                        {cartProduct?.product_type === 1 &&
                            <div>
                                <Typography color='GrayText' variant='body2'>Tamaño: {cartProduct.product_size}</Typography>
                                <Typography color='GrayText' variant='body2'>Color: {cartProduct.product_color}</Typography>
                            </div>}
                    </Stack>
                </Grid>
                <Grid item lg={2} style={{ display: 'flex' }}>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                        <IconButton onClick={() => handleCartProductDecrease()}>
                            <RemoveIcon />
                        </IconButton>
                        <Typography variant='h6'>{cartProduct.product_quantity}</Typography>
                        <IconButton onClick={() => handleCartProductIncrease()}>
                            <AddIcon />
                        </IconButton>
                    </Stack>
                </Grid>
                <Grid item lg={2} style={{ display: 'flex' }}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <IconButton onClick={() => handleCartProductDelete()}>
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={2} style={{ display: 'flex' }}>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                        <Typography variant='h6'>{cartProduct.product_total_price}</Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Divider />
  
        </div>
    )
}
