import React from 'react'
import { Container, Box } from '@mui/system'
import { ContratarPage } from './servicios/ContratarPage'
import { Button, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom';
export default function OutContratarPage() {
    return (
        <><Container>
            <Box pt={5} pb={2}> 
            <Typography  variant='h5' 
                    color='textPrimary'  
                    component='h1'><strong>Contrata Nuestro Servicio</strong>  </Typography>
            </Box>
          
            <ContratarPage />
            <Box pr={3}>
                    <Button variant='contained' component={RouterLink}  to="/">Volver atras</Button>
                </Box>
        </Container></>
    )
}
