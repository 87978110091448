import { db, firebase } from '../../db/firebase'
import Swal from 'sweetalert2';
export const getDatoFactura = (formData, navigate) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'GET_FACTURA_REQUEST' })
        const {
            userAuth: { userInfo },
        } = getState();
        console.log(userInfo, 'userAuth')
        console.error( "idcliente", userInfo.id)
        //
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
            "limit": 25,
            "idcliente": userInfo.id
        });
   
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };
        fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/GetInvoices", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result)
                console.log('Se consulto', data)
                dispatch({
                    type: 'GET_FACTURA_SUCCESS',
                    payload: data.facturas,
                })
            })
            .catch(error => console.log('error', error));
        //

    } catch (error) {

        dispatch({
            type: 'GET_FACTURA_FAIL',
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
export const addPoints = (formData, puntos) => async (dispatch, getState) => {
    try {
        const {
            getClient: { client }
        } = getState()
        let cedula = formData[0].cedula

        const serviciosUnicosArray = formData.flatMap((usuario) => usuario.servicios)
            .filter((servicio, index, servicios) => {
                return servicios.findIndex((s) => s.id === servicio.id) === index;
            });

        //obtenemos su plan y le damos sus puntos
        serviciosUnicosArray.map((servicio) => {
            if (servicio.servicios.costo >= 15.68 && servicio.servicios.costo <= 23.40) {
                puntos *= 0.5
            }
            if (servicio.servicios.costo >= 23.40 && servicio.servicios.costo <= 26.89) {
                puntos *= 1
            }
            if (servicio.servicios.costo >= 26.90 && servicio.servicios.costo <= 31.89) {
                puntos *= 2
            }
            if (servicio.servicios.costo >= 31.90 && servicio.servicios.costo <= 49.89) {
                puntos *= 3
            }
            if (servicio.servicios.costo >= 49.90) {
                puntos *= 4
            }
            //nuevoServicio.puntos = puntos
            console.log('puntos', puntos)
            console.log('cedula', cedula, 'servicio', servicio.id)
            //incrementamos puntos
            const increment = firebase.firestore.FieldValue.increment(puntos)
            db.collection(`users/${cedula}/yigapuntos/`).doc(`${servicio.id}`).update({
                idservicio: servicio.id,
                idperfil: servicio.idperfil,
                perfil: servicio.perfil,
                instalado: servicio.instalado,
                puntosBase: 100,
                puntos: increment,
                created: firebase.firestore.FieldValue.serverTimestamp()
            })
            //planes de puntos
            db.collection(`users/${cedula}/yigapuntos/${servicio.id}/historico`).add({
                idservicio: servicio.id,
                idperfil: servicio.idperfil,
                perfil: servicio.perfil,
                instalado: servicio.instalado,
                puntosBase: 100,
                puntos: increment,
                descripcion: 'Test',
                created: firebase.firestore.FieldValue.serverTimestamp()
            });
            return null;
        });

    } catch (error) {
    }
}
export const addPointsLine = (descripcion, puntos) => async (dispatch, getState) => {
    try {
        const {
            userAuth: { userInfo }
        } = getState()

        //obtenemos su plan y le damos sus puntos
        if (userInfo.servicios.costo >= 15.68 && userInfo.servicios.costo <= 23.40) {
            puntos *= 0.5
        }
        if (userInfo.servicios.costo >= 23.40 && userInfo.servicios.costo <= 26.89) {
            puntos *= 1
        }
        if (userInfo.servicios.costo >= 26.90 && userInfo.servicios.costo <= 31.89) {
            puntos *= 2
        }
        if (userInfo.servicios.costo >= 31.90 && userInfo.servicios.costo <= 49.89) {
            puntos *= 3
        }
        if (userInfo.servicios.costo >= 49.90) {
            puntos *= 4
        }

        console.log('puntos', puntos)
        //incrementamos puntos
        const increment = firebase.firestore.FieldValue.increment(puntos)
        db.collection(`users/${userInfo.cedula}/yigapuntos/`).doc(`${userInfo.servicios.id}`).update({
            puntosBase: puntos,
            puntos: increment,
            created: firebase.firestore.FieldValue.serverTimestamp()
        })
        //planes de puntos
        db.collection(`users/${userInfo.cedula}/yigapuntos/${userInfo.servicios.id}/historico`).add({
            idservicio: userInfo.servicios.id,
            idperfil: userInfo.servicios.idperfil,
            perfil: userInfo.servicios.perfil,
            instalado: userInfo.servicios.instalado,
            puntosBase: 100,
            puntos: increment,
            descripcion: descripcion,
            created: firebase.firestore.FieldValue.serverTimestamp()
        });
        Swal.fire({
            showConfirmButton: false,
            title: `Has Ganado ${puntos} YigaCoins⭐`,
            text: descripcion,
        })
        return null;
    } catch (error) {
    }
}