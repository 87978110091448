import {
    Container, Table, Typography, TableContainer, TableRow, TableCell, TableBody, TableHead, Paper, Box
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, storage } from "../../db/firebase";
import moment from 'moment'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarColumnsButton,
    GridExportMenuItemProps,
    GridPrintExportMenuItem,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { GridFilterPanel } from "@mui/x-data-grid";
export const EncuestasActualizarDatosPage = () => {
    const [data, setData] = useState();
    const [total, setTotal] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);
    useEffect(() => {
        LoadData();
    }, []);
    const LoadData = (startAfterDoc, persistMessages = []) => {
        let ref = db.collection("users")
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
        ref.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;
            const data = [
                ...persistMessages,
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            console.log(data, 'data')
            setData(data);
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
            setIsLastPage(totalCount < 1);
        });
    };

    function CustomFooterTotalComponent(props) {
        return ''
    }
    const columns = [
        {
            field: 'created', headerName: 'FECHA DE ENVIO', type: 'dateTime', width: 160,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },

        { field: 'id', headerName: 'CLIENTE', width: 300 },
        { field: 'email', headerName: 'correo', width: 125 },
        { field: 'device_register', headerName: 'device register', width: 125 },
      

    ];

    const csvOptions = {
        delimiter: ';',
        fileName: `SOLICITUD APROBADAS ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
    };
    const printOptions = { hideFooter: true, hideToolbar: true }
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridPrintExportMenuItem options={printOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );

    return (
        <>
            <Container>
                <Box py={5}>
                    <Typography variant="h5" component='h2'>
                        <strong>Dashboard Actualizar Clientes</strong>
                        </Typography>
                </Box>
                <Box paddingTop={2} sx={{ height: 800, width: '100%' }}>

                <DataGrid
                    density="compact"
                    rows={data ? data : []}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    // disableColumnFilter
                    // disableColumnSelector
                    // disableDensitySelector
                    //hideFooterPagination
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterTotalComponent,
                    }}
                    onStateChange={(state) => {
                        console.log(state.pagination.rowCount);
                        setTotal(state.pagination.rowCount)
                    }}
                />
                </Box>
            </Container>
        </>
    );
};
