import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField, Typography, Box, Grid, Button, Container,
    Checkbox, Dialog, Card, CardContent, Alert, LinearProgress,
    DialogTitle, DialogContent, DialogActions,
    RadioGroup, Radio, FormControl, FormControlLabel, CircularProgress
} from "@mui/material";
import { db, firebase } from '../../../../db/firebase'
import Swal from 'sweetalert2'
import moment from 'moment'
import logo from '../../../../assets/img/elipago.png'
import logo_dark from '../../../../assets/img/tarjetas.png'
import logo1 from '../../../../assets/img/Logoscanalespago.png'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import zappingImage2 from './../../../../assets/img/zapping_image2.png'
import bannerZapping from './../../../../assets/img/bannerZapping.jpeg'
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
export const BuyButtonZapping2 = () => {

    let navigate = useNavigate()

    const [statusBuyZapping, setStatusBuyZapping] = useState("");
    const [loadingStatusBuyZapping, setLoadingStatusBuyZapping] = useState(false);

    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    const [contractArray, setContractArray] = useState([]);
    const [open, setOpen] = useState(false);
    const [loadingButtonBuy, setLoadingButtonBuy] = useState(false);
    const [openTerms, setOpenTerms] = useState(false);
    const [openSuccessBuy, setOpenSuccessBuy] = useState(false);
    const [openConfirmBuy, setOpenConfirmBuy] = useState(false);
    const [esfuerzo, setEsfuerzo] = useState('');
    const [satisfaccion, setSatisfaccion] = useState('');
    const [recomendacion, setRecomendacion] = useState('');
    const [cedula, setCedula] = useState(userInfo.cedula);
    const [idCliente, setIdCliente] = useState('');
    const [nombre, setNombre] = useState({ campo: '', valido: null })
    const [estado, setEstado] = useState({ campo: '', valido: null })
    const [correo, setCorreo] = useState({ campo: '', valido: null })
    const [telefono, setTelefono] = useState({ campo: '', valido: null })
    const [movil, setMovil] = useState({ campo: '', valido: null })
    const [direccionPrincipal, setDireccionPrincipal] = useState({ campo: '', valido: null })
    const [url, setURL] = useState('')
    const [deuda, setDeuda] = useState('')
    const [num_contrato, setNumContrato] = useState('')
    const [tab, setTab] = useState(false)
    const [modal, setModal] = useState(false)

    const [linea, setLinea] = useState(1)
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const [checkedTerms, setCheckedTerms] = useState(false);

    const [hasZapping, setHasZapping] = useState(false)
    const [pdfUrl, setPdfUrl] = useState(null)
    const [loadingPdf, setLoadingPdf] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [linkWhatsapp, setLinkWhatsapp] = useState('')
    //
    const handleSubmit = () => {
        window.localStorage.clear()
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        //
        //000000002153
        var newDeuda = deuda / 100
        console.log(newDeuda)
        console.log(newDeuda, 'new deuda')
        console.log(deuda, 'deuda')
        // console.log(userInfo.servicios[linea - 1].id, 'id servicio')
        var raw = JSON.stringify({
            "cedula": userInfo.cedula,
            "nombre": userInfo.nombre,
            "direccion": `${Math.floor(Math.random() * 100)} ${userInfo.direccion_principal.substring(0, 30)}`,
            "mobile": userInfo.telefono,
            "email": userInfo.correo,

            "description": 'Pago Zapping 24EnLinea',
            "deuda": newDeuda
        })
        console.log(userInfo.direccion_principal, 'direccion_principal')
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };
        fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/zappingAbitmedia2abitmedia2", requestOptions)
            .then(response => response.text())
            .then(result => {
                var res = JSON.parse(result)
                console.log(res, ' res')
                if (res.errors) {
                    Swal.fire({
                        icon: 'info',
                        text: `Tenemos incoveninentes al momento de pagar contacte con soporte 045010056, Code: ${res.errors.reference}`,
                    })
                }
                setLoading(false)
                setURL(res.data)
                window.localStorage.clear()
                //window.location.href = res.data.url;

                let ventanaPago = window.open(res.data.url, "mywindow", "width=900,height=900");
                window.localStorage.clear()
                //let htmlElement = newWindow.document.documentElement.innerHTML;
                //console.log(htmlElement)
                //setPage(htmlElement)
                //console.log(res.data)
                //Agregamos el tokens de abitmedia
                var intervalo = setInterval(function () {
                    if (ventanaPago.closed) {
                        setIsLoading(true);
                        setOpenSuccessBuy(true);
                        console.log('ventanaPago', ventanaPago)
                        console.log('La ventana ha sido cerrada');
                        var raw2 = JSON.stringify({
                            "token": res.data.token,
                            //  "token": "cha_VIpt9JIMXTMOMtsiZ7vW2180",
                        })
                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw2,
                        };

                        fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/abitmediaConsulta", requestOptions)
                            .then(response => response.text())
                            .then(result => {
                                let dataPago = JSON.parse(result)
                                if (dataPago?.data) {
                                    if (dataPago.data[0]?.status == 1) {
                                        handleOpen()
                                    }
                                }
                            }).catch(error => {
                                setLoading(false)
                                console.log('error', error)
                            }).finally(() => {
                                setIsLoading(false);
                            });
                        clearInterval(intervalo);
                    }
                }, 1000);

                var id = `${userInfo.nombre} ${res.data.token}`
                let date = new Date()

                let formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
                var newDeuda = deuda / 100

                console.log(userInfo.nombre)
                console.log(res.data.token)
                console.log(linea)
                console.log(userInfo.cedula)

                db.collection("abitmedia_tokens").doc(id).set({
                    nombre: userInfo.nombre,
                    token: res.data.token,
                    linea: linea,
                    estado: 0,
                    cedula: userInfo.cedula,
                    fecha_generada: formattedDate,
                    deuda: deuda,
                    deuda_: newDeuda,
                    idservicio: userInfo.servicios.id,
                    created: firebase.firestore.FieldValue.serverTimestamp()
                })

            }).catch(error => {
                setLoading(false)
                console.log('error', error)
            });
    }
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSuccessBuy = () => {
        setOpenSuccessBuy(false);
    };

    const handleCloseTerms = () => {
        setOpenTerms(false);
    };

    const handleEnviarEncuesta = () => {
        console.log("Holi")
        db.collection('encuesta_satisfaccion').doc().set({
            cedula: userInfo.cedula,
            nombre: userInfo.nombre,
            esfuerzo: esfuerzo,
            satisfaccion: satisfaccion,
            recomendacion: recomendacion,
            medioPago: "web",
            created: firebase.firestore.FieldValue.serverTimestamp()
        })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    text: 'Gracias por compartir su experiencia',
                })
                console.log('Encuesta guardada correctamente en Firestore');
                handleClose(); // Cerrar modal después de enviar la encuesta
            })
            .catch((error) => {
                console.error('Error al guardar la encuesta:', error);
            });
    };

    useEffect(() => {
        // getClient(parseInt(userInfo.cedula))
        // handleContrato(userInfo.NUM_CONTRATO)
        console.log('userInfo', userInfo)
        console.log('userInfo.servicios', userInfo.servicios)
        console.log('userInfo.servicios[linea - 1].id', userInfo.servicios[linea - 1]?.id)
        var deudaString = "000000000499";
        setDeuda(deudaString)
    }, [])

    const handleChangeCheckbox = (event) => {
        setCheckedTerms(event.target.checked);
        console.log("Checkbox value:", event.target.checked);
    };

    const getPdfZapping = () => {

        // console.log('userInfo', userInfo)

        setLoadingPdf(true);

        const numContrato = userInfo.NUM_CONTRATO;

        // expresión regular para obtener el numero de contrato
        const onlyNumeroContrato = numContrato.match(/\d+/)[0];

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "contract_numbers": userInfo.NUM_CONTRATO,
            "dni": userInfo.cedula
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://dataguardian.intercommerce.com.ec/funcion/license_code_zapping/", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log('result', result)
                setLoadingPdf(false);
                // Si la respuesta contiene 'storage' significa que trae el pdf
                if (result.message.includes('storage')) {
                    // Extraer solo el enlace del PDF usando una expresión regular
                    const pdfLinkMatch = result.message.match(/https:\/\/storage\.googleapis\.com\/[^\s]+/);
                    const pdfLink = pdfLinkMatch ? pdfLinkMatch[0] : null;

                    if (pdfLink) {
                        setPdfUrl(pdfLink);
                        setHasZapping(true);
                        // console.log('PDF URL:', pdfLink);
                    }
                    // Si la respuesta contiene 'wa.link' significa que debe hacer upgrade de su plan
                } else if (result.message.includes('wa.link')) {
                    // Extraer el enlace usando una expresión regular
                    const linkMatch = result.message.match(/https:\/\/wa\.link\/\w+/);
                    const waLink = linkMatch ? linkMatch[0] : null;
                    setLinkWhatsapp(waLink)

                    setErrorMessage(result.message);
                    setHasZapping(false);

                    // console.log('WhatsApp Link:', waLink);
                } else {
                    setErrorMessage(result.message);
                    setHasZapping(false);
                    // console.log('result.message', result.message)
                }
            })
            .catch((error) => {
                console.error(error);
                setLoadingPdf(false);
                setErrorMessage('Error al obtener la licencia.');
            });
    }

    useEffect(() => {
        getPdfZapping()
        getStatusBuyZapping()
    }, [])

    const confirComprarFunction = () => {
        setOpenSuccessBuy(true)
        setOpenConfirmBuy(false)
    }

    const viewTermsModalFunction = () => {
        setOpenTerms(true)
        setOpenConfirmBuy(false)
    }

    const closeTermsModalFunction = () => {
        setCheckedTerms(true);
        setOpenConfirmBuy(true)
        setOpenTerms(false)
    }

    const sendDataBuyZApping = () => {
        setLoadingButtonBuy(true)
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "nombre": userInfo.nombre,
            "contractNumbers": userInfo.NUM_CONTRATO,
            "cedula": userInfo.cedula
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://us-central1-kaspersky-license.cloudfunctions.net/api/comprar_zapping_datos", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(result)
                setLoadingButtonBuy(false)
                confirComprarFunction()
            })
            .catch((error) => {
                console.error(error)
                setLoadingButtonBuy(false)
                setOpenConfirmBuy(false)
                Swal.fire({
                    icon: 'error',
                    text: 'Ha ocurrido un error, intentelo nuevamente dentro de un momento',
                })
            });
    }

    const getStatusBuyZapping = () => {
        setLoadingStatusBuyZapping(true)
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "contractNumbers": userInfo.NUM_CONTRATO
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://us-central1-kaspersky-license.cloudfunctions.net/api/getZappingDatos", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(result)
                const dataParse = JSON.parse(result);
                console.log('dataParse.message', dataParse.message)
                if (dataParse.message && typeof dataParse.message === 'object' && Object.keys(dataParse.message).length > 0) {
                    console.log('No vacio')
                    setStatusBuyZapping('No vacio')
                    setLoadingStatusBuyZapping(false)
                } else {
                    console.log('Vacio')
                    setStatusBuyZapping('Vacio')
                    setLoadingStatusBuyZapping(false)
                }
                // if(dataParse.message)
                // setStatusBuyZapping(dataParse.)
            })
            .catch((error) => {
                setLoadingStatusBuyZapping(false)
                console.error(error)
            });
    }

    const closeModalConfirmBuyZapping = () => {
        setOpenConfirmBuy(false)
    }

    const formatPayCurrency = (value) => {
        return `$${value.toFixed(2)}`;
    };

    return (
        <>
            <Dialog open={isLoading}>
                <DialogContent style={{ textAlign: 'center' }}>
                    <CircularProgress color="primary" style={{ marginBottom: '16px' }} />
                    <Typography variant="subtitle1">Por favor, espere...</Typography>
                </DialogContent>
            </Dialog>
            <Grid
                container
                component="main"
                alignItems="center"
                justifyContent="center"
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                >
                    <Box style={{ backgroundSize: '100%' }}>
                        <Container component="Box" maxWidth="lg">
                            <Typography
                                variant='h4'
                                component='h1'
                            >
                                <span style={{
                                    background: 'linear-gradient(90deg, #E4547E, #EEDC80)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    display: 'inline-block'
                                }}>
                                    <strong>COMPRAR ZAPPING</strong>
                                </span>
                            </Typography>
                            <Box py={1}></Box>
                            <Card>
                                {loadingPdf ? <LinearProgress color="warning" /> : ''}
                                <CardContent style={{ padding: "0px" }}>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6} style={{ backgroundColor: "black" }}>
                                            <Box px={2} py={2}>
                                                <Typography variant="h6" gutterBottom style={{ "marginTop": "4px", "color": "white" }}>
                                                    ¿QUE ES ZAPPING?
                                                </Typography>

                                                <Typography variant="subtitle1" gutterBottom style={{ "color": "grey" }}>
                                                    Es la plataforma <strong>STREAMING</strong> que permite reproducir contenido de internet. A traves de esta plataforma podras ver todos los partidos de la Liga Pro y mas de 20 canales adicionales.
                                                    <br />5 Dispositivos simultaneos.
                                                    <br />2 Ubicaciones distintas (2 direcciones IP* distintas)
                                                </Typography>
                                                <Box pt={1}>
                                                    <img src={bannerZapping} width="100%" style={{ borderRadius: '8px' }} />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {tab === true ?
                                                <Alert severity="success">Se ha actualizado la informacion con exito</Alert>
                                                :
                                                <Box py={2} pr={3}>

                                                    {/* <Typography
                                                        variant='h4'
                                                        component='h1'
                                                    >
                                                        <span style={{
                                                            background: 'linear-gradient(90deg, #E4547E, #EEDC80)',
                                                            WebkitBackgroundClip: 'text',
                                                            WebkitTextFillColor: 'transparent',
                                                            display: 'inline-block'
                                                        }}>
                                                            <strong>COMPRAR ZAPPING</strong>
                                                        </span>
                                                    </Typography> */}

                                                    <Typography
                                                        variant='h4'
                                                        component='h1'
                                                    >

                                                        <strong>DATOS PERSONALES</strong>

                                                    </Typography>

                                                    {/* <Typography variant='h5' component='h1'><strong>Actualización de datos</strong></Typography> */}
                                                    <Box py={5}>
                                                        <TextField
                                                            fullWidth
                                                            type='number'
                                                            value={cedula}
                                                            label="CÉDULA | RUC"
                                                        />
                                                    </Box>
                                                    {
                                                        contractArray && cedula.length >= 10 &&
                                                        <>
                                                            <Box pb={5}>
                                                                {/* <FormControl fullWidth disabled={!contractArray}>
                                                        <InputLabel >LINEA DE CONTRATO</InputLabel>
                                                        <Select
                                                            value={userInfo.NUM_CONTRATO}
                                                            label="LINEA DE CONTRATO"
                                                            onChange={(e) => { handleContrato(e.target.value) }}
                                                        >

                                                            {contractArray && contractArray.map((item, index) => (
                                                                <MenuItem key={index} value={item.NUM_CONTRATO}>{item.NUM_CONTRATO} - <strong>{item.estado}</strong></MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl> */}
                                                                <TextField
                                                                    fullWidth
                                                                    value={userInfo.NUM_CONTRATO}
                                                                    label="LINEA DE CONTRATO"
                                                                />
                                                            </Box>
                                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        value={userInfo.nombre}
                                                                        variant='outlined'
                                                                        label="NOMBRE"
                                                                        disabled
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box pt={5}></Box>
                                                                    <TextField
                                                                        label='VALOR ZAPPING'
                                                                        value={formatPayCurrency(4.99)}
                                                                        name="DEUDA"
                                                                        fullWidth
                                                                        InputProps={{
                                                                            readOnly: true, // Opcional: si no quieres que el usuario edite el valor
                                                                        }}
                                                                    />
                                                                    <p style={{ fontSize: "10px", paddingBottom: "0px", marginBottom: "0px" }}>*Precio final incluido incpuestos</p>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }

                                                    <Box display="flex" pt={5} flexDirection="row-reverse">
                                                        {
                                                            loadingPdf ? (
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                >
                                                                    <CircularProgress color="warning" />
                                                                </Grid>
                                                            ) : (
                                                                <>
                                                                    {pdfUrl ? (
                                                                        <>
                                                                            <Button
                                                                                //onClick={() => Swal.fire({ icon: 'info', text: 'Nos encontramos en actualizacion de nuestro boton de pago para darte una mejor experiencia. Continua disfrutando de nuestros demas servicios.'})}
                                                                                onClick={() => /* setOpenTerms(true) */handleSubmit()}
                                                                                color='success'
                                                                                fullWidth
                                                                                variant='contained'
                                                                                // disabled={!userInfo || !deuda || parseFloat(deuda) === 0}
                                                                                disabled
                                                                            >USTED YA CUENTA CON EL SERVICIO ZAPPING</Button>
                                                                        </>
                                                                    ) : (
                                                                        <>

                                                                            {
                                                                                linkWhatsapp !== '' ? '' : <>
                                                                                    <Typography variant="body2" color="error">
                                                                                        {errorMessage} {errorMessage.includes('Error') ? 'Por favor intentelo nuevamente dentro de un momento' : ''}
                                                                                    </Typography>
                                                                                </>
                                                                            }

                                                                            {
                                                                                linkWhatsapp !== '' ? <>

                                                                                    <LoadingButton
                                                                                        fullWidth
                                                                                        color="warning"
                                                                                        variant='contained'
                                                                                        size='small'
                                                                                        loading={loadingStatusBuyZapping}
                                                                                        endIcon={loadingStatusBuyZapping === true ? <SendIcon /> : ''}
                                                                                        disabled={loadingStatusBuyZapping === true || statusBuyZapping === "No vacio"}
                                                                                        loadingPosition="end"
                                                                                        onClick={() => setOpenConfirmBuy(true)/* getStatusBuyZapping() *//* sendDataBuyZApping() */}>
                                                                                        {
                                                                                            statusBuyZapping === "" ? "VERIFICANDO" : statusBuyZapping === "Vacio" ? "COMPRAR" : statusBuyZapping === "No vacio" ? "USTED YA CUENTA CON ZAPPING" : "USTED YA CUENTA CON ZAPPING"
                                                                                        }
                                                                                    </LoadingButton>


                                                                                </> : ''
                                                                            }


                                                                        </>)}
                                                                </>
                                                            )
                                                        }
                                                    </Box>

                                                </Box>}
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>

                            <Box py={1}></Box>

                            {/* <Checkbox disabled={loadingPdf === true} checked={checkedTerms}
                                onChange={handleChangeCheckbox} />

                            <Button color='secondary' sx={{
                                boxShadow: 'none',
                                textDecoration: 'underline',
                            }} onClick={() => setOpenTerms(true)}>
                                Aceptar Términos y Condiciones
                            </Button> */}

                        </Container>
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Encuesta de satisfacción</DialogTitle>
                <DialogContent>
                    <form>
                        <FormControl component="fieldset">
                            <p>1. En una escala del 1 al 5, ¿Qué nivel de esfuerzo le supuso navegar a través del portal 24 en línea para resolver su requerimiento?</p>
                            <RadioGroup row aria-label="esfuerzo" name="esfuerzo" value={esfuerzo} onChange={(e) => setEsfuerzo(e.target.value)}>
                                {[1, 2, 3, 4, 5].map(valor => (
                                    <FormControlLabel key={valor} value={valor.toString()} control={<Radio />} label={valor.toString()} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        <FormControl component="fieldset">
                            <p>2. En una escala del 1 al 5, ¿Cómo califica su nivel de satisfacción con los servicios que ofrece el portal 24 en línea?</p>
                            <RadioGroup row aria-label="satisfaccion" name="satisfaccion" value={satisfaccion} onChange={(e) => setSatisfaccion(e.target.value)}>
                                {[1, 2, 3, 4, 5].map(valor => (
                                    <FormControlLabel key={valor} value={valor.toString()} control={<Radio />} label={valor.toString()} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        <FormControl component="fieldset">
                            <p>3. En una escala del 0 al 10, ¿Qué tan probable es que recomiende los servicios de Yiga5 a un amigo o familiar?</p>
                            <RadioGroup row aria-label="recomendacion" name="recomendacion" value={recomendacion} onChange={(e) => setRecomendacion(e.target.value)}>
                                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(valor => (
                                    <FormControlLabel key={valor} value={valor.toString()} control={<Radio />} label={valor.toString()} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleEnviarEncuesta}>Enviar</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openTerms} onClose={handleCloseTerms}>
                <DialogTitle>TÉRMINOS Y CONDICIONES ZAPPING</DialogTitle>
                <DialogContent>
                    {/* <Typography variant="h6" gutterBottom>
                        TERMINOS Y CONDICIONES ZAPPING
                    </Typography> */}
                    <Typography variant="subtitle1" gutterBottom>
                        “Zapping” es una plataforma de entretenimiento OTT (Over-the-top) que incluye la reproducción de contenido multimedia vía Streaming.
                        La tecnología de streaming permite ver vídeos, escuchar música e incluso presenciar acontecimientos en directo desde su computadora
                        de escritorio (PC), computadora portátil, teléfono inteligente (smartphone), tableta o televisión inteligente (Smart TV) conectados a
                        Internet.
                        El producto “ZAPPING” es brindado por INTERCOMMERCE S.A. en adelante el “PRESTADOR” a Usted, como usuario en adelante “CLIENTE”
                        bajo los términos y condiciones previstos en el presente contrato y/o adendum. Al ingresar y usar este servicio en el aplicativo móvil o
                        página web, el “CLIENTE” expresa su voluntad y acepta los términos y condiciones establecidos por el “PRESTADOR”.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        TRATAMIENTO Y PROTECCIÓN DE LOS DATOS PERSONALES.
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        La contratación de la plataforma Zapping más los planes del servicio de internet, significará la aceptación de estos términos y condiciones,
                        que incluye la recopilación de los datos personales, identificativos, contacto, navegación, tracking, entre otros con la finalidad de prestar
                        los servicios de contenidos de la plataforma de Zapping, que dispondrá de los contenidos de Zapping Sports, más los contenidos nacionales
                        e internacionales.
                        La base legal para el tratamiento de los datos personales de EL CLENTE es el consentimiento otorgado por los mismos al aceptar los
                        presentes Términos y Condiciones de Uso y el contrato suscrito y/o adendum al contrato y de la Política de Privacidad de INTERCOMMERCE
                        S.A.
                        INTERCOMMERCE S.A. en corresponsabilidad con la plataforma zapping, implementarán las medidas organizativas, técnicas y jurídicas
                        para precautelar la privacidad de sus datos personales, así como de garantizar el pleno ejercicio de sus derechos como titular de datos
                        personales, los cuales estarán disponible en nuestro sitio web: https://www.yiga5.com en la sección información legal / política de
                        privacidad.
                        INTERCOMMERCE S.A. en corresponsabilidad con la plataforma zapping adoptarán las medidas de seguridad necesarias para garantizar la
                        confidencialidad, integridad y disponibilidad de los datos personales de EL CLIENTE, de acuerdo con la normativa aplicable en materia de
                        protección de datos. Los datos personales de EL CLIENTE serán conservados durante el tiempo necesario para cumplir con las finalidades
                        para las que fueron recopilados y para cumplir con las obligaciones legales aplicables.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        CARACTERISTÍCAS DEL PRODUCTO.
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        El cliente tendrá acceso a la plataforma de entretenimiento OTT mediante la aplicación “Zapping”.
                        La plataforma le permite visualizar los partidos de la LIGA PRO BETCRIS y contenidos nacionales e internacionales.
                        Zapping es compatible con los siguientes dispositivos:
                        Televisores: LG / Samsung / y con sistema operativo Android TV. Todos modelos 2016 en adelante
                        Dispositivos externos de entretenimiento: AndroidTv / Chromecast/ Amazon Fire TV / IoS.
                        Equipos terminales: (pc – laptop / Tablet / Smart Tv /teléfono móvil) con sistema operativo Android / IoS
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        DISPOSITIVOS PERMITIDOS
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        El número máximo de equipos o dispositivos permitidos por cada cliente, que pueden acceder al aplicativo móvil “Zapping” es 5, los cuales
                        pueden ser del tipo: teléfonos inteligentes (smartphones), televisiones inteligentes (Smart TV), tabletas o computadoras de escritorio (PC).
                        Los contenidos podrán reproducirse de manera simultánea hasta en 5 dispositivos a la vez en 2 ubicaciones IP distintas (La distribución
                        será de la siguiente manera: 4 dispositivo ubicación 1, 1 dispositivo ubicación 2). En caso de que el cliente desee registrar un dispositivo
                        adicional a los 5 dispositivos registrados inicialmente, primero tendrá que dar de baja uno de éstos en el dashboard de control propio de
                        la plataforma.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        PRECIO DEL SERVICIO
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        El servicio Zapping estará activo para los clientes nuevos que contraten un plan de internet (de acuerdo a promoción vigente) y para los
                        clientes actuales que realicen mejoras en su plan, SIN COSTO hasta el 31 de diciembre. A partir del 01 de enero del 2025, el uso del servicio
                        tendrá un recargo en la facturación de $4,33 + impuestos.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        CONTRATACION Y FACTURACION
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        La contratación de Zapping + planes de internet estará disponible en todos los canales de venta físicos y digitales de INTERCOMMERCE
                        S.A. a nivel nacional.
                        ZAPPING incluye sin limitación la aceptación del Acuerdo de Suscripción y Política de Privacidad, además de la creación y activación de su
                        cuenta según corresponda.
                        Zapping se adicionará como un servicio dentro de nuestros planes de internet actuales. INTERCOMMERCE S.A., se reserva el derecho de
                        seleccionar los planes comerciales que incluirán el servicio.
                        La contratación del plan de internet (incluyendo zapping) está sujeto a un tiempo de permanencia mínima de 24 facturas pagadas, en caso
                        de cancelación anticipada se cobrarán los valores no devengados de las promociones por el servicio de internet.
                        La activación de Zapping para clientes que ya cuentan con un plan de internet, está sujeto a una mejora de su plan.
                        La contratación exclusiva del servicio Zapping para clientes INTERCOMMERCE S.A, se podrá realizar por medio del portal
                        www.24enlinea.com y la app Yiga Experience.
                        El cliente recibe el proporcional sin costo desde el momento de la compra hasta su ciclo de facturación. Una vez asignada la licencia de
                        Zapping el servicio será facturado.
                        La entrega de este servicio no incluye la configuración del mismo en ningún dispositivo. Es responsabilidad del cliente: descargar el
                        aplicativo, instalarlo y configurarlo en sus dispositivos, con las credenciales o código recibido por parte de INTERCOMMERCE S.A. En caso
                        de presentar inconvenientes, el cliente podrá solicitar el soporte técnico remoto llamando al teléfono 045010056.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        CANCELACION O SUSPENSION DEL SERVICIO.
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        El cliente puede realizar la cancelación de su suscripción a través del call center o centros de atención al cliente.
                        En caso de cancelación o downgrade del plan contratado (Plan de Internet + Zapping) el cliente perderá todas las promociones adquiridas
                        al momento de la suscripción.
                        El cliente puede realizar la cancelación de su servicio, llamando al Call Center o asistiendo a los Centros de Atención al Cliente.
                        En caso de suspensión por morosidad en su servicio de internet, el rubro por concepto de Zapping se seguirá facturando por el valor de
                        $4,33 +imp, por los siguientes 3 meses. Posterior a este tiempo, si el cliente no ha cancelado su deuda (internet + zapping) se procederá
                        con la eliminación de la licencia.
                        En caso de que el cliente sea beneficiario de una promoción o descuento en el producto “ZAPPING”, el cliente entiende que el
                        incumplimiento de las condiciones establecidas para recibir la promoción o descuento resultarán en el cobro de las tarifas regulares de los
                        beneficios entregados por INTERCOMMERCE S.A., sin opción a reclamo.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        ENTREGA DE CUPONES, CREDENCIALES Y SOPORTE.
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        Para acceder a “Zapping”, el cliente recibirá un cupón que le permitirá crear sus credenciales de acceso: usuario y contraseña en la
                        plataforma. El cliente podrá obtener este cupón mediante el chatbot, registrando la opción Zapping, ingresando su # de cedula y
                        seleccionando su linea activa, hasta 7 dias después de su contratación o activación del servicio de internet.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        CONTENIDO
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        El dueño del contenido (ZAPPING) no promueve, aprueba o permite que se copien partidos entregados digitalmente, ni otras actividades
                        delictivas. Usted no puede, ya sea directamente o con el uso de dispositivos, software, sitios de Internet, servicios basados en web, u otros
                        medios: (i) retirar, alterar, eludir, evitar, o interferir avisos de derechos de autor, marcas u otro tipo de aviso de propiedad insertados en
                        el Contenido, o mecanismos de administración de derechos digitales, dispositivos u otras protecciones de contenido o medidas de control
                        de acceso del Contenido, incluyendo mecanismos de filtración geográfica; ni (ii) copiar, descargar, modificar, distribuir, transmitir, mostrar,
                        efectuar, reproducir , exhibir, duplicar, publicar, licenciar, crear obras derivadas u ofrecer para venta el Contenido u otra información
                        contenida u obtenida de o a través del Servicio, sin nuestro consentimiento expreso por escrito. No puede incorporar el Contenido, ni
                        hacer streaming o retransmitirlo, en aplicaciones de hardware o de software, ni ponerlo a disposición a través de enmarcados o vínculos
                        en línea. Tampoco puede crear un negocio utilizando el Contenido, ya sea con fines de lucro o no. El Contenido al que aplican estas
                        restricciones incluye, entre otros, textos, gráficos, configuraciones, interfaces, logotipos, fotografías, materiales de audio y video y
                        fonogramas. Asimismo, se le prohíbe estrictamente crear obras derivadas o materiales que provengan o se basen en cualquier forma en
                        el Contenido, incluyendo montajes, mezclas de música y videos similares, fondos de escritorio, protector de pantalla, tarjetas de saludos
                        y mercancías.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        ACCESO
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        Por medio del presente, le otorgamos un acceso, no exclusivo, intransferible y limitado, para acceder al Servicio, vía streaming en línea
                        por Internet a través del Reproductor de Video, para fines personales, no comerciales, como se establece en las Condiciones de Uso.
                        Excepto por la licencia limitada referida en este párrafo, no se le transfieren derechos de propiedad o titularidad a usted.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        CAMBIOS EN LOS TÉRMINOS Y CONDICIONES.
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        INTERCOMMERCE S.A. se reserva el derecho a modificar estos Términos y Condiciones a su entera discreción y en cualquier momento, de
                        acuerdo con necesidades corporativas; en tal sentido, el CLIENTE acepta, autoriza y brinda su consentimiento a INTERCOMMERCE S.A. S.A.
                        para administrar, sistematizar, procesar y archivar sus datos durante el tiempo de vigencia del contrato suscrito con INTERCOMMERCE
                        S.A. y del producto denominado “Zapping” y demás procesos. El cliente podrá conocer cualquier modificación en estos Términos y
                        Condiciones a través de la publicación que INTERCOMMERCE S.A. realizará en su página web https://www.yiga5.com en la sección
                        Información Legal / Términos y Condiciones; así mismo, el cliente podrá usar el producto luego de efectuado cualquier cambio que
                        constituye aceptación expresa por parte del cliente conforme los nuevos Términos y Condiciones. INTERCOMMERCE S.A. podrá emplear
                        medios electrónicos, en todas las transacciones comerciales generadas, procesadas y aceptadas a través de llamadas telefónicas y sus
                        modalidades similares digitales que gozan de igual valor que aquellas generadas personalmente como acuerdo de voluntades autorizadas
                        por el titular.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        APLICACIÓN Y ACEPTACION DE LOS PRODCUTOS.
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        INTERCOMMERCE S.A. en corresponsabilidad con la plataforma ZAPPING se reservan el derecho, con o sin aviso previo de: 1) cambiar las
                        descripciones, imágenes y referencias relativas a productos y características; 2) limitar la cantidad disponible de cualquier producto; 3)
                        aceptar o imponer condiciones sobre la aceptación de cualquier cupón, código de cupón, código promocional o cualquier otra promoción
                        similar; 4) prohibir a cualquier usuario a realizar cualquiera o todas las transacciones; y/o 5) negarnos a brindarle a cualquier interesado
                        algún producto. El precio y la disponibilidad del producto “Zapping” están sujetos a cambio sin aviso.
                        Al ingresar y usar este sitio Web el “CLIENTE” expresa su voluntad y acepta los términos y condiciones establecidos pulsando sobre el
                        recuadro “ACEPTO”, si el “CLIENTE” NO está de acuerdo con estos términos y condiciones no acceda o haga uso de este sitio, por cuanto
                        se entiende que existe un acuerdo en común entre las partes tanto del “PRESTADOR” como por parte del “CLIENTE”
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button onClick={/* handleCloseTerms */() => {
                        closeTermsModalFunction()
                    }}>Aceptar</Button>
                    {/* <Button onClick={() => {
                        setOpenTerms(false)
                        handleSubmit()
                    }} color='secondary'>Aceptar</Button> */}
                </DialogActions>
            </Dialog>

            {/* Modal finalizacion compra */}
            <Dialog open={openSuccessBuy}>
                <DialogTitle sx={{ backgroundColor: 'whitesmoke' }}>Gracias por su compra.</DialogTitle>
                <DialogContent dividers>
                    <Box py={1}>
                        <Typography variant="subtitle1" gutterBottom>
                            Dentro de aproximadamente 15 minutos luego de haber realizado la compra podra descargar su licencia en la pagina principal.
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: 'whitesmoke' }}>
                    <Button onClick={() => navigate('/')/* handleCloseSuccessBuy */} color='success'>Ir a la pantalla principal</Button>
                </DialogActions>
            </Dialog>

            {/* Modal confirmar compra */}
            <Dialog open={openConfirmBuy} onClose={closeModalConfirmBuyZapping}>
                <DialogTitle sx={{ backgroundColor: 'whitesmoke' }}>Confirmación de compra</DialogTitle>
                <DialogContent dividers>
                    <Box py={1}>
                        <Typography variant="subtitle1" gutterBottom>
                            Al contratar el paquete Zapping, el costo del servicio se reflejará en su próxima factura. Por favor confirma tu compra.
                        </Typography>

                        <Checkbox /* disabled={loadingPdf === true} */ checked={checkedTerms}
                            onChange={handleChangeCheckbox} />

                        {/* <Typography variant="subtitle1" gutterBottom>
                                Terminos y Condiciones
                            </Typography> */}

                        <Button color='secondary' sx={{
                            boxShadow: 'none', // Deshabilita la sombra
                            textDecoration: 'underline',
                        }} onClick={() => viewTermsModalFunction()/* handleSubmit() */}>
                            Aceptar Términos y Condiciones
                        </Button>

                    </Box>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: 'whitesmoke' }}>
                    <LoadingButton
                        color='success'
                        size='small'
                        loading={loadingButtonBuy}
                        endIcon={loadingButtonBuy === true ? <SendIcon /> : ''}
                        disabled={checkedTerms === false}
                        loadingPosition="end"
                        onClick={() => /* getStatusBuyZapping() */sendDataBuyZApping()}>
                        {
                            loadingButtonBuy === false ? "Confirmar comprar" : "Confirmando compra"
                        }
                    </LoadingButton>
                    {/* <Button onClick={() => sendDataBuyZApping()} color='success'>Confirmar comprar</Button> */}
                </DialogActions>
            </Dialog>

        </>
    )
}