import * as React from 'react';
import {
    Box, Typography, Grid, Button, Paper, CircularProgress, Chip, LinearProgress
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import zappingImage2 from './../../assets/img/zapping_image2.png'
import zappingImage3 from './../../assets/img/zapping_image3.png'
import { BuyButtonZapping2 } from '../servicios/zapping/ComprarZapping/BuyButtonZapping2';

export const ZappingPage = () => {

    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    const [hasZapping, setHasZapping] = useState(false)
    const [pdfUrl, setPdfUrl] = useState(null)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [showPdf, setShowPdf] = useState(false);

    const [linkWhatsapp, setLinkWhatsapp] = useState('')

    const getPdfZapping = () => {

        // console.log('userInfo', userInfo)

        setLoading(true);

        const numContrato = userInfo.NUM_CONTRATO;

        // expresión regular para obtener el numero de contrato
        const onlyNumeroContrato = numContrato.match(/\d+/)[0];

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "contract_numbers": userInfo.NUM_CONTRATO,
            "dni": userInfo.cedula
        });
        console.log({
            "contract_numbers": userInfo.NUM_CONTRATO,
            "dni": userInfo.cedula
        })
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://dataguardian.intercommerce.com.ec/funcion/license_code_zapping/", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log('result', result)
                setLoading(false);
                // Si la respuesta contiene 'storage' significa que trae el pdf
                if (result.message.includes('storage')) {
                    // Extraer solo el enlace del PDF usando una expresión regular
                    const pdfLinkMatch = result.message.match(/https:\/\/storage\.googleapis\.com\/[^\s]+/);
                    const pdfLink = pdfLinkMatch ? pdfLinkMatch[0] : null;

                    if (pdfLink) {
                        setPdfUrl(pdfLink);
                        setHasZapping(true);
                        // console.log('PDF URL:', pdfLink);
                    }
                    // Si la respuesta contiene 'wa.link' significa que debe hacer upgrade de su plan
                } else if (result.message.includes('wa.link')) {
                    // Extraer el enlace usando una expresión regular
                    const linkMatch = result.message.match(/https:\/\/wa\.link\/\w+/);
                    const waLink = linkMatch ? linkMatch[0] : null;
                    setLinkWhatsapp(waLink)

                    setErrorMessage(result.message);
                    setHasZapping(false);

                    // console.log('WhatsApp Link:', waLink);
                } else {
                    setErrorMessage(result.message);
                    setHasZapping(false);
                    // console.log('result.message', result.message)
                }
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setErrorMessage('Error al obtener la licencia.');
            });
    }

    const openWhatsappButton = () => {
        window.open(linkWhatsapp, '_blank');
    };

    useEffect(() => {
        getPdfZapping()
    }, [])

    const handleDownloadPdf = () => {
        fetch(pdfUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `licencia_zapping_${userInfo.nombre}.pdf`); // Nombre del archivo
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(err => console.error('Error al descargar el PDF', err));
    };


    return (
        <>
            <Typography variant="h3" component="h1"><strong>Zapping</strong>{/* <TvIcon style={{ color: '#00B2FF', marginLeft: '10px', width: '30px', height: '30px' }} /> */}</Typography>

            <Box pt={2}>
                {loading ? <LinearProgress color="warning" /> : ''}
                <Paper elevation={2}>
                    <Box py={3} px={3}>
                        <Chip
                            label={
                                <span>
                                    ZAPPING CONTRATADO: {' '}
                                    <span style={{ color: hasZapping === false ? 'red' : 'green', fontWeight: 'bold' }}>
                                        {hasZapping === false ? 'NO' : 'SI'}
                                    </span>
                                </span>
                            }
                            variant="outlined"
                            sx={{
                                borderColor: hasZapping === false ? 'red' : 'green', // Borde según el estado
                            }}
                        />

                        <Box py={1}></Box>

                        <Typography variant="h6" gutterBottom>
                            <span style={{
                                background: 'linear-gradient(90deg, #E4547E, #EEDC80)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                display: 'inline-block'
                            }}>
                                <strong>¿QUE ES ZAPPING?</strong>
                            </span>
                        </Typography>

                        <Typography variant="h6" gutterBottom /* style={{ "color": "#505050" }} */>
                            Es la plataforma <strong>STREAMING</strong> que permite reproducir contenido de internet. A traves de esta plataforma podras ver todos los partidos de la Liga Pro y mas de 20 canales adicionales.
                        </Typography>

                        <Box pb={1}></Box>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={6}>
                                <img src={zappingImage2} width="90%" style={{ borderRadius: '8px' }} />
                            </Grid>

                            <Grid item xs={6}>
                                <img src={zappingImage3} width="90%" style={{ borderRadius: '8px' }} />
                            </Grid>

                        </Grid>

                        <Box py={1}></Box>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" gutterBottom style={{ "margin": "0px", "color": "grey" }}>
                                    Nombre
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    {userInfo.nombre}
                                </Typography>

                                <Box pt={1}></Box>

                                <Typography variant="subtitle2" gutterBottom style={{ "margin": "0px", "color": "grey" }}>
                                    Cedula
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    {userInfo.cedula}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" gutterBottom style={{ "margin": "0px", "color": "grey" }}>
                                    Numero Contrato
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    {userInfo.NUM_CONTRATO}
                                </Typography>

                                <Box pt={1}></Box>

                                <Typography variant="subtitle2" gutterBottom style={{ "margin": "0px", "color": "grey" }}>
                                    Correo
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    {userInfo.email}
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* <Lottie animationData={tvZapping} loop={true} style={{ width: '300px', height: '300px' }} /> */}

                        <Box py={1}></Box>

                        {/* {loading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                {pdfUrl ? (
                                    <>
                                        <Box py={1}></Box>
                                        <Button
                                            fullWidth
                                            onClick={() => window.open(pdfUrl, '_blank')}
                                        >
                                            Ver PDF
                                        </Button>
                                        <Box pb={1}></Box>
                                        <Button
                                            fullWidth
                                            download="zapping.pdf"
                                            href={pdfUrl}
                                        >
                                            Descargar PDF
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="body2" color="error">
                                            {errorMessage}
                                        </Typography>
                                        {
                                            linkWhatsapp !== '' ? <>
                                                <Box py={1}></Box>
                                                <Button
                                                    fullWidth
                                                    onClick={openWhatsappButton}
                                                    color='secondary'
                                                >
                                                    Abrir Link
                                                </Button>
                                            </> : ''
                                        }
                                    </>
                                )}
                            </>
                        )} */}

                        {loading ? (
                            <CircularProgress color="warning" />
                        ) : (
                            <>
                                {pdfUrl ? (
                                    <>
                                        <Box py={1}></Box>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    onClick={() => setShowPdf(true)}
                                                >
                                                    Ver Licencia Zapping
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    onClick={handleDownloadPdf}
                                                    color="success"
                                                >
                                                    Descargar Licencia Zapping
                                                </Button>
                                            </Grid>
                                        </Grid>




                                        {/* Mostrar el PDF en la misma página */}
                                        {showPdf && (
                                            <Box py={2}>
                                                <iframe
                                                    src={pdfUrl}
                                                    width="100%"
                                                    height="600px"
                                                    style={{ border: 'none' }}
                                                />
                                                <Box py={1}></Box>
                                                <Button
                                                    fullWidth
                                                    onClick={() => setShowPdf(false)}
                                                    color="error"
                                                >
                                                    Cerrar PDF
                                                </Button>
                                            </Box>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="body2" color="error">
                                            {errorMessage}
                                        </Typography>
                                        {
                                            linkWhatsapp !== '' ? <>
                                                <Box py={1}></Box>
                                                <Button
                                                    fullWidth
                                                    onClick={openWhatsappButton}
                                                    color='secondary'
                                                >
                                                    Abrir Link
                                                </Button>
                                                {/* <BuyButtonZapping2 /> */}
                                            </> : ''
                                        }
                                    </>
                                )}
                            </>
                        )}



                    </Box>
                </Paper>
            </Box>
        </>
    );
}