import { Container, Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import img1 from "../../assets/img/microservicio/PARTE1.png"
import img2 from "../../assets/img/microservicio/PARTE2.png"
import img3 from "../../assets/img/microservicio/PARTE3.png"
import img4 from "../../assets/img/microservicio/PARTE4.png"
import img5 from "../../assets/img/microservicio/PARTE5.png"
import img6 from "../../assets/img/microservicio/PARTE7.png"
import img7 from "../../assets/img/microservicio/PARTE8.jpg"
import img8 from "../../assets/img/microservicio/PARTE10.jpg"
import img9 from "../../assets/img/microservicio/PARTE11.jpg"
import img10 from "../../assets/img/microservicio/PARTE12.jpg"
import img11 from "../../assets/img/microservicio/PARTE13.jpg"
import img12 from "../../assets/img/microservicio/PARTE14.jpg"
import img13 from "../../assets/img/microservicio/PARTE9.jpg"
export const MicrositioPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <Container >
            <img src={img1} width='100%'  loading="lazy" />
            <img src={img2} width='100%'  loading="lazy"/>
            <img src={img3} width='100%' loading="lazy" />
            <img src={img4} width='100%' loading="lazy"/>
            <img src={img5} width='100%' loading="lazy"/>
            <img src={img6} width='100%'loading="lazy"/>
       
       
            <img src={img13} width='100%' loading="lazy"/>
            <Box sx={{ flexDirection: 'row' }}>
            <img src={img7} width='100%' loading="lazy"/>
                <a href='https://www.facebook.com/yiga5internetseguro'>
                    <img src={img8} width='100%' loading="lazy"/>
                </a>
                <a href='https://www.instagram.com/yiga5.ec/'>
                    <img src={img9} width='100%' loading="lazy"/>
                </a>
                <a href='https://www.tiktok.com/@yiga5.ec'>
                    <img src={img10} width='100%' loading="lazy"/>
                </a>
                <a href='https://www.youtube.com/@Yiga5'>
                    <img src={img11} width='100%' loading="lazy"/>
                </a>
                <a href='https://yiga5.com'>
                    <img src={img12} width='100%'loading="lazy" />
                </a>
            </Box>
        </Container>
    )
}