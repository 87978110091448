import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Box, Card, CardContent, Chip, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { authState, getInvoice } from '../../redux/actions/userActions';
import {db} from '../../db/firebase'
function Estado({ estado }) {
    //let name;
    let color;
    if (estado === "SUSPENDIDO") {
        color = 'warning'
    } else if (estado === "ACTIVO") {
        color = 'success'
    }
    return (
        <Chip label={estado} color={color} variant="outlined" />
    )
}
export const SelectLinea = ({ props }) => {
    const dispatch = useDispatch()
    const getClient = useSelector(state => state.getClient)
    const { client } = getClient
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [linea, setLinea] = useState('')
    const [data, setData] = useState('')
    //
    const onSubmit = (e) => {
        setLinea(e)
        const p = client.filter((item) => item.NUM_CONTRATO === e)
        const index = client.findIndex(item => item.NUM_CONTRATO === e)
        //console.log(p)
        const data = p[0]
        const servicio = data.servicios[index]
        //console.log(index + 1, 'linea')
        dispatch(authState({
            ...userInfo,
            ...data,
            coins: 0,
            id_cliente: data.id,
            servicios: servicio,
            index_linea: index + 1
        }))
    }
    const onData = () => {
        setLinea(props.NUM_CONTRATO)
    }
    const fetchData = async () => {
        const querySnapshot = await db.collection(`users/${userInfo.uid}/yigapuntos/${userInfo.servicios.id}/historico`).get()
        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        setData(data)
        console.log('hola')
        console.log(data, 'hola')
        //
        await db.collection(`users/${userInfo.uid}/yigapuntos/`).doc(`${userInfo.servicios.id}`).onSnapshot((doc) => {
            let data = doc.data()
            //setPlan(data)
        })
    }
    useEffect(() => {
        onData()
        console.log(client, 'cliente')
    }, [onData])
    return (
        <>
            <Box pb={5}>
                <Card>
                    <CardContent>
                        <InputLabel>Selecciona tu linea de contrato</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={linea || ''}
                           
                            fullWidth
                            variant='outlined'
                            onChange={(e) => onSubmit(e.target.value)}
                        >
                            {client?.map((item, index) => (
                                <MenuItem key={index} value={item.NUM_CONTRATO}>
                                    <div style={{ width: 175, whiteSpace: 'nowrap' }}>
                                        {item.NUM_CONTRATO} -  <Estado estado={item.estado} />  - {item.direccion_principal} </div></MenuItem>
                            ))}
                        </Select>
                    </CardContent>
                </Card>
            </Box>

        </>
    )
}