import { Typography, Grid } from '@mui/material'
import React from 'react'
export const TermsPage = () => {
  return (
    <>
      <Grid
        container
        component="main"
        alignItems="center"
        justifyContent="center"

      >
        <Grid item xs={12} sm={8} md={12} lg={10} elevation={6}
        >
          <Typography variant='h3' component="h1"><strong>Terminos y condiciones</strong> </Typography>
        
          <p>1. OBJETIVO</p>
          <p>
          El programa YIGACOINS está diseñado para entregar beneficios a nuestros clientes del segmento hogar y pymes a través de una moneda digital que les permitirá canjear premios.</p>
          <p>
            2. CONDICIONES GENERALES</p>
          <p>
            2.1 Inscripciones </p>
          <p>
          •	Solo podrá ser beneficiarios del Programa YIGACOINS los clientes que tengan cuenta activa en los servicios hogar y pymes.
          </p>
          <p>
          •	Para acceder a los beneficios los usuarios de YIGA5 deberán descargar la aplicación YIGA EXPERIENCE disponible en Play Store y App Store; ingresar a la sección UNETE y registrar sus datos, o mediante la web www.24enlinea.com </p>
          <p>
          •	Una vez iniciada su sesión, deberá ir a la sección YIGACOINS y empezar a ganar Coins.
          </p>
          <p>
          2.2. Vigencia del programa </p>
          <p>
          YIGACOINS tendrá una vigencia indefinida. Sin embargo, YIGA5 se reserva el derecho de modificar, suspender, terminar y/o cancelar de manera parcial o total, individual o colectiva, en cualquier momento y con efectos hacia futuro, el programa de fidelización. Para este efecto YIGA5, informará a los clientes y aliados dicha circunstancia por los medios que considere más idóneos. La terminación del programa, bajo las circunstancias indicadas, en ningún caso dará lugar al pago de indemnización ni generará responsabilidades a cargo de YIGA5.
          </p>
          <p>
          2.3. Beneficiario</p>
          <p>
          Se consideran beneficiarios del Programa, aquellas personas que hayan aceptado y que cumplan con los presentes términos y condiciones.
          </p>
          <p>
          Los beneficios de YIGACOINS no son acumulables y se pueden redimir sin cantidad de restricciones, salvo en algunos casos que el beneficio tenga restricciones de uso por usuario.
          </p>
          <p>
          2.4.2. Vigencia de los beneficios
          </p>
          <p>
          La vigencia de los YigaCoins es por un año a partir de su asignación. 
          </p>
          <p>
          Yiga Coins podrá enviar comunicados y recordatorios a los clientes a través de correo electrónico, SMS, aplicaciones autorizadas o cualquier otro medio, para informar sobre el vencimiento de sus YigaCoins.
          </p>
          <p>
          Los YigaCoins que no sean canjeados durante su vigencia, perderán su validez al momento de realizar el canje. El cliente podrá revisar el vencimiento de sus YigaCoins a través de la plataforma Yiga Experience y/o www.24enlinea.com, en la sección YigaCoins.

          </p>
          <p>
          2.4.3. Beneficios
          </p>
          <p>
          YIGA5 publicará los beneficios o premios vigentes en la aplicación YIGA EXPERIENCE y en la web www.24enlinea.com, en la sección YIGACOINS.
          </p>
          <p>
          YIGA5 se reserva el derecho de modificar los beneficios o premios en cualquier momento sin contraer ninguna responsabilidad frente a los clientes, por lo que los clientes únicamente podrán redimir las YigaCoins que se encuentren disponibles en la plataforma durante su vigencia.
          </p>
          <p>2.5 Garantías</p>
          <p>
          La garantía de los productos físicos y/o servicios entregados se otorgará directamente por los fabricantes del producto entregado y en los tiempos establecidos por los mismos.
          </p>
          <p>
          2.6 Exoneraciones y responsabilidades
          </p>
          <p>
          YIGA5 podrá cambiar, modificar, adicionar, ajustar o eliminar cualquiera de los términos y condiciones de los presentes términos, para lo cual dará aviso al cliente a través de la aplicación YIGA EXPERIENCE y la web www.24enlinea.com. En caso de continuar vinculado al programa de fidelización, el beneficiario acepta expresamente las modificaciones introducidas.
          </p>
          <p>
          En caso de fallecimiento del cliente, se podrá trasladar los Yiga Coins adquiridos a la persona que asuma la responsabilidad del contrato.
          </p>
          <p>
          Cualquier daño o perjuicio que sufra el cliente por el uso de la plataforma o de la aplicación, cualquier pérdida que llegare a sufrir por tener acceso o por ejecutar operaciones a través del mismo, es responsabilidad exclusiva del cliente y por lo tanto el declara que mantendrá indemne y libre de toda responsabilidad a INTERCOMMERCE. S.A. Igualmente, el cliente releva de cualquier responsabilidad a INTERCOMMERCE. S.A. por los daños que llegare a sufrir el hardware y el software, o cualquier aparato electrónico a través del cual el beneficiario acceda a la plataforma o aplicación de Yiga Experience o www.24enlinea.com. En ningún caso INTERCOMMERCE. S.A. serán responsables por daños ni por las pérdidas o gastos que pudiesen surgir por conectarse a la plataforma o aplicación de Yiga Experience / www.24enlinea.com, por el uso que se haga del mismo, por la incapacidad para hacer uso del mismo por parte de cualquier persona, por cualquier falla en la ejecución, error, omisión, interrupción, defecto, demora en la transmisión de la información o en la información transmitida o en la conexión, por la existencia de un virus en la computadora o por fallas del sistema o en la línea, por su suspensión o terminación, aun cuando INTERCOMMERCE. S.A. o sus representantes se encuentren notificados sobre la posibilidad de tales daños, pérdidas o gastos. Los enlaces a la plataforma o aplicación de Yiga Experience o www.24enlinea.com, corren totalmente por cuenta del beneficiario. a conocer al cliente la(s) modificación (es) por cualquier medio idóneo incluyendo Internet o cualquier sistema electrónico de transmisión de datos.
          </p>
          <p>
          2.7 Comunicaciones
          </p>
          <p>Para aclaraciones sobre los beneficios o premios y funcionamiento de los YigaCoins, por favor revisar en la plataforma o en la App Yiga Experience o www.24enlinea, sección “Preguntas Frecuentes”.</p>
          <p>3. AUTORIZACIÓN PARA EL TRATAMIENTO DE LOS DATOS PERSONALES Y USO DE LA IMAGEN</p>
          <p>
          Al participar en la presente actividad, el participante otorga a INTERCOMMERCE. S.A. su consentimiento previo, expreso e informado para el tratamiento de sus datos personales, de conformidad con lo señalado en la Ley 1581 de 2012. Como titular de los datos provistos declara que ha sido informado que tiene los siguientes derechos:
          </p>
          <p>•	Acceder en forma gratuita a los datos proporcionados a YIGA5/ INTERCOMMERCE. S.A. que hayan sido objeto de tratamiento;
          </p>
          <p>
          •	Conocer, actualizar y rectificar su información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o a aquellos cuyo tratamiento está prohibido o no haya sido autorizado;
          </p>
          <p>•	Solicitar prueba de la autorización otorgada.
          </p>
          <p>
          •	Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente;
          </p>
          <p>
          •	Revocar la autorización y/o solicitar la supresión del dato.   </p>

          <p>
          •	Abstenerse de responder las preguntas sobre datos sensibles.
          </p>
          <p>Los datos personales suministrados serán incorporados a una base de datos con fines comerciales, publicitarios y de marketing. YIGA5 en la plataforma de Yiga Coins programa de fidelización de la marca, tiene como domicilio la AV. Narcisa de Jesus 6421 y Puente de la Joya. Parque de Negocios Nexus 1, oficina bodega #23., línea gratuita nacional 045010056. </p>
          <p>
          El tratamiento de datos personales por parte de YIGA5/ INTERCOMMERCE. S.A. se desarrollará con base a las políticas de privacidad publicadas en  https://yiga5.com/protecciondedatos/, la cual son plenamente aplicables a los datos provistos por los participantes. Por favor leerlas antes de participar en la actividad.
          </p>
          <p>Los participantes autorizan que se utilice su nombre e imagen capturada en fotos, videos y/o comentarios publicados en redes sociales para que sean utilizadas exclusivamente en actividades relacionadas al concurso objeto de este reglamento, por cualquier medio conocido o por conocer, en el territorio ecuatoriano y de manera indefinida. El participante no recibirá ningún tipo de pago o indemnización adicional al beneficio establecido para la presente actividad por el uso de sus fotos, videos y/o comentarios por lo que queda claro que no habrá ningún tipo de reclamación posterior.
          </p>
          
        </Grid>
      </Grid>
    </>
  )
}
