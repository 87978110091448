import {
    Paper, Container, Box, CardContent, Card, TextField, Select,
    MenuItem,  FormControl, DialogActions, InputLabel, Alert
} from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listPlanes } from '../../redux/actions/parametersActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import img from '../../assets/img/yigasalud.png'
export const ActualizarPlanPage = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    //
    const [contractArray, setContractArray] = useState([])
    const [num_contrato, setNumContrato] = useState('')
    //
    const [cedula, setCedula] = useState('')
    //
    const [client, setClient] = useState('')
    const [plan, setPlan] = useState('')

    //
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    //
    const getPlanes = useSelector(state => state.getPlanes)
    const { list_planes } = getPlanes
    useEffect(() => {
        dispatch(listPlanes())
    }, [])
    const submitHandler = (e) => {
        e.preventDefault()
        console.log({
            user_uid: 'CAMBIO DE PLAN',
            cedula: cedula,
            nombres: client.nombre,
            direccion: client.direccion_principal,
            telefono: client.telefono,
            celular: client.movil,
            correo: client.correo,
            planNombre: plan,
            numContrato: client.NUM_CONTRATO
        })
        Swal.fire({
            text: "¿Esta seguro de enviar?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Acepto'
        }).then((result) => {
            if (result.isConfirmed) {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({
                    user_uid: 'CAMBIO DE PLAN',
                    cedula: cedula,
                    nombres: client.nombre,
                    direccion: client.direccion_principal,
                    telefono: client.telefono,
                    celular: client.movil,
                    correo: client.correo,
                    planNombre: plan.plan,
                    numContrato:  client.NUM_CONTRATO
                });
                
           
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'manual'
                };

                fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/soei/solicitud_actualizar_plan", requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        //navigate(`/`)
                        Swal.fire({ icon: 'success', text: 'RECUERDA QUE DEBES ESPERAR LA LLAMADA DE UN ASESOR PARA CONFIRMAR EL CAMBIO DE TU PLAN, CUALQUIER INQUIETUD CONTACTA A SERVICIO CLIENTE 045010056.', })
                    })
                    .catch(error => console.log('error', error));
            }
        })
        //Swal.fire({ icon: 'success', text: 'Se envio la informacion a un operador, en pocos minutos se contactaran contigo', })
    }
    //Consultar cedula
    const getClient = (cedula) => {
        setCedula(cedula)
        if (cedula && cedula.length >= 10) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "dni": cedula
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(`https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/get_clients`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const dataParse = JSON.parse(result);
                    if (dataParse && dataParse.estado === 'error') {
                        console.log('No se encontro')
                        Swal.fire({
                            icon: 'info',
                            text: 'No se encontro cliente',
                        })
                        setCedula('')
                    } else {
                        const data = dataParse.datos[0]
                        setContractArray(dataParse.datos)
                    }
                })
                .catch(error => console.log('error', error));
        } else {
         
        }
    }
    //
    const handlePlane = (e) => {
        const p = list_planes.filter((item) => item.plan === e);
        console.log(e, 'plan')
        const data = p[0]
        console.log('data', data)
        setPlan(data)
    }
    const handleContrato = (e) => {
        setNumContrato(e)
        console.log(e, 'contrato')
        const p = contractArray.filter((item) => item.NUM_CONTRATO === e);
        const data = p[0]
        if (data) {
            console.log(data)
            setClient(data)
        } else {
            console.log('No hay data')
            setClient('')
            setNumContrato('')
        }
    }
    function convertInter(value) {
        if (value == 354) {
            return '35 Mbps'
        }
        if (value == 355) {
            return '100 Mbps'
        }
        if (value == 356) {
            return '150 Mbps'
        }
        if (value == 357) {
            return '200 Mbps'
        }
        if (value == 358) {
            return '300 Mbps'
        }
        if (value == 374) {
            return '70 Mbps'
        }
    }
    return (
        <Paper
            sx={{
          
                backgroundColor: "transparent",
                color: "#fff",
                width: "100%",
                mb: 4,
                minHeight: "100%",
                height: "auto",
                position: 'absolute',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundColor: 'aliceblue'
            }}
        >

      
        <Container maxWidth='md'>
               <img width='100%' src={img} />
                <Box pt={2}></Box>
            <form onSubmit={submitHandler}>
                <Card>
                    <CardContent>
                        <Box py={3}>
                            <TextField
                                fullWidth
                                type='number'
                                value={cedula}
                                onChange={(e) => getClient(e.target.value)}
                                label="CÉDULA | RUC"
                            />
                        </Box>
                        {

                            contractArray && cedula.length >= 10 &&
                            <Box pb={3}>
                                <FormControl fullWidth disabled={!contractArray}>
                                    <InputLabel >LINEA DE CONTRATO</InputLabel>
                                    <Select
                                        value={num_contrato}
                                        label="LINEA DE CONTRATO"
                                        onChange={(e) => { handleContrato(e.target.value) }}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {contractArray && contractArray.map((item, index) => (
                                            <MenuItem key={index} value={item.NUM_CONTRATO}>{item.NUM_CONTRATO} - <strong>{item.estado}</strong></MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                        <Box pb={2}>
                            <FormControl fullWidth>
                                <InputLabel id="ubicacion">SELECCIONAR PLAN</InputLabel>
                                <Select
                                    labelId="ubicacion"
                                    id="ubicacion"
                                    value={plan.plan}
                                    label="SELECCIONAR PLAN"
                                    onChange={(e) => handlePlane(e.target.value)}
                                >
                                    {
                                        userInfo && list_planes && list_planes.map((item, index) => (
                                            item.id >= 354 && item.id <= 358 &&
                                            <MenuItem key={index} value={item.plan}>{item.plan} - <strong>${item.costo}</strong> - {convertInter(item.id)}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    
                      
                    </CardContent>
                </Card>
                <Box py={2}>
                    <DialogActions>
                        <LoadingButton
                            size='large'
                            //loading={loading}
                            //disabled={loading || !plan || !cedula.campo || !nombre.campo || !correo.campo}
                            type="submit"
                            variant="contained"
                            color='secondary'
                        >
                            Enviar
                        </LoadingButton>
                    </DialogActions>
                </Box>
            </form>

        </Container>
        </Paper>
    )
}
