import soporte from '../../../assets/svg/soporte.svg'
import cambio_domicilio from '../../../assets/svg/cambio_domicilio.svg'
import plan from '../../../assets/svg/plan.svg'
import anulacion from '../../../assets/svg/anulacion.svg'
import migracion from '../../../assets/svg/migracion.svg'
import contratar from '../../../assets/svg/contratar.svg'
import zappingtv from '../../../assets/icons/zapping_tv.png'
import DownloadZapping from '../../../assets/icons/DownloadZapping.png'
export const DashboardSvg = (props) => {
    return (
        <>
            {props.item === 0 &&
                <img src={soporte} alt='soporte' width='300px' />}
            {props.item === 1 &&
                <img src={cambio_domicilio} alt='cambio domicilio' width='250px' />}
            {props.item === 2 &&
                <img src={plan} alt='plan' width='125px' />}
            {props.item === 3 &&
                <img src={anulacion} alt='plan' width='200px' />}
            {props.item === 4 &&
                <img src={migracion} alt='migracion' width='160px' />}
            {props.item === 5 &&
                <img src={contratar} alt='contratar servicio' width='110px' />}
            {props.item === 6 &&
                <img src={contratar} alt='contratar camara' width='110px' />}
            {props.item === 7 &&
                <img src={zappingtv} alt='zappingtv' width='110px' />}
            {props.item === 8 &&
                <img src={DownloadZapping} alt='Descargar licencia' width='110px' />}
        </>
    )
}